import React from 'react';
import { Navbar, Page, Tabs, Tab, Toolbar, Block, Link, f7 } from 'framework7-react';
import { getObjects } from '@api';
import { useQuery } from 'react-query';
import { dateFormat, sleep } from '@js/utils';
import { map } from 'lodash';
import { Contact, Objects, PageRouteProps } from '@constants';
import useAuth from '@hooks/useAuth';

const ContactIndexPage = ({ f7route, f7router }: PageRouteProps) => {
  const { currentUser } = useAuth();

  const { data, refetch } = useQuery<Objects<Contact>, Error>(
    'contacts',
    getObjects({
      model_name: 'contact',
      q: {
        user_id_eq: currentUser?.id || '',
        s: ['created_at desc'],
      },
    }),
  );

  const onClickGoBack = async () => {
    f7.preloader.show();
    f7router.back();
    await sleep(500);
    f7.preloader.hide();
  };

  return (
    <Page name="contact" noToolbar>
      <Navbar title="판매 문의 (1:1 문의)" backLink />
      <div>
        <div className="sm:block">
          <div id="list">
            <div className="bg-white overflow-hidden sm:rounded-md">
              {data && data.total_count > 0 && currentUser ? (
                <ul className="divide-y divide-gray-200">
                  {map(data.objects, (contact: Contact) => (
                    <li key={contact.id}>
                      <a href={`/contacts/${contact.id}`} className="block hover:bg-gray-50">
                        <div className="px-4 py-4 flex items-center sm:px-6">
                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex text-sm">
                                {contact?.contact_type == 'seller_contact' ? (
                                  <p className="px-2 inline-flex text-xs leading-5 font-normal rounded-full bg-blue-100 text-blue-800 mr-1">
                                    판매 문의
                                  </p>
                                ) : (
                                  <p className="px-2 inline-flex text-xs leading-5 font-normal rounded-full bg-yellow-100 text-gray-800 mr-1">
                                    상품문의
                                  </p>
                                )}
                                {contact?.answer ? (
                                  <p className="px-2 inline-flex text-xs leading-5 font-normal rounded-full bg-green-100 text-green-800">
                                    답변완료
                                  </p>
                                ) : (
                                  <p className="px-2 inline-flex text-xs leading-5 font-normal rounded-full bg-gray-100 text-gray-500">
                                    답변예정
                                  </p>
                                )}
                                <p className="ml-2 font-medium text-gray-600 truncate">{contact?.title}</p>
                              </div>
                              <div className="mt-2 flex">
                                <div className="flex items-center text-sm text-gray-500">
                                  <i
                                    className="las la-calendar-minus mr-2"
                                    style={{ fontSize: '20px', color: '#bbbbbb' }}
                                  />
                                  <p>
                                    <span className="text-xs">{dateFormat(contact.created_at, 'time')}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <Block className="pt-16 mt-16 text-center">
                  {/* <div className="text-center pt-16 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                    <i className="las la-exclamation-circle" style={{ fontSize: '64px', color: '#bbbbbb' }}></i>
                    <h1 className="text-center mt-2 mb-5 font-normal">문의사항이 없습니다.</h1>
                    <div className="flex">
                      <button
                        onClick={onClickGoBack}
                        type="button"
                        className="text-center mr-20 ml-20 flex-initial rounded-full items-center px-4 py-2 border border-blue-700 shadow-sm text-base font-medium text-blue-700 bg-white"
                      >
                        이전 페이지 돌아가기
                      </button>
                    </div>
                  </div> */}
                </Block>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="text-center pt-16 py-4 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
        <div className="max-w-lg mx-auto">
          <dd className="flex justify-center">
            <i className="las la-pen flex-shrink-0" style={{ fontSize: '64px' }} />
          </dd>
          <h2 className="mt-3 text-md font-semibold tracking-tight text-gray-900 sm:text-xl">
            모두다에 궁금하신 점을 문의해주세요.
          </h2>
          <dl className="mt-8 text-sm text-gray-500 text-center">
            <div className="mt-6">
              <dt className="sr-only">Phone number</dt>
              <dd className="flex justify-center">
                <i className="las la-phone flex-shrink-0" style={{ fontSize: '24px', color: '#bbbbbb' }} />
                <span className="ml-3">+1 (555) 123-4567</span>
              </dd>
            </div>
            <div className="mt-3">
              <dt className="sr-only">Email</dt>
              <dd className="flex justify-center">
                <i className="las la-envelope flex-shrink-0" style={{ fontSize: '24px', color: '#bbbbbb' }} />
                <span className="ml-3">test@moduda.com</span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="px-4">
        {currentUser ? (
          <div className="rounded-lg shadow-md">
            <a
              href="/contacts/new"
              className="mt-8 w-full bg-blue-500 border border-transparent px-5 py-3 inline-flex items-center justify-center text-base font-medium rounded-md text-white hover:bg-blue-700 sm:mt-10 sm:w-auto xl:mt-0"
              aria-describedby="tier-hobby"
            >
              문의하기
            </a>
          </div>
        ) : (
          <div className="rounded-lg shadow-md">
            <a
              href="/intro"
              className="mt-8 w-full bg-blue-500 border border-transparent px-5 py-3 inline-flex items-center justify-center text-base font-medium rounded-md text-white hover:bg-blue-700 sm:mt-10 sm:w-auto xl:mt-0"
              aria-describedby="tier-hobby"
            >
              로그인하기
            </a>
          </div>
        )}
      </div>
    </Page>
  );
};

export default ContactIndexPage;

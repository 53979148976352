import React, { useState } from 'react';
import { Navbar, Page, Link, Tabs, Tab, Toolbar } from 'framework7-react';
import useAuth from '@hooks/useAuth';
import { useQuery, useMutation } from 'react-query';
import { getUser, API_URL } from '@api';
import ReactQueryState from '@components/shared/ReactQueryState';
import { createFollow, deleteFollow } from '@api';
import { Follow, User } from '@constants';

interface FollowInfoProps {
  isFollower: boolean;
  followId: number | null;
  followersCount: number;
}

const UserShowPage = ({ f7route, f7router }) => {
  const { currentUser } = useAuth();
  const userId = f7route.params.id;
  const USER_KEY = ['user', parseInt(userId, 10)];

  const { mutate: createMutate } = useMutation(createFollow(), {
    onSuccess: (data: Follow) =>
      setFollowInfo((defaultInfo: FollowInfoProps) => ({
        isFollower: data.is_followed,
        followId: data.id,
        followersCount: defaultInfo.followersCount + 1,
      })),
  });
  const { mutate: deleteMutate } = useMutation(deleteFollow(), {
    onSuccess: (data: Follow) =>
      setFollowInfo((defaultInfo: FollowInfoProps) => ({
        isFollower: data.is_followed,
        followId: data.id,
        followersCount: defaultInfo.followersCount - 1,
      })),
  });

  const { status, data: user, error } = useQuery<User, Error>(USER_KEY, getUser(userId), {
    enabled: !!userId,
  });

  const [followInfo, setFollowInfo] = useState<FollowInfoProps>({
    isFollower: !!user?.follow_id,
    followId: user?.follow_id,
    followersCount: user?.followers_count || 0,
  });

  const onClickFollow = () => (followInfo.isFollower ? deleteMutate(followInfo.followId) : createMutate(userId));

  return (
    <Page noToolbar>
      <Navbar backLink />
      <ReactQueryState data={user} status={status} error={error} />
      {user && (
        <>
          <div className="py-5 divide-solid">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
              <div className="flex items-center space-x-5">
                <div className="flex-shrink-0">
                  <div className="relative">
                    <i
                      className="h-24 w-24 rounded-full las la-user-circle"
                      style={{ fontSize: '96px', color: 'gray' }}
                    />
                    <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true"></span>
                  </div>
                </div>
                <div className="w-full">
                  <h1 className="text-xl font-bold text-gray-900">{user ? user.name : '인썸니아'}</h1>
                  <p className="mt-1 text-xs font-medium text-gray-500">
                    팔로워 <span className=" text-gray-900">{followInfo.followersCount}</span> | 팔로잉{' '}
                    <span className=" text-gray-900">{user.followings_count || 0}</span>
                  </p>
                  {followInfo.isFollower ? (
                    <button
                      type="button"
                      className="w-1/2 text-center mt-2 items-center px-2.5 py-1.5 border border-transparent text-sm font-semibold rounded shadow-sm text-blue-600 bg-white border-blue-600 focus:outline-none"
                      onClick={onClickFollow}
                    >
                      팔로우 취소
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="w-1/2 text-center mt-2 items-center px-2.5 py-1.5 border border-transparent text-sm font-semibold rounded shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none"
                      onClick={onClickFollow}
                    >
                      팔로우
                    </button>
                  )}
                </div>
              </div>
              {user.description ? (
                <div className="mt-5 flex items-center space-x-5 truncate">{user.description}</div>
              ) : (
                ''
              )}
              <div className="mt-1 flex items-center text-sm text-blue-500 space-x-5 truncate">{user.email}</div>
            </div>
          </div>
          <hr />
          <div className="border-b border-gray-200">
            <Toolbar tabbar className="-mb-px flex">
              <Link
                tabLink="#feed"
                tabLinkActive
                className="custom-tab-active border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-900 w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm"
              >
                피드
              </Link>
              <Link
                tabLink="#info"
                className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-900 w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm"
              >
                정보
              </Link>
            </Toolbar>
          </div>
          <Tabs>
            <Tab id="feed" tabActive>
              {/* {user.images.length ? (
                <ul role="list" className="grid grid-cols-3 gap-x-0 gap-y-0">
                  {images.map((image, i) => (
                    <li key={image.id} className="relative">
                      <div className="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500 group block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
                        <img
                          src={`${API_URL + image.image_path}`}
                          alt=""
                          className="group-hover:opacity-75 object-cover pointer-events-none"
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <Block className="mt-16">
                  <div className="text-center pt-16 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                    <div className="max-w-lg mx-auto">
                      <dd className="flex justify-center">
                        <i className="las la-camera flex-shrink-0 text-gray-400" style={{ fontSize: '64px' }}></i>
                      </dd>
                      <h2 className="mt-3 text-md font-semibold tracking-tight text-gray-900 sm:text-xl">
                        게시글이 없습니다.
                      </h2>
                    </div>
                  </div>
                </Block>
              )} */}
            </Tab>
            <Tab id="info">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">소개</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">개인 정보 및 소개</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">이름</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{user.name}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">전화번호</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{user.phone}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">이메일</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{user.email}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">주소</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {user.zipcode} {user.address1} {user.address2}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">소개</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{user.description}</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </Tab>
          </Tabs>
        </>
      )}
    </Page>
  );
};

export default UserShowPage;

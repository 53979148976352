import React from 'react';
import { Page, Navbar } from 'framework7-react';
import ContactForm from './form';
import { useQuery } from 'react-query';
import ReactQueryState from '../../components/shared/ReactQueryState';
import { getObject } from '@api';
import { Contact, PageRouteProps } from '@constants';

const ContactEditPage = ({ f7route, f7router }: PageRouteProps) => {
  const contactId = f7route.params.id;
  const CONTACT_KEY = ['contact', parseInt(contactId)];
  const { status, data: contact, error } = useQuery<Contact, Error>(
    CONTACT_KEY,
    getObject(contactId, {
      model_name: 'contact',
    }),
    {
      enabled: !!contactId,
    },
  );

  return (
    <Page noToolbar>
      <Navbar title="문의 수정" sliding={false} backLink />
      <ReactQueryState data={contact} status={status} error={error} />
      {contact && <ContactForm contact={contact} f7router={f7router} f7route={f7route} itemId={contact.item_id} />}
    </Page>
  );
};

export default ContactEditPage;

import React from 'react';
import { Page, Navbar, Block, f7 } from 'framework7-react';
import { getObjects } from '@api';
import { useQuery } from 'react-query';
import { sleep, dateFormat } from '@js/utils';
import { Term, Objects } from '@constants';
import { map } from 'lodash';

const TermIndexPage = ({ f7router }) => {
  const { data, status, error } = useQuery<Objects<Term>, Error>(
    'terms',
    getObjects({
      model_name: 'term',
      q: {
        s: ['created_at asc'],
      },
    }),
  );

  const onClickGoBack = async () => {
    f7.preloader.show();
    f7router.back();
    await sleep(500);
    f7.preloader.hide();
  };

  return (
    <Page name="term" noToolbar>
      <Navbar backLink={true} title="이용약관"></Navbar>
      <div>
        <div>
          <div className="flow-root mt-6 mb-6">
            {data && data.total_count > 0 ? (
              <ul className="-my-5 divide-y divide-gray-200">
                {map(data?.objects, (term: Term) => (
                  <li className="p-4" key={term.id}>
                    <div className="flex items-center space-x-4">
                      <div className="flex-1 min-w-0">
                        <a href={`/terms/${term.id}`} className="hover:underline focus:outline-none">
                          {term?.title}
                        </a>
                      </div>
                      <div>
                        <a
                          href={`/terms/${term.term_type}`}
                          className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                        >
                          보기
                        </a>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <Block className="pt-16 mt-16 text-center">
                <div className="text-center pt-16 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                  <i className="las la-exclamation-circle" style={{ fontSize: '64px', color: '#bbbbbb' }}></i>
                  <h1 className="text-center mt-2 mb-5 font-normal">약관이 없습니다.</h1>
                  <div className="flex">
                    <button
                      onClick={onClickGoBack}
                      type="button"
                      className="text-center mr-20 ml-20 flex-initial rounded-full items-center px-4 py-2 border border-blue-700 shadow-sm text-base font-medium text-blue-700 bg-white"
                    >
                      이전 페이지 돌아가기
                    </button>
                  </div>
                </div>
              </Block>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(TermIndexPage);

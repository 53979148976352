import React from 'react';
import { Navbar, Page, Button, f7 } from 'framework7-react';
import { getObject, getObjects, cancelPayment } from '@api';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import ReactQueryState from '@components/shared/ReactQueryState';
import { Order, LineItem, PageRouteProps } from '@constants';
import { currency, convertImagePath, dateFormat, toast } from '@js/utils';
import { map } from 'lodash';
import useAuth from '@hooks/useAuth';
import i18n from '../../assets/lang/i18n';

const OrderShowPage = ({ f7route, f7router }: PageRouteProps) => {
  const orderId = f7route.params.id;
  const { currentUser } = useAuth();
  const refundToast = toast.get();

  const { status, data: order, error } = useQuery<Order, Error>(
    ['order', parseInt(orderId, 10)],
    getObject(orderId, {
      model_name: 'order',
    }),
    {
      enabled: !!orderId,
    },
  );
  const queryClient = useQueryClient();

  const { data, isSuccess } = useQuery(
    'line_items',
    getObjects({
      model_name: 'line_item',
      q: {
        order_id_eq: orderId,
        s: ['created_at desc'],
      },
    }),
    {
      enabled: !!orderId,
    },
  );

  const cancelPaymentMutation = useMutation(cancelPayment(), {
    onError: (error) => {
      // console.log(`에러가 발생했습니다: `, error);
      f7.dialog.alert('처리 중 오류가 발생하였습니다. 문제가 계속될 경우 관리자에게 문의해주세요.');
    },
  });

  const onClickRefund = () => {
    if (currentUser) {
      f7.dialog.confirm('주문을 취소하시겠습니까?', 'MODOODA', () => {
        cancelPaymentMutation.mutate(
          {
            id: orderId,
          },
          {
            onSuccess: async (data) => {
              if (order.status === 'paid' || order.status === 'before_shipping') {
                refundToast.setToastIcon('checkmark_circle').setToastText('주문이 취소되었습니다.').openIconToast();
              } else {
                refundToast
                  .setToastIcon('checkmark_circle')
                  .setToastText('주문 환불 요청을 완료했습니다.')
                  .openIconToast();
              }
              await queryClient.invalidateQueries('order');
            },
          },
        );
      });
    } else {
      f7.dialog.alert('다시 시도해주세요.');
      f7router.navigate('/orders');
    }
  };

  return (
    <Page noToolbar className="bg-gray-100">
      <Navbar backLink title="주문 상세" />
      <ReactQueryState data={order} status={status} error={error} />
      <div className="py-4 xl:py-10 px-2 sm:px-2 lg:px-4 overflow-hidden">
        {order && (
          <div className="w-full">
            <div className="relative md:bg-white mx-4">
              <div className="prose prose-blue prose-lg text-gray-500 lg:max-w-none text-base">
                <p className="font-semibold text-xs text-gray-900">
                  {dateFormat(order?.paid_at ? order?.paid_at : order?.updated_at, 'time')}
                </p>
                <p className="text-xs mt-2">주문번호 {order.order_number}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="col-span-1 rounded-lg divide-y divide-gray-200 px-4">
        {order && (
          <>
            <div className="border border-gray-200 col-span-1 bg-white rounded-lg shadow-sm divide-y divide-gray-200">
              <div className="px-4 py-5 sm:px-6">
                <p className="font-semibold text-base text-gray-900 mb-2">결제정보</p>
                <div className="relative w-full mt-2">
                  <span className="mt-1 max-w-2xl text-sm text-gray-500">결제수단</span>
                  <span className="mt-1 max-w-2xl text-sm text-gray-800 float-right">
                    {order?.payment_method ? order?.payment_method : '-'}
                  </span>
                </div>
                <div className="relative w-full mt-2">
                  <span className="mt-1 max-w-2xl text-sm text-gray-500">총 상품금액</span>
                  <span className="mt-1 max-w-2xl text-sm text-gray-800 float-right">{`${currency(
                    order?.item_total,
                  )}원`}</span>
                </div>
                <div className="relative w-full mt-2">
                  <span className="mt-1 max-w-2xl text-sm text-gray-500">배송옵션</span>
                  <span className="mt-1 max-w-2xl text-sm text-gray-800 float-right">{order?.deliver_method}</span>
                </div>
                <div className="relative w-full mt-2 mb-4">
                  <span className="mt-1 max-w-2xl text-sm text-gray-500">배송비</span>
                  <span className="mt-1 max-w-2xl text-sm text-gray-800 float-right">
                    {order?.deliver_method === '현장픽업' ? '0원' : `${currency(order?.shipping_fee)}원`}
                  </span>
                </div>
                <hr />
                <div className="relative w-full mt-2">
                  <span className="mt-1 max-w-2xl text-sm text-gray-800 font-semibold">총 결제금액</span>
                  <span className="mt-1 max-w-2xl text-sm text-gray-800 float-right font-semibold">{`${currency(
                    order?.total,
                  )}원`}</span>
                </div>
              </div>
            </div>
            <div className="border border-gray-200 col-span-1 bg-white rounded-lg shadow-sm divide-y divide-gray-200 mt-4">
              <div className="px-4 py-5 sm:px-6">
                <p className="font-semibold text-base text-gray-900 mb-2">배송정보</p>
                <div className="relative w-full mt-2 mb-2">
                  <span className="mt-1 max-w-2xl text-sm text-gray-500">배송 상태</span>
                  {order?.status && order?.status === 'refund' ? (
                    <span className="mt-1 max-w-2xl float-right px-2 inline-flex text-xs leading-5 font-normal rounded-full bg-red-100 text-red-800 mr-1">
                      {i18n.t(`${order.status}`)}
                    </span>
                  ) : (
                    <span className="mt-1 max-w-2xl float-right px-2 inline-flex text-xs leading-5 font-normal rounded-full bg-blue-100 text-blue-800 mr-1">
                      {i18n.t(`${order.status}`)}
                    </span>
                  )}
                </div>
                {order?.deliver_method === '현장픽업' ? (
                  <div>
                    <h5>현장픽업 예정인 주문입니다.</h5>
                  </div>
                ) : (
                  <>
                    <div className="relative w-full mb-2">
                      <span className="mt-1 max-w-2xl text-sm text-gray-500">송장번호</span>
                      <span className="mt-1 max-w-2xl float-right px-2 inline-flex text-xs leading-5 font-normal mr-1">
                        {order.invoice_num ? order.invoice_num : '-'}
                      </span>
                    </div>
                    <div className="relative w-full mb-4">
                      <span className="mt-1 max-w-2xl text-sm text-gray-500">택배사</span>
                      <span className="mt-1 max-w-2xl float-right px-2 inline-flex text-xs leading-5 font-normal mr-1">
                        {order.deliver_company ? order.deliver_company : '-'}
                      </span>
                    </div>
                  </>
                )}
                {order && order?.status === 'refund' && (
                  <>
                    <hr />
                    <p className="font-semibold text-base text-gray-900 mb-2 mt-2">환불 정보</p>
                    <div className="relative w-full mb-2">
                      <span className="mt-1 max-w-2xl text-sm text-gray-500">환불일자</span>
                      <span className="mt-1 max-w-2xl float-right px-2 inline-flex text-xs leading-5 font-normal mr-1">
                        {order?.cancelled_at ? dateFormat(order?.cancelled_at, 'time') : '-'}
                      </span>
                    </div>
                    <div className="relative w-full mt-4 mb-4">
                      <span className="mt-1 max-w-2xl text-sm text-gray-500">환불 영수증</span>
                      <span className="mt-1 max-w-2xl float-right px-2 inline-flex text-xs leading-5 font-normal mr-1">
                        {order?.receipt_url ? (
                          <a href={order?.receipt_url} className=" external" target="_blank" rel="noreferrer">
                            <span className="inline-flex items-center px-2.5 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              조회하기
                            </span>
                          </a>
                        ) : (
                          '없음'
                        )}
                      </span>
                    </div>
                  </>
                )}
                <hr />
                <p className="mt-2 font-semibold text-base text-gray-900 mb-2">{order?.receiver_name}</p>
                <div className="relative w-full mt-2">
                  <span className="mt-1 max-w-2xl text-sm text-gray-500">
                    {order?.zipcode ? `(${order?.zipcode})` : ''}
                    {order?.address1}
                    {order?.address2}
                  </span>
                </div>
                <div className="relative w-full mt-2">
                  <span className="mt-1 max-w-2xl text-xs text-gray-500">수령인 연락처: {order?.receiver_phone}</span>
                </div>
              </div>
            </div>
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-4">
              {isSuccess &&
                map(data.objects, (line_item: LineItem) => (
                  <li
                    key={line_item.id}
                    className="border border-gray-200 col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
                  >
                    <>
                      <div className="w-full flex items-left justify-between p-6 space-x-6">
                        <img
                          className="w-14 h-14 bg-gray-300 flex-shrink-0"
                          src={convertImagePath(line_item?.image_path)}
                          alt=""
                        />
                        <div className="flex-1 truncate list m-0">
                          <div className="item-header py-1">{line_item?.category_name}</div>
                          <div className="item-title">{line_item?.item_name}</div>
                          <div className="item-subtitle">{`${currency(line_item?.price)}원`}</div>
                        </div>
                      </div>
                      <div>
                        <div className="-mt-px flex divide-x divide-gray-200">
                          <div className="w-0 flex-1 flex">
                            <a
                              href={`/items/${line_item?.item_id}`}
                              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                            >
                              <span className="ml-3">상품 보기</span>
                            </a>
                          </div>
                          <div className="-ml-px w-0 flex-1 flex">
                            <a
                              href="https://search.naver.com/search.naver?query=배송조회"
                              className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 external"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span className="ml-3">배송 조회</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                  </li>
                ))}
            </ul>
            {order && order?.status !== 'refund' && order?.status !== 'refund_request' && (
              <div className="mt-2">
                {order?.status === 'paid' || order?.status === 'before_shipping' ? (
                  <Button
                    onClick={onClickRefund}
                    large
                    fill
                    className="mt-4 mb-0 bg-gray-500 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-700"
                  >
                    주문 취소하기
                  </Button>
                ) : (
                  <Button
                    onClick={onClickRefund}
                    large
                    fill
                    className="mt-4 mb-0 bg-gray-500 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-700"
                  >
                    환불 요청하기
                  </Button>
                )}
              </div>
            )}
            {order && (order?.status === 'refund' || order?.status === 'refund_request') && (
              <div className="mt-2">
                {order?.status === 'refund' ? (
                  <Button
                    large
                    fill
                    className="mt-4 mb-0 bg-gray-300 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-700"
                  >
                    환불 완료된 상품입니다.
                  </Button>
                ) : (
                  <Button
                    large
                    fill
                    className="mt-4 mb-0 bg-gray-300 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-700"
                  >
                    환불 요청된 상품입니다.
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Page>
  );
};

export default React.memo(OrderShowPage);

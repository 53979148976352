import React from 'react';
import { API_URL, getObjects } from '@api';
import { Link, SkeletonBlock, SkeletonText, BlockTitle, f7 } from 'framework7-react';
import { useQuery } from 'react-query';
import { Category, Objects } from '@constants';
import { objectsSkeletonPlaceholder, truncate, convertImagePath } from '@js/utils';
import _ from 'lodash';

const Categories = () => {
  const { data, isLoading, isError, isFetching } = useQuery<Objects<Category>, Error>(
    'categories',
    getObjects({ model_name: 'category', main_category: true }),
    { placeholderData: objectsSkeletonPlaceholder(16) },
  );

  if (isError) {
    return (
      <div className="h-32 flex items-center justify-center">
        <span className="text-gray-400">서버에 문제가 발생 했습니다. </span>
      </div>
    );
  }

  return (
    <>
      <div className="px-5 py-2 sm:px-6 mt-8 row">
        <h3 className="text-lg leading-6 font-medium text-gray-900 col-50">인기 카테고리</h3>
        <span className="float-right px-2.5 py-0.5 rounded-full text-medium text-center font-medium bg-gray-100 text-gray-800 col-30">
          <Link
            text="더보기"
            onClick={() => {
              f7.tab.show('#view-categories');
            }}
          />
        </span>
      </div>
      {data && (
        <div className="grid grid-cols-4 gap-2 p-2 md:grid-cols-4">
          {data.objects
            .filter((category, index) => data.objects.map((c) => c.id).indexOf(category.id) === index)
            .slice(0, 4)
            .map((category: Category, i) => (
              <div key={i}>
                {isLoading || isFetching ? (
                  <Link href="#" className="bg-white h-20 flex flex-col items-center justify-center" key={i}>
                    <SkeletonBlock slot="media" className="w-14 h-14 rounded-lg" />
                    <span className="text-gray-500 mt-1">
                      <SkeletonText>---</SkeletonText>
                    </span>
                  </Link>
                ) : (
                  <Link
                    href={`/items?category_id=${category.id}`}
                    className="bg-white h-20 flex flex-col items-center justify-center"
                    key={category.id}
                  >
                    <img src={convertImagePath(category?.image_path)} alt="#" className="w-14 h-14 rounded-lg" />
                    <span className="text-gray-500 mt-1">{truncate(5, category.title)}</span>
                  </Link>
                )}
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default Categories;

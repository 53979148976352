import React, { useState, useRef } from 'react';
import { Page, Navbar, Block, f7, Icon, List, ListItem, ListInput, NavRight, Link } from 'framework7-react';
import { getInfiniteObjects } from '@api';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { Order, InfiniteObjects } from '@constants';
import { map } from 'lodash';
import { currency, sleep, convertImagePath } from '@js/utils';
import useAuth from '@hooks/useAuth';
import { useFormik } from 'formik';
import { dateFormat } from '@js/utils';

const OrderIndexPage = ({ f7router, f7route }) => {
  const { currentUser } = useAuth();
  const [viewType, setViewType] = useState('list');
  const allowInfinite = useRef(true);
  const queryClient = useQueryClient();
  const { is_payment } = f7route.query;

  const filterForm = useFormik({
    initialValues: {
      s: 'updated_at desc',
      user_id_eq: currentUser?.id,
    },
    onSubmit: async () => {
      await queryClient.removeQueries('orders');
      await refetch();
      allowInfinite.current = true;
    },
  });

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery<
    InfiniteObjects<Order>,
    Error
  >(
    'orders',
    getInfiniteObjects({
      model_name: 'order',
      q: filterForm.values,
    }),
    {
      getNextPageParam: (lastPage) => lastPage.next_cursor,
      enabled: !!currentUser,
    },
  );

  const onClickGoToBuyItem = async () => {
    f7.preloader.show();
    f7router.back();
    await sleep(500);
    f7router.navigate(`/items?category_all=true`);
    f7.preloader.hide();
  };

  const loadMore = async () => {
    if (!allowInfinite.current) return;
    allowInfinite.current = false;
    if (hasNextPage && !isFetchingNextPage) {
      await fetchNextPage();
      allowInfinite.current = true;
    }
  };

  const onRefresh = async (done) => {
    await queryClient.removeQueries('orders');
    await refetch();
    allowInfinite.current = true;
    done();
  };

  return (
    <Page
      name="order"
      noToolbar
      infinite
      infinitePreloader={isFetchingNextPage}
      onInfinite={loadMore}
      onPtrRefresh={onRefresh}
      ptr
    >
      <Navbar backLink={!is_payment} title="주문 목록">
        {is_payment && (
          <NavRight>
            <Link href="/" iconIos="f7:house" iconAurora="f7:house" iconMd="material:house" />
          </NavRight>
        )}
      </Navbar>
      <form onSubmit={filterForm.handleSubmit} className="item-list-form p-3 table w-full border-b">
        <div className="float-left">
          총 <b>{currency((data && data.pages[0]?.total_count) || 0)}</b>개 상품
        </div>
        <ListInput
          type="select"
          className="float-right float-right inline-flex items-center px-2.5 py-3 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          name="s"
          onChange={(e) => {
            filterForm.handleChange(e);
            filterForm.submitForm();
          }}
          value={filterForm.values.s}
        >
          {map(i18next.t('sort').item, (v, k) => (
            <option value={k} key={k}>
              {v}
            </option>
          ))}
        </ListInput>
        <div
          className="float-right inline-flex items-center shadow-sm text-xs hover:text-blue-600 rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
          onClick={(e) => setViewType('list')}
        >
          <Icon f7="list_bullet" size="24px" />
        </div>
        <div
          className="float-right inline-flex items-center shadow-sm text-xs hover:text-blue-600 rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
          onClick={(e) => setViewType('grid')}
        >
          <Icon f7="circle_grid_3x3_fill" size="24px" />
        </div>
      </form>
      <div>
        <div className="flow-root mt-6 mb-6">
          <List noHairlines className="mt-0 text-sm font-thin">
            {data && data?.pages[0]?.total_count > 0 ? (
              <ul className="-my-5 divide-y divide-gray-200">
                {viewType === 'list'
                  ? data.pages.map((page, i) => (
                      <React.Fragment key={i}>
                        {page.objects.map((order: Order) => (
                          <>
                            <ListItem key={order.id} mediaItem className="w-full">
                              <div className="flex flex-row">
                                <div className="w-4/5 inline-flex justify-left align-middle">
                                  <a href={`/orders/${order.id}`} className="item-link">
                                    <div className="text-xxs font-medium text-gray-600">
                                      {dateFormat(order?.paid_at ? order?.paid_at : order?.updated_at, 'day')}
                                    </div>
                                    {/* <div className="text-xxs font-medium text-gray-500">{order?.order_number}</div> */}
                                    <div className="item-title">
                                      {`${order?.line_items[0]?.item_name || '상품 정보 없음'}`}{' '}
                                      {order?.line_items?.length > 1 && `외 ${order?.line_items?.length - 1}종`}
                                    </div>
                                    <div className="item-subtitle">{`${currency(order.total)}원`}</div>
                                  </a>
                                </div>
                                <a href={`/orders/${order.id}`} className="justify-right w-1/5">
                                  <Icon icon="icon-forward text-gray-400 px-6 py-3" />
                                </a>
                              </div>
                              <img
                                slot="media"
                                src={convertImagePath(order?.line_items[0]?.image_path)}
                                className="w-20 rounded"
                                alt=""
                              />
                            </ListItem>
                          </>
                        ))}
                      </React.Fragment>
                    ))
                  : data.pages.map((page, i) => (
                      <React.Fragment key={i}>
                        {page.objects.map((order: Order) => (
                          <div className="w-1/2 inline-flex grid-list-item relative" key={order.id}>
                            <ListItem mediaItem className="w-full">
                              <a href={`/orders/${order.id}`} className="item-link">
                                <div className="item-media">
                                  <img
                                    slot="media"
                                    src={convertImagePath(order?.line_items[0]?.image_path)}
                                    className="w-40 m-auto radius rounded shadow-sm"
                                    alt=""
                                  />
                                </div>
                                <div className="text-xxs font-medium mt-2 text-gray-600">
                                  {dateFormat(order?.paid_at ? order?.paid_at : order?.updated_at, 'day')}
                                </div>
                                {/* <div className="text-xxs font-medium text-gray-500">{order?.order_number}</div> */}
                                <div className="item-title">
                                  {`${order?.line_items[0]?.item_name}`}{' '}
                                  {order?.line_items?.length > 1 && `외 ${order?.line_items?.length - 1}종`}
                                </div>
                                <div className="item-subtitle">{`${currency(order?.total)}원`}</div>
                              </a>
                            </ListItem>
                          </div>
                        ))}
                      </React.Fragment>
                    ))}
              </ul>
            ) : (
              <Block className="pt-16 mt-16 text-center">
                <div className="text-center pt-16 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                  <i className="las la-exclamation-circle" style={{ fontSize: '64px', color: '#bbbbbb' }} />
                  <h1 className="text-center mt-2 mb-5">주문내역이 없습니다.</h1>
                  <div className="flex">
                    <button
                      onClick={onClickGoToBuyItem}
                      type="button"
                      className="text-center mr-20 ml-20 flex-initial rounded-full items-center px-4 py-2 border border-blue-700 shadow-sm text-base font-medium text-blue-700 bg-white"
                    >
                      상품 둘러보기
                    </button>
                  </div>
                </div>
              </Block>
            )}
          </List>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(OrderIndexPage);

import React from 'react';
import { map } from 'lodash';
import { Term } from '@constants';
import logo from '@images/modooda_company_logo.png';

const BusinessInfo = ({ terms }) => (
  <footer>
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
      <div className="border-t border-gray-200 py-8 text-sm text-gray-500 text-left sm:text-left row">
        <div className="col-25 mt-1">
          <a href="https://www.sseuimsae.com" target="_blank" className="external" rel="noreferrer">
            <img src={logo} alt="" width="120" height="90" />
          </a>
        </div>
        <div className="col-75" style={{ lineHeight: '13px', fontSize: '8px' }}>
          <div className="xs:inline text-xxs">상호: 주식회사 쓰임새, 대표자 성명: 최진규</div>
          <div className="xs:inline text-xxs">사업자등록번호: 879-86-02150</div>
          <div className="xs:inline text-xxs">사업자소재지: 경기도 수원시 팔달구 권선로 731, 803호</div>
          <div className="xs:inline text-xxs">전화번호: 031-309-2208, email: sseuimsae@gmail.com</div>
          <div className="xs:inline text-xxs">회사소개: www.sseuimsae.com, 통신판매업신고번호: 2021-수원팔달-0651</div>
          <div className="xs:inline text-xxs">개인정보관리책임자</div>
          <div className="xs:inline text-xxs">- 담당자: 나현우, 연락처: 031-309-2208, email: sseuimsae@gmail.com</div>
          {terms && (
            <>
              {map(terms?.objects, (term: Term) => (
                <a href={`/terms/${term?.term_type}`} className="mr-2 xs:inline text-xxs" key={term?.id}>
                  {term?.title}
                </a>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  </footer>
);

export default BusinessInfo;

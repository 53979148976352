import React from 'react';
import { SwiperSlide, Swiper } from 'framework7-react';
import { Page, Navbar, Subnavbar, Searchbar, theme, Link, SkeletonBlock, SkeletonText } from 'framework7-react';
import { getObjects, API_URL } from '@api';
import { Notice, Objects, Category } from '@constants';
import { objectsSkeletonPlaceholder, truncate, convertImagePath } from '@js/utils';
import { useQuery } from 'react-query';
import { IS_PRODUCTION } from '@config';
import categoryAllIcon from '@images/category_all_icon.png';

const CategoryIndexPage = ({ f7route, f7router }) => {
  let { is_main } = f7route.query;

  const { data: itemCategories, isLoading, isFetching } = useQuery<Objects<Category>, Error>(
    'item_categories',
    getObjects({ model_name: 'category', category_type: 'item' }),
    {
      placeholderData: objectsSkeletonPlaceholder(4),
    },
  );

  (window as any).goPageBack = {
    promises: [] as Promise<string>[],
    callback() {
      if (f7router.history.length === 1) {
        (window as any).Android.pressBackOut();
      }
      f7router.back();
    },
  };

  return (
    <Page noToolbar={!is_main} name="home">
      {is_main ? (
        <Navbar title="카테고리" sliding={false} className="m-0">
          <Link href="/search" iconIos="f7:search" iconAurora="f7:search" iconMd="material:search" />
        </Navbar>
      ) : (
        <Navbar backLink title="카테고리" sliding={false}>
          <Subnavbar inner={false}>
            <Searchbar searchContainer=".search-list" searchIn=".item-title" disableButton={!theme.aurora} />
          </Subnavbar>
        </Navbar>
      )}
      <div className="bg-white overflow-hidden sm:rounded-lg">
        <div className="px-4 py-3 sm:px-6 mt-2">
          <h3 className="text-lg leading-6 font-medium text-gray-900">상품 카테고리</h3>
        </div>
      </div>
      {itemCategories && (
        <div className="grid grid-cols-4 gap-2 p-2 md:grid-cols-4">
          <div>
            {isLoading || isFetching ? (
              <Link href="#" className="bg-white h-20 flex flex-col items-center justify-center">
                <SkeletonBlock slot="media" className="w-14 h-14 rounded-lg" />
                <span className="text-gray-500 mt-1">
                  <SkeletonText>---</SkeletonText>
                </span>
              </Link>
            ) : (
              <Link href="/items?category_all=true" className="bg-white h-20 flex flex-col items-center justify-center">
                <img src={categoryAllIcon} alt="#" className="w-14 h-14 rounded-lg" />
                <span className="text-gray-500 mt-1">전체상품</span>
              </Link>
            )}
          </div>
          {itemCategories.objects.map((category: Category, i) => (
            <div key={i}>
              {isLoading || isFetching ? (
                <Link href="#" className="bg-white h-20 flex flex-col items-center justify-center" key={i}>
                  <SkeletonBlock slot="media" className="w-14 h-14 rounded-lg" />
                  <span className="text-gray-500 mt-1">
                    <SkeletonText>---</SkeletonText>
                  </span>
                </Link>
              ) : (
                <Link
                  href={`/items?category_id=${category.id}`}
                  className="bg-white h-20 flex flex-col items-center justify-center"
                  key={category.id}
                >
                  <img src={convertImagePath(category?.image_path)} alt="#" className="w-14 h-14 rounded-lg" />
                  <span className="text-gray-500 mt-1">{truncate(5, category?.title)}</span>
                </Link>
              )}
            </div>
          ))}
        </div>
      )}
    </Page>
  );
};
export default CategoryIndexPage;

import React, { useEffect } from 'react';
import { List, ListItem, Row, Col } from 'framework7-react';
import { useFormikContext } from 'formik';
import i18n from '../../assets/lang/i18n';

const AgreeCheckboxes = (props) => {
  const { values, handleChange, setFieldValue, setFieldTouched } = useFormikContext();

  const onClickAgreeAll = () => {
    _.map(props.names, (name) => {
      setFieldValue(name, true);
      setTimeout(() => setFieldTouched(name, true));
    });
  };

  return (
    <div className="pb-6 p-4 mt-6 ">
      <div className="text-right mt-5 mb-5">
        <a onClick={onClickAgreeAll} className="text-red-600 font-semibold text-sm red">
          전체 동의
        </a>
      </div>
      {_.map(props.names, (name, index) => (
        <div key={index}>
          <Row noGap>
            <Col width="90">
              <List className="m-0">
                <ListItem
                  checkbox
                  className="text-xs"
                  title={i18n.t('checkdesc')[name]}
                  name={name}
                  onChange={handleChange}
                  value={values[name]}
                  checked={values[name]}
                />
              </List>
            </Col>
            <Col width="10" className="text-right mt-2">
              <List className="m-0">
                <label className="item-checkbox">
                  <a className="text-sm underline link" href={`/terms/${name}`}>
                    보기
                  </a>
                </label>
              </List>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default AgreeCheckboxes;

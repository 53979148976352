import _ from 'lodash';
import { selector, selectorFamily } from 'recoil';
import { AuthState, Like } from '@constants';
import { userLikes, authState } from '@atoms';
import { filter } from 'lodash';

export const authSelector = selector({
  key: 'authSelector',
  get: ({ get }) => get(authState),
  set: ({ set }, newAuthState: AuthState) => set(authState, newAuthState),
});

export const getLikeIds = selectorFamily({
  key: 'likeIds',
  get: (model_name: string) => ({ get }) => {
    const likes = get(userLikes);
    // return filter(likes, (like: Like) => like.target_type === model_name);
    return likes[model_name] || [];
  },
});

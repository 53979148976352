import React from 'react';
import { Router } from 'framework7/types';
import packageJson from '../../../package.json';

/** 리터럴 혹은 불변 객체 */
export const TOKEN_KEY = `${packageJson.name}_TOKEN`;
export const CSRF_KEY = `${packageJson.name}_CSRF`;

export const ACTIONS = {
  NEW: 'new',
  INDEX: 'index',
  EDIT: 'edit',
  SHOW: 'show',
};

export type ID = string | number;

export interface Model {
  id: number;
  model_name: string;
  created_at: string;
  updated_at: string;
}

export interface LineItemParams<Props> {
  line_item: Props;
}

export interface CreateLineItemProps {
  item_id: number;
  type: 'cart' | 'buy_now';
}

export interface PaymentParams<Props> {
  order: Props;
}

export interface CreatePaymentsProps {
  receiver_name: string;
  receiver_phone: string;
  zipcode: string;
  address1: string;
  address2: string;
}

export const DEFAULT_ACTIONS = Object.values(ACTIONS);

/** 인터페이스 */
/* User Auth Interfaces */
export interface Token {
  token: null | string;
  csrf: null | string;
}

export interface AuthState extends Token {
  // isLoading: boolean;
  currentUser: any; // TODO currentUser 인터페이스화
}

export interface TokenPayload {
  user: any; //any TODO IToknePayload any 타입 변경
} /* Routes Interfaces */

export interface Route {
  path: string;
  component?: React.FunctionComponent;
  async?: any;
}

export interface ResourceRoute {
  resource: string;
  collection?: string[];
  member?: string[];
  only?: ('show' | 'edit' | 'new' | 'index')[];
}

// Shared

export interface Objects<T> {
  total_pages: number;
  total_count: number;
  objects: T[];
}

export interface InfiniteObjects<T> {
  next_cursor: number;
  total_count: number;
  objects: T[];
}

export interface Address extends DefaultProps {
  zipcode: string;
  address1: string;
  address2?: string;
}

interface DefaultProps {
  id: number;
  model_name: string;
  created_at: string;
  updated_at: string;
}

// Tables

export interface User extends Address {
  id: number;
  email: string;
  name: string;
  phone: string;
  image_path: string;
  status?: string;
  follow_id?: number;
  followers_count?: number;
  followings_count?: number;
  description?: string;
  total_payment?: number;
  failed_payment?: number;
  success_payment?: number;
  exit_reason?: string;
}

export interface AdminUser extends Model {
  email: string;
  user: User;
}

export interface Post {
  id: number;
  user?: User;
  user_id: number;
  title: string;
  content: string;
  view_count: number;
  created_at: string;
}

export interface Notice {
  id: number;
  title: string;
  content: string;
  view_count: number;
  created_at: string;
  banner_image_path: string;
  body: Text;
  end_at?: string;
  admin_user: AdminUser;
}

export interface Payment extends Model {
  price: number;
  status: 'success' | 'fail' | 'cancel' | 'partial_cancel';
  payment_type: 'normal' | 'refund';
  line_item_id: number;
  seller_id: number;
  payment_method: 'card' | 'deposit' | 'phone';
  imp_uid: string;
  merchant_uid: string;
  imp_success: string;
  error_msg: string;
  paid_at: string;
  receipt_url: string;
  order_number: string;
  item_id: number;
  item_title: string;
  is_paid: boolean;
}

export interface Term extends Model {
  id: number;
  title: string;
  body: string;
  term_type?: 'use_term' | 'privacy' | 'marketing';
}

export interface Faq {
  id: string;
  question: string;
  answer: string;
  faq_type: string;
}

export interface Category {
  id: number;
  title: string;
  body: string;
  image_path: string;
  ancestry: string;
  is_root: boolean;
}

export interface Like {
  id: number;
  target_type: string;
  target_id: number;
  user?: User;
  target?: Item;
}

export interface Follow {
  id: number;
  followed_id: number;
  follower_id: number;
  is_followed: boolean;
}
export interface LineItems {
  order: Order;
  line_items: LineItem[];
}

export interface Items {
  objects: Item[];
}

export interface Orders {
  objects: Order[];
}

export interface Item extends DefaultProps {
  name: string;
  status: 'active' | 'disabled';
  list_price: number;
  sale_price: number;
  description: string;
  title: string;
  image_path: string;
  likes_count?: number;
  price: number;
  stock: number;
  remain_time: string;
  category?: Category;
  image: string;
  sell_status: 'on_sale' | 'sold';
  is_valid: boolean;
  buyer_name: string;
  category_name: string;
  sell_type: string;
  image_ids?: number[];
  images?: Image[];
  seller?: Seller;
  like_id?: number;
  category_string?: string;
  texture?: string;
  manufacturer?: string;
  status_ko?: string;
  item_width?: number;
  item_height?: number;
  item_depth?: number;
  item_code: string;
  deliver_method?: string;
}

export interface Order extends Address {
  id: number;
  user_id: number;
  status: 'cart' | 'buy_now' | 'paid' | 'refund' | 'before_shipping' | 'shipping' | 'shipped' | 'refund_request';
  invoice_num?: string;
  receiver_name: string;
  receiver_phone: string;
  shipping_fee: number;
  total: number;
  deliver_company?: string;
  order_number: string;
  line_items?: LineItem[];
  paid_at?: string;
  payment_method: 'card' | 'deposit' | 'phone';
  receipt_url?: string;
  cancelled_at?: string;
  include_deliver_total?: number;
  deliver_fee?: number;
  deliver_method?: string;
  item_total?: number;
}

export interface LineItem {
  id: number;
  order_id: number;
  status: string;
  price: number;
  is_checked: boolean;
  item_id: number;
  item_name: string;
  category_name: string;
  image_path: string;
  order?: Order;
  unit_price: number;
  quantity: number;
  payment: Payment;
  item?: Item;
}

export interface ContactType {
  contact_type: 'item' | 'payment' | 'etc';
}

export interface UserLikesProps {
  [key: string]: Like[];
}

export interface PageRouteProps {
  f7route: Router.Route;
  f7router: Router.Router;
}

export interface Contact {
  id: number;
  user_id: number;
  title: string;
  content: string;
  answer: string;
  contact_type: string;
  item_id: number;
  user_name: string;
  admin_user: AdminUser;
  created_at: string;
  updated_at: string;
}

export interface Seller {
  id: number;
  title: string;
  active_items_count?: number;
  sold_items_count?: number;
  sell_start_at?: string;
  item_registered_at?: string;
  open_at?: string;
  closed_at?: string;
  sell_end_at?: string;
  second_open_house_at?: string;
  first_open_house_at?: string;
  seller_lineitems_count?: number;
  lat?: number;
  lng?: number;
  pickup_address?: string;
  user_id?: number;
}

export interface Image extends Model {
  imagable_type: string;
  imagable_id: number;
  image: string;
  image_path: string;
}

import React from 'react';
import { f7, f7ready, List, ListInput } from 'framework7-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { createObject, updateObject } from '@api';
import { toast } from '../../js/utils';
import { useMutation, useQueryClient } from 'react-query';

const ContactNewSchema = Yup.object().shape({
  title: Yup.string().required('필수 입력사항입니다'),
  content: Yup.string()
    .required('필수 입력사항입니다')
    .min(5, '5글자 이상 입력해주셔야합니다')
    .max(1000, '1000자 미만으로 작성해주셔야합니다'),
});

const ContactForm = ({ contact = null, f7router, f7route, itemId = null }) => {
  const { mutate } = !contact
    ? useMutation(createObject({ model_name: 'contact' }))
    : useMutation(updateObject(contact.id, { model_name: 'contact' }));
  const queryClient = useQueryClient();
  // const item_id = f7router.url.split("=")[1];

  return (
    <List noHairlinesMd>
      <Formik
        initialValues={
          itemId
            ? {
                title: contact?.title || '',
                content: contact?.content || '',
                contact_type: 'item_contact',
                item_id: itemId || '',
              }
            : {
                title: contact?.title || '',
                content: contact?.content || '',
                contact_type: 'seller_contact',
                item_id: itemId || '',
              }
        }
        validationSchema={ContactNewSchema}
        onSubmit={async (values, { setValues, setSubmitting }) => {
          f7.dialog.preloader('잠시만 기다려주세요...');
          setSubmitting(true);
          mutate(
            { contact: values },
            {
              onSuccess: (data, variables, context) => {
                f7.dialog.close();
                queryClient.invalidateQueries('contacts');
                !!contact && queryClient.invalidateQueries(['contact', contact.id]);
                f7router.back();
                toast
                  .get()
                  .setToastText(`문의가 정상적으로 ${!contact ? '등록' : '수정'}되었습니다.`)
                  .openToast();
              },
            },
          );
        }}
        enableReinitialize
        validateOnMount
      >
        {({ values, isSubmitting, isValid, handleChange, handleBlur, touched, errors }) => (
          <Form>
            <div className="px-5 py-2">
              <div className="mt-6">
                <label htmlFor="title" className="text-sm font-semibold text-gray-900">
                  제목
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="제목을 입력해주세요"
                  />
                  <p className="text-red-500 text-sm">{touched.title && errors.title}</p>
                </div>
              </div>
              <div className="mt-6">
                <label htmlFor="content" className="text-sm font-semibold text-gray-900">
                  내용
                </label>
                <div className="mt-1">
                  <textarea
                    name="content"
                    id="content"
                    value={values.content}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="내용을 입력해주세요"
                  />
                  <p className="text-red-500 text-sm">{touched.content && errors.content}</p>
                </div>
              </div>
            </div>
            <div className="p-5">
              <button
                type="submit"
                className="mt-4 w-full bg-blue-500 border border-transparent px-5 py-3 inline-flex items-center justify-center text-base font-medium rounded-md text-white hover:bg-blue-700 sm:mt-10 sm:w-auto xl:mt-0"
                disabled={isSubmitting || !isValid}
              >
                {!contact ? '작성' : '수정'}하기
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </List>
  );
};

export default ContactForm;

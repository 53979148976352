import { ListItem, Swiper, SwiperSlide, List } from 'framework7-react';
import React from 'react';
import HeartContainer from '@components/shared/HeartContainer';
import { getItems } from '@api';
import { useQuery } from 'react-query';
import { currency, truncate, convertImagePath } from '@js/utils';
import { Items, Item } from '@constants';
import { map } from 'lodash';
import ReactQueryState from '../components/shared/ReactQueryState';

const ItemsGrid = () => {
  const { data, status, error } = useQuery<Items, Error>('items', getItems());

  return (
    <>
      <div className="mt-8 px-5 py-2 sm:px-6 row mb-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900 col-50">오늘의 상품</h3>
        <span className="float-right px-2.5 py-0.5 rounded-full text-medium text-center font-medium bg-gray-100 text-gray-800 col-30">
          <a href="/items?today=true">더보기</a>
        </span>
      </div>
      <ReactQueryState data={data} status={status} error={error} />

      <List mediaList noHairlines className="mt-0 text-sm font-thin">
        {data && (
          <Swiper speed={100} slidesPerView={2.5} spaceBetween={10} observer loop={false}>
            {map(data.objects, (item: Item, index) => (
              <React.Fragment key={item.id}>
                <SwiperSlide key={item.id} className="mb-4 px-2">
                  <div className="grid-list-item relative">
                    <ListItem
                      key={index}
                      virtualListIndex={data.objects.indexOf(item)}
                      mediaItem
                      className="w-40 m-auto"
                    >
                      <a href={`/items/${item.id}`} className="item-link">
                        <div className="item-media">
                          <img
                            slot="media"
                            src={convertImagePath(item?.image_path)}
                            className="w-40 m-auto radius rounded shadow-sm"
                            alt=""
                          />
                        </div>
                        <div className="item-header mt-1">
                          {item?.category_name && <>[{truncate(14, item?.category_name)}]</>}
                        </div>
                        <div className="font-semibold line-clamp-2">{item?.title}</div>
                        <div className="item-subtitle">{`${currency(item?.price)}원`}</div>
                      </a>
                      <div className="flex flex-row">
                        <a
                          href={`/items/${item.id}`}
                          className="text-xs font-medium text-red-700 w-full inline-flex justify-left align-middle mt-2"
                        >
                          남은시간: {item?.remain_time || '판매종료'}
                        </a>
                        <HeartContainer
                          target={item}
                          heartClassName="z-50 text-red-500 text-2xl"
                          className="justify-right align-middle inline-flex mr-1"
                        />
                      </div>
                    </ListItem>
                  </div>
                </SwiperSlide>
              </React.Fragment>
            ))}
          </Swiper>
        )}
      </List>
    </>
  );
};
export default ItemsGrid;

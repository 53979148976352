import React, { useState } from 'react';
import QrReader from 'react-qr-reader';
import { Button, Link, Navbar, NavRight, Page, Popup, f7 } from 'framework7-react';
import { getItemByCode } from '@api';

const QrScanner = ({ f7router }) => {
  const [popupOpened, setPopupOpened] = useState(false);

  const handleScan = async (result) => {
    if (result) {
      const { data } = await getItemByCode(result);
      if (data?.id) {
        f7.popup.close('.qr-popup');
        f7router.navigate(`/items/${data.id}`);
      } else {
        f7.popup.close('.qr-popup');
        f7.dialog.alert('상품 정보를 찾을 수 없습니다');
      }
    }
  };

  const handleError = (errorMessage) => {
    // f7.dialog.alert(errorMessage);
  };

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-4 lg:px-6 mt-2">
        <Button
          className="items-center text-center px-6 py-6 mb-8 mt-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          popupOpen=".qr-popup"
        >
          QR코드 스캔
        </Button>
      </div>
      <Popup
        className="qr-popup"
        opened={popupOpened}
        onPopupClosed={() => {
          setPopupOpened(false);
        }}
      >
        <Page>
          <Navbar title="QR코드 스캔">
            <NavRight>
              <Link popupClose>닫기</Link>
            </NavRight>
          </Navbar>
          <QrReader delay={300} onScan={handleScan} onError={handleError} resolution={800} />
        </Page>
      </Popup>
    </>
  );
};

export default React.memo(QrScanner);

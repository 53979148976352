import _ from 'lodash';
import HomePage from '@pages/home';
import SearchPage from '@pages/search';
import MyPage from '@pages/users/mypage';
import LoginPage from '@pages/users/sessions/new';
import SignUpPage from '@pages/users/registrations/new';
import AuthenticationPage from '@pages/users/registrations/authentication';
import RegistrationEditPage from '@pages/users/registrations/edit';
import UserIndexPage from '@pages/users/index';
import { ResourceRoute } from '@constants';
import IntroPage from '@pages/intro';
import ItemsRegisterPage from '@pages/items/register';
import SellerItemsPage from '@pages/sellers/items';
import SellerDashboardPage from '@pages/sellers/dashboard';
import SellerLineItemsPage from '@pages/sellers/line_items';
import PasswordNewPage from '@pages/users/passwords/new';
import InfoSearchPage from '@pages/users/infoSearch';
import EmailSearchPage from '@pages/users/emailSearch';
import UserWithdrawPage from '@pages/users/withdraw';
import WithdrawCompletePage from '@pages/users/withdrawComplete';
import { mapResourceRoute, mapAsyncRoute, mergeRoutes } from './routes.utils';

/**
 * @resourceRoutes
 * @param {String} resource (required)
 * @param {Array} only (optional)
 * ex) ['show'] -> [{ path: 'items/:id', component: '@pages/items/show.{jsx|tsx}'}]
 * only 를 명시 안해주면 show, index, new, edit 을 모두 탐색 합니다.
 *
 * @param {Array} collection (optional)
 * ex) ['buy'] -> [{ path: 'items/buy', component: '@pages/items/buy.{jsx|tsx}'}]
 *
 * @param {Array} member (optional)
 * ex) ['my_item'] -> [{ path: 'items/:id/my_item', component: '@pages/items/my_item.{jsx|tsx}'}]
 */
const resourceRoutes: ResourceRoute[] = [
  {
    resource: 'items',
  },
  {
    resource: 'users',
  },
  {
    resource: 'posts',
  },
  {
    resource: 'notices',
  },
  {
    resource: 'faqs',
  },
  {
    resource: 'line_items',
    only: ['index', 'show'],
  },
  {
    resource: 'terms',
    only: ['index', 'show'],
  },
  {
    resource: 'payments',
    only: ['show', 'index'],
  },
  {
    resource: 'contacts',
  },
  {
    resource: 'notifications',
  },
  {
    resource: 'reports',
  },
  {
    resource: 'categories',
  },
  {
    resource: 'likes',
  },
  {
    resource: 'orders',
  },
];

/**
 * @customRoutes
 * @param {String} path (required)
 * @param {Array} component (required)
 */
const customRoutes = [
  { path: '/', component: HomePage },
  { path: '/intro', component: IntroPage },
  { path: '/search', component: SearchPage },
  { path: '/authentication', component: AuthenticationPage },
  { path: '/users/sign_in', component: LoginPage },
  { path: '/users/sign_up', component: SignUpPage },
  { path: '/users/edit', component: RegistrationEditPage },
  { path: '/users/withdraw', component: UserWithdrawPage },
  { path: '/users/withdraw_complete', component: WithdrawCompletePage },
  { path: '/info_search', component: InfoSearchPage },
  { path: '/passwords/new', component: PasswordNewPage },
  { path: '/email_search', component: EmailSearchPage },
  { path: '/mypage', component: MyPage },
  { path: `/items/register/:id`, component: ItemsRegisterPage },
  { path: `/sellers/:id/items`, component: SellerItemsPage },
  { path: `/sellers/:id/dashboard`, component: SellerDashboardPage },
  { path: `/sellers/:id/line_items`, component: SellerLineItemsPage },
];

/**
 * @asyncRoutes
 * @param {String} path (required)
 * @param {React.FC} component (required)
 * asyncRoutes 랑 path 가 중복되면 asyncRoute 를 우선 적용
 */
const asyncRoutes = [{ path: '/users', component: UserIndexPage }];

const mappedResourceRoutes = resourceRoutes
  .map((resource) => mapResourceRoute(resource))
  .reduce((acc, routes) => [...acc, ...routes], []);

const mappedAsyncRoutes = asyncRoutes.map((route) => mapAsyncRoute(route));

export default mergeRoutes(customRoutes, mappedResourceRoutes, mappedAsyncRoutes);

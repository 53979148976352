import React from 'react';
import { Page, Navbar } from 'framework7-react';
import ContactForm from './form';

const ContactNewPage = ({ f7route, f7router }) => {
  const itemId = f7route.query.item_id;

  return (
    <Page noToolbar>
      <Navbar title="문의 작성" sliding={false} backLink />
      <ContactForm f7router={f7router} f7route={f7route} itemId={itemId} />
    </Page>
  );
};

export default ContactNewPage;

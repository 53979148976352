import React from 'react';
import { Row, Col, ListItem, Icon } from 'framework7-react';
import { Order } from '@constants';
import { currency } from '@js/utils';

interface TotalSheetProps {
  order: Order;
  className?: string;
  deliverFee?: number;
  orderTotal?: number;
  isPickup?: boolean;
}

const paymentType = ['카드', '계좌이체', '휴대폰'];

const TotalSheet = ({ order, className, deliverFee, orderTotal, isPickup }: TotalSheetProps) => (
  <>
    <Row className={`p-3 ${className || ''}`}>
      <Col width="50">총 상품금액</Col>
      <Col className="text-right mb-2" width="50">
        {currency(order?.total)} 원
      </Col>

      <Col width="50">총 배송비</Col>
      <Col className="text-right mb-2" width="50">
        {!isPickup ? `+ ${currency(deliverFee)} 원` : `+ 0 원`} <br />
        {!isPickup && <small>현장픽업 시 배송비 무료</small>}
      </Col>

      {/* <Col width="50">총 할인금액</Col>
      <Col className="text-right mb-3" width="50">
        - 0 원
      </Col> */}

      <Col className="font-bold" width="50">
        결제 금액
      </Col>
      <Col className="font-bold text-xl text-right" width="50">
        {currency(orderTotal || 0)} 원
      </Col>
    </Row>
  </>
);

export default React.memo(TotalSheet);

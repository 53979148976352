import React, { useState } from 'react';
import { f7, Page, Navbar } from 'framework7-react';
import { sleep } from '../../js/utils';
import { useRecoilValue } from 'recoil';
import { userInfos } from '@atoms';
import { useImageManager } from '@hooks/useImageManager';
import ItemForm from './Form';

const ItemsRegisterPage = ({ f7router, f7route }) => {
  const user = useRecoilValue(userInfos);
  const sellerId: number = f7route.params.id;
  const [itemImages, setItemImages] = useState([]);
  const { deleteImage } = useImageManager();

  return (
    <Page noToolbar>
      <Navbar
        backLink
        title="상품등록"
        sliding={false}
        onBackClick={() => {
          // let backConfirm = confirm('등록한 정보를 저장 하시겠습니까?');
          // if (backConfirm) {
          // } else {
          if (itemImages) {
            itemImages.forEach((image) => deleteImage(image));
          }
          f7router.back();
          f7.toast.create({ text: '상품 등록이 취소되었습니다', position: 'center', closeTimeout: 2000 }).open();
          // }
        }}
      />
      <ItemForm f7router={f7router} user={user} seller={sellerId} setItemImages={setItemImages} />
    </Page>
  );
};

export default ItemsRegisterPage;

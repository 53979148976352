import React, { useEffect, useState } from 'react';
import { f7, List, ListItem, ListInput, Icon, Button } from 'framework7-react';
import { createObject, updateObject, getObjects, createDropzoneImage, updateItemImage, getCategories } from '@api';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { Category, Objects } from '@constants';
import { objectsSkeletonPlaceholder, convertImagePath } from '@js/utils';
import { useImageManager } from '@hooks/useImageManager';
import QrScanner from '@components/shared/QrScanner';
import { AppInterface } from '@utils/interfaces';
import { toast } from '../../js/utils';

const ItemNewSchema = Yup.object().shape({
  title: Yup.string().required('필수 입력사항 입니다'),
});

const ItemForm = ({ item = null, f7router, user, seller, setItemImages }) => {
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [mainImage, setMainImage] = useState('');
  const [isIos, setIsIos] = useState(false);
  // const [images, setImages] = useState([]);

  const { mutate } = !item
    ? useMutation(createObject({ model_name: 'item' }))
    : useMutation(updateObject(item.id, { model_name: 'item' }));
  const queryClient = useQueryClient();

  const setCategoriesSelect = ({ data }) => {
    const mains = data.filter((v) => v.is_root === true);
    setMainCategories(mains);
  };

  const { data: categories, isSuccess } = useQuery<Objects<Category>, Error>(
    'item_form_categories',
    getCategories({ item_category: true }),
    {
      placeholderData: objectsSkeletonPlaceholder(4),
      onSuccess: (data) => setCategoriesSelect({ data }),
    },
  );

  const { images, deleteImage, isUploading, isError, error, inputRef } = useImageManager();

  const [imageIds, setImageIds] = useState([]);

  useEffect(() => {
    if (item) {
      setMainImage(`${convertImagePath(item.image_path)}`);
    }

    if (images) {
      setImageIds(images.map((image) => image.id));
      setItemImages(images.map((image) => image));
    }

    if (/iPad|iPhone|iPod|MacIntel/.test(navigator.platform)) {
      setIsIos(true);
    }
  }, [item, images]);

  return (
    <Formik
      initialValues={
        item
          ? {
              title: item?.title,
              texture: item?.texture,
              manufacturer: item?.manufacturer,
              status: item?.status ? item?.status : 'best',
              item_width: item?.item_width,
              item_height: item?.item_height,
              item_depth: item?.item_depth,
              price: item?.price,
              description: item?.description,
              deliver_method: item?.deliver_method ? item?.deliver_method : 'general',
              sell_type: item?.sell_type ? item?.sell_type : 'temporary',
              user_id: user?.id,
              seller_id: seller,
              item_code: item?.item_code,
              image_ids: item?.image_ids || [],
            }
          : {
              title: '',
              texture: '',
              manufacturer: '',
              status: 'best',
              item_width: '',
              item_height: '',
              item_depth: '',
              price: '',
              description: '',
              item_code: '',
              deliver_method: 'general',
              sell_type: 'temporary',
              user_id: user?.id,
              seller_id: seller,
              image_ids: [],
            }
      }
      validationSchema={ItemNewSchema}
      onSubmit={async (values, { setValues, setSubmitting }) => {
        f7.dialog.preloader('잠시만 기다려주세요...');
        values = { ...values, image_ids: imageIds };
        setSubmitting(true);
        const formData = new FormData();
        const itemImageformData = new FormData();
        itemImageformData.append('item[image]', values.image);

        mutate(
          { item: values },
          {
            onSuccess: async (data, variables, context) => {
              formData.append('target_id', data.id);
              formData.append('target_type', 'Item');
              // await createImages(formData);
              if (values.image !== undefined) {
                await updateItemImage(data.id, itemImageformData);
              }
              f7.dialog.close();
              queryClient.invalidateQueries('items');
              queryClient.invalidateQueries('edit_item');
              !!item && queryClient.invalidateQueries(['item', item.id]);
              f7router.back();
              toast
                .get()
                .setToastText(`상품이 ${!item ? '등록' : '수정'}되었습니다.`)
                .openToast();
            },
          },
        );
      }}
      enableReinitialize
      validateOnMount
    >
      {({ handleChange, handleBlur, values, errors, touched, isSubmitting, isValid, setFieldValue, submitForm }) => (
        <Form encType="multipart/form-data">
          <div className="bg-white overflow-hidden sm:rounded-lg">
            <div className="px-2 py-3 sm:px-6 mt-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">상품 기본 정보</h3>
            </div>
          </div>
          {isIos ? (
            <div className="max-w-7xl mx-auto px-4 sm:px-4 lg:px-6 mt-2">
              <Button
                onClick={() => {
                  AppInterface.callQrScan().then((data) => {
                    setFieldValue('item_code', data);
                  });
                }}
                className="items-center text-center px-6 py-6 mb-8 mt-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                QR코드 스캔
              </Button>
            </div>
          ) : (
            <QrScanner />
          )}
          <List noHairlinesMd className="m-0">
            <ListInput
              label="상품코드"
              type="text"
              name="item_code"
              placeholder="상품코드를 스캔해주세요"
              readonly
              // eslint-disable-next-line no-return-assign
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.item_code}
              errorMessageForce
              errorMessage={touched.title && errors.title}
            />
            <ListInput
              label="상품명"
              type="text"
              name="title"
              placeholder="상품명을 입력해주세요"
              clearButton
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              errorMessageForce
              errorMessage={touched.title && errors.title}
            />
            <ListInput
              label="재질"
              type="text"
              name="texture"
              placeholder="상품의 재질을 입력해주세요"
              clearButton
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.texture}
              errorMessageForce={true}
              errorMessage={touched.texture && errors.texture}
            />
            <ListInput
              label="제조사"
              type="text"
              name="manufacturer"
              placeholder="상품의 제조사를 입력해주세요"
              clearButton
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.manufacturer}
              errorMessageForce={true}
              errorMessage={touched.manufacturer && errors.manufacturer}
            />
            <ListInput
              label="가격"
              type="number"
              name="price"
              placeholder="숫자만 입력(단위: 원)"
              clearButton
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.price}
              errorMessageForce={true}
              errorMessage={touched.price && errors.price}
              className="col"
            />
            <ListInput
              label="상세설명"
              type="textarea"
              name="description"
              placeholder="상세설명을 입력해주세요"
              clearButton
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              errorMessageForce={true}
              errorMessage={touched.description && errors.description}
              className="col"
            />
            <ul className="row">
              <ListInput
                label="가로"
                type="number"
                name="item_width"
                placeholder="cm"
                clearButton
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.item_width}
                errorMessageForce
                errorMessage={touched.item_width && errors.item_width}
                className="col"
              />
              <ListInput
                label="세로"
                type="number"
                name="item_height"
                placeholder="cm"
                clearButton
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.item_height}
                errorMessageForce
                errorMessage={touched.item_height && errors.item_height}
                className="col"
              />
              <ListInput
                label="높이"
                type="number"
                name="item_depth"
                placeholder="cm"
                clearButton
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.item_depth}
                errorMessageForce
                errorMessage={touched.item_depth && errors.item_depth}
                className="col"
              />
            </ul>
          </List>
          <List>
            <div className="bg-white overflow-hidden sm:rounded-lg">
              <div className="px-2 py-3 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">상품 사진</h3>
              </div>
            </div>
            <div className="bg-white overflow-hidden sm:rounded-lg">
              <div className="px-2 sm:px-6">
                <h4 className="text-sm leading-6 text-gray-900">대표 사진</h4>
              </div>
            </div>
            <div className="justify-start">
              <div className="m-1">
                <div className="justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex">
                      <label
                        htmlFor="main-file-upload"
                        className="m-auto cursor-pointer bg-white rounded-md text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                      >
                        <span>사진 업로드</span>
                        <input
                          id="main-file-upload"
                          name="image"
                          type="file"
                          className="sr-only"
                          onChange={async (e) => {
                            let reader = new FileReader();
                            let file = e.target.files[0];
                            reader.onloadend = () => {
                              setFieldValue('image', file);
                              setMainImage(reader.result);
                            };
                            reader.readAsDataURL(file);
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {mainImage && (
                <div className="m-1">
                  <div className="px-2 pt-1 pb-2 rounded-md">
                    <div className="space-y-1 text-center">
                      <small>대표 사진 미리보기</small>
                      <div>
                        <img
                          src={`${mainImage}`}
                          alt=""
                          className="open-photo-browser m-auto radius rounded shadow-sm w-40"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-4 p-2">
              <div className="block text-sm font-semibold text-gray-900">
                상품 사진 (상세)
                {isUploading && <div className="text-sm mt-2">업로드 중입니다...</div>}
              </div>
              <label className="cursor-pointer mt-1 flex text-center justify-center w-20 h-20 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <span className="flex text-sm text-gray-600 pt-4">
                    사진 <br />
                    업로드
                  </span>
                  <input ref={inputRef} type="file" accept="image/*" multiple className="hidden" />
                </div>
              </label>

              <div className="flex flex-row space-x-4 mt-4 overflow-scroll">
                {images.map((image) => (
                  <div className="flex flex-col flex-shrink-0">
                    <img
                      key={image?.id}
                      src={convertImagePath(image?.image_path)}
                      className="w-36 h-36 rounded shadow"
                      alt=""
                    />
                    <a
                      href="#"
                      className="button inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => deleteImage(image)}
                    >
                      삭제
                    </a>
                  </div>
                ))}
              </div>
              {isError && <div>{error.message}</div>}
            </div>
          </List>
          <List noHairlinesMd>
            <div className="bg-white overflow-hidden sm:rounded-lg">
              <div className="px-2 py-3 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">부가 정보</h3>
              </div>
            </div>
            <div className="item-content iten-input">
              <div className="item-inner">
                <div className="item-title item-label">
                  상품 카테고리 <br />{' '}
                  {item && `현재 카테고리: ${item?.category_string ? item?.category_string : '선택 없음'}`}
                </div>
              </div>
            </div>
            {categories?.total_count !== 0 && (
              <ul className="row">
                <ListInput
                  type="select"
                  placeholder="카테고리를 선택해주세요"
                  className="col"
                  onChange={async (e) => {
                    values.category_id = e.target.value;
                    const childCategories = categories.filter((v) => v.ancestry === e.target.value);
                    setSubCategories(childCategories);
                  }}
                  // onFocus={async () => {
                  //   const rootCategories = categories.objects.filter((v) => v.is_root === true);
                  //   setMainCategories(rootCategories);
                  // }}
                >
                  <Icon icon="demo-list-icon" slot="media" />
                  <option value="" />
                  {mainCategories &&
                    mainCategories.map((category, index) => (
                      <option value={category.id} key={index}>
                        {category.title}
                      </option>
                    ))}
                </ListInput>
                <ListInput
                  type="select"
                  className="col"
                  placeholder="카테고리를 선택해주세요"
                  onChange={async (e) => {
                    setFieldValue('category_id_2', e.target.value);
                  }}
                >
                  <Icon icon="demo-list-icon" slot="media" />
                  <option value="" />
                  {subCategories &&
                    subCategories.map((category, index) => (
                      <option value={category.id} key={index}>
                        {category.title}
                      </option>
                    ))}
                </ListInput>
              </ul>
            )}

            <div className="item-content iten-input">
              <div className="item-inner">
                <div className="item-title item-label">상태</div>
              </div>
            </div>
            <ul className="row">
              <ListItem
                radio
                radioIcon="start"
                value="best"
                name="demo-media-radio"
                onChange={(e) => {
                  values.status = e.target.value;
                }}
                title="상"
                className="col"
                defaultChecked={item ? item?.status === 'best' : true}
              />
              <ListItem
                radio
                radioIcon="start"
                value="better"
                name="demo-media-radio"
                onChange={(e) => {
                  values.status = e.target.value;
                }}
                title="중상"
                className="col"
                defaultChecked={item && item?.status === 'better'}
              />
            </ul>
            <ul className="row">
              <ListItem
                radio
                radioIcon="start"
                value="normal"
                name="demo-media-radio"
                onChange={(e) => {
                  values.status = e.target.value;
                }}
                title="중하"
                className="col"
                defaultChecked={item && item?.status === 'normal'}
              />
              <ListItem
                radio
                radioIcon="start"
                value="bad"
                name="demo-media-radio"
                onChange={(e) => {
                  values.status = e.target.value;
                }}
                title="하"
                className="col"
                defaultChecked={item && item?.status === 'bad'}
              />
            </ul>
            <div className="item-content iten-input">
              <div className="item-inner">
                <div className="item-title item-label">판매형태</div>
              </div>
            </div>
            <ul className="row">
              <ListItem
                radio
                radioIcon="start"
                value="temporary"
                name="sell-type-radio"
                onChange={(e) => {
                  values.sell_type = e.target.value;
                }}
                title="기간판매"
                className="col"
                defaultChecked={item ? item?.sell_type === 'temporary' : true}
              />
              <ListItem
                radio
                radioIcon="start"
                value="regular"
                name="sell-type-radio"
                onChange={(e) => {
                  values.sell_type = e.target.value;
                }}
                title="상시판매"
                className="col"
                defaultChecked={item && item?.sell_type === 'regular'}
              />
            </ul>
            <div className="item-content iten-input">
              <div className="item-inner">
                <div className="item-title item-label">배송</div>
              </div>
            </div>
            <ul className="row">
              <ListItem
                radio
                radioIcon="start"
                value="general"
                name="deliver-method-radio"
                onChange={(e) => {
                  values.deliver_method = e.target.value;
                }}
                title="일반배송"
                className="col"
                defaultChecked={item ? item?.deliver_method === 'general' : true}
              />
              <ListItem
                radio
                radioIcon="start"
                value="freight"
                name="deliver-method-radio"
                onChange={(e) => {
                  values.deliver_method = e.target.value;
                }}
                title="화물배송(착불)"
                className="col"
                defaultChecked={item && item?.deliver_method === 'freight'}
              />
            </ul>
          </List>

          <div className="p-4 row">
            <a
              className="col-30 button button-fill button-large"
              onClick={() => {
                const cancelConfirm = confirm('등록한 정보를 저장 하시겠습니까?');
                if (cancelConfirm) {
                  submitForm();
                } else {
                  if (images) {
                    images.forEach((image) => deleteImage(image));
                  }
                  f7router.back();
                  f7.toast
                    .create({ text: '상품 등록이 취소되었습니다', position: 'center', closeTimeout: 2000 })
                    .open();
                }
              }}
            >
              취소
            </a>
            <button
              type="submit"
              className="col-70 button button-fill button-large disabled:opacity-50"
              disabled={isSubmitting || !isValid}
            >
              등록하기
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ItemForm;

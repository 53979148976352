import React, { useRef } from 'react';
import { Page, Navbar } from 'framework7-react';
import { getInfiniteObjects, getUser } from '@api';
import { useInfiniteQuery, useQueryClient, useQuery } from 'react-query';
import { Payment, InfiniteObjects, User } from '@constants';
import { currency, dateFormat } from '@js/utils';
import useAuth from '@hooks/useAuth';
import i18n from '../../assets/lang/i18n';
import { useRecoilValue } from 'recoil';
import { userInfos } from '@atoms';

const PaymentIndexPage = ({ f7router, f7route }) => {
  const { currentUser } = useAuth();
  const allowInfinite = useRef(true);
  const queryClient = useQueryClient();
  const { user_id } = f7route.query;
  const USER_KEY = ['user', parseInt(user_id, 10)];

  const { status, data: user, error } = useQuery<User, Error>(USER_KEY, getUser(user_id), {
    enabled: !!user_id,
  });

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery<
    InfiniteObjects<Payment>,
    Error
  >(
    'payments',
    getInfiniteObjects({
      model_name: 'payment',
      q: {
        seller_id_eq: currentUser?.seller?.id,
        s: ['created_at desc'],
      },
    }),
    {
      getNextPageParam: (lastPage) => lastPage.next_cursor,
      enabled: !!currentUser,
    },
  );

  const onRefresh = async (done) => {
    await queryClient.removeQueries('payments');
    await refetch();
    allowInfinite.current = true;
    done();
  };

  const loadMore = async () => {
    if (!allowInfinite.current) return;
    allowInfinite.current = false;
    if (hasNextPage && !isFetchingNextPage) {
      await fetchNextPage();
      allowInfinite.current = true;
    }
  };

  return (
    <Page
      name="payment"
      noToolbar
      infinite
      infinitePreloader={isFetchingNextPage}
      onInfinite={loadMore}
      onPtrRefresh={onRefresh}
      className="bg-gray-100"
      ptr
    >
      <Navbar backLink title="정산 목록" />
      <div className="p-4">
        <h3 className="text-lg mb-5 mt-5 leading-6 font-semibold text-gray-900">정산 현황</h3>
        <>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <div className="relative bg-white pt-5 px-4 pb-8 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
              <dt>
                <div className="absolute bg-white rounded-md p-3">
                  <i className="las la-balance-scale" style={{ color: 'gray', fontSize: '28px' }} />
                </div>
                <p className="ml-16 text-sm font-medium text-gray-500 truncate">총 결제 가격</p>
              </dt>
              <dd className="ml-16 flex items-baseline sm:pb-7">
                <p className="text-base font-semibold text-gray-900 mt-1">{`${currency(user?.total_payment)}원`}</p>
              </dd>
            </div>
            <div className="relative bg-white pt-5 px-4 pb-8 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
              <dt>
                <div className="absolute bg-white rounded-md p-3">
                  <i className="las la-check-circle" style={{ color: 'gray', fontSize: '28px' }} />
                </div>
                <p className="ml-16 text-sm font-medium text-gray-500 truncate">정산 완료</p>
              </dt>
              <dd className="ml-16 flex items-baseline sm:pb-7">
                <p className="text-base font-semibold text-gray-900 mt-1"> {`${currency(user?.success_payment)}원`}</p>
              </dd>
            </div>
            <div className="relative bg-white pt-5 px-4 pb-8 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
              <dt>
                <div className="absolute bg-white rounded-md p-3">
                  <i className="las la-sync" style={{ color: 'gray', fontSize: '28px' }} />
                </div>
                <p className="ml-16 text-sm font-medium text-gray-500 truncate">정산 예정</p>
              </dt>
              <dd className="ml-16 flex items-baseline sm:pb-7">
                <p className="text-base font-semibold text-gray-900 mt-1"> {`${currency(user?.failed_payment)}원`}</p>
              </dd>
            </div>
          </dl>
        </>
      </div>
      {data && data?.pages[0]?.total_count ? (
        <>
          <h3 className="px-4 text-lg mb-5 mt-5 leading-6 font-semibold text-gray-900">정산 상세 내역</h3>
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg p-4">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-3 text-center py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          정산 가격
                        </th>
                        <th
                          scope="col"
                          className="px-3 text-center py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          주문 상세
                        </th>
                        <th
                          scope="col"
                          className="px-3 text-center py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          정산
                        </th>
                        <th
                          scope="col"
                          className="px-3 text-center py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          결제
                        </th>

                        <th
                          scope="col"
                          className="px-3 text-center py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          결제 방법
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {data &&
                        data?.pages[0]?.total_count > 0 &&
                        data.pages.map((page, i) => (
                          <React.Fragment key={i}>
                            {page.objects.map((payment: Payment) => (
                              <tr key={payment.id}>
                                <td className="px-4 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 font-semibold">
                                    {`${currency(payment?.price)}원`}
                                  </div>
                                </td>
                                <td className="px-4 py-4 whitespace-nowrap">
                                  {payment.item_id ? (
                                    <a href={`/items/${payment.item_id}`}>
                                      <div className="text-sm text-gray-900 underline">{payment.item_title}</div>
                                      <div className="text-xs text-gray-600">{payment.order_number}</div>
                                      <div className="text-xs text-gray-600">
                                        {dateFormat(payment.created_at, 'day')}
                                      </div>
                                    </a>
                                  ) : (
                                    <div className="text-sm text-gray-900">{payment.order_number}</div>
                                  )}
                                </td>
                                <td className="px-4 py-4 whitespace-nowrap">
                                  <span
                                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                      payment.is_paid === true
                                        ? `bg-green-100 text-green-800`
                                        : `bg-red-100 text-red-800`
                                    }`}
                                  >
                                    {payment.is_paid === true ? '완료' : '예정'}
                                  </span>
                                </td>
                                <td className="px-4 py-4 whitespace-nowrap">
                                  <span
                                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                      payment.payment_type === 'normal'
                                        ? `bg-green-100 text-green-800`
                                        : `bg-red-100 text-red-800`
                                    }`}
                                  >
                                    {i18n.t(payment.payment_type)}
                                  </span>
                                </td>

                                <td className="px-6 py-4 whitespace-nowrap">
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                    {i18n.t(payment.payment_method)}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </Page>
  );
};

export default React.memo(PaymentIndexPage);

import React, { useRef } from 'react';
import { f7, Page, NavRight, Navbar, Link, List, ListItem, Block, Icon } from 'framework7-react';
import useAuth from '@hooks/useAuth';
import { getInfiniteObjects, API_URL } from '@api';
import { lineItemsCount } from '@atoms';
import { useRecoilValue } from 'recoil';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { InfiniteObjects, Like } from '@constants';
import HeartContainer from '@components/shared/HeartContainer';
import { currency, sleep, truncate, convertImagePath } from '@js/utils';

const LikeIndexPage = ({ f7router }) => {
  const { currentUser } = useAuth();
  const allowInfinite = useRef(true);
  const queryClient = useQueryClient();
  const ITEM_INF_KEY = ['likes/infinite'];
  const cartCount = useRecoilValue(lineItemsCount);

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery<
    InfiniteObjects<Like>,
    Error
  >(
    ITEM_INF_KEY,
    getInfiniteObjects({
      model_name: 'like',
      q: {
        user_id_eq: currentUser?.id || '',
        s: ['created_at desc'],
      },
    }),
    {
      getNextPageParam: (lastPage) => lastPage.next_cursor,
    },
  );

  const onClickGoToBuyItem = async () => {
    f7.preloader.show();
    f7router.back();
    await sleep(500);
    f7.tab.show('#view-items');
    f7.preloader.hide();
  };

  return (
    <Page noToolbar name="home">
      <Navbar backLink title="찜목록" sliding={false}>
        <NavRight>
          <Link href="/line_items" iconF7="cart" iconBadge={cartCount} badgeColor="red" />
        </NavRight>
      </Navbar>
      <List noHairlines className="mt-0 text-sm font-thin ">
        {data && data?.pages[0]?.total_count >= 1 ? (
          <>
            <ul>
              {data.pages.map((page, i) => (
                <React.Fragment key={i}>
                  {page.objects.map((like) => (
                    <React.Fragment key={like.id}>
                      <ListItem mediaItem className="w-full relative no-chevron">
                        <div className="flex flex-row">
                          <div className="w-4/5 inline-flex justify-left align-middle">
                            <a href={`/items/${like.target_id}`} className="item-link">
                              <div className="item-header">
                                {like.target?.category_name && <>[{truncate(14, like.target?.category_name)}]</>}
                              </div>
                              <div className="item-title">{`${like?.target?.title}`}</div>
                              <div className="item-subtitle">{`${currency(like?.target?.price)}원`}</div>
                            </a>
                          </div>
                          <a href={`/items/${like.target_id}`} className="justify-right w-1/5">
                            <Icon icon="icon-forward text-gray-400 px-6 py-3" />
                          </a>
                        </div>
                        <img
                          slot="media"
                          src={convertImagePath(like?.target?.image_path)}
                          className="w-20 rounded"
                          alt=""
                        />
                        <div className="flex flex-row">
                          <a
                            href={`/items/${like.target_id}`}
                            className="font-medium text-red-700 w-4/5 inline-flex justify-left align-middle mt-2"
                          >
                            남은시간: {like?.target?.remain_time || 0}
                          </a>
                          {like?.target && (
                            <HeartContainer
                              target={like?.target}
                              heartClassName="z-50 text-red-500 text-2xl px-5"
                              className="justify-right align-middle inline-flex w-1/5"
                            />
                          )}
                        </div>
                      </ListItem>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </ul>
          </>
        ) : (
          <Block className="pt-16 mt-16 text-center">
            <div className="text-center pt-16 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
              <i className="las la-heart" style={{ fontSize: '56px', color: '#bbbbbb' }} />
              <h1 className="text-center mt-2 mb-5 font-normal">찜한 상품이 없습니다.</h1>
              <div className="flex">
                <button
                  onClick={onClickGoToBuyItem}
                  type="button"
                  className="text-center mr-20 ml-20 flex-initial rounded-full items-center px-4 py-2 border border-blue-700 shadow-sm text-base font-medium text-blue-700 bg-white"
                >
                  상품 둘러보기
                </button>
              </div>
            </div>
          </Block>
        )}
      </List>
    </Page>
  );
};
export default React.memo(LikeIndexPage);

import React from 'react';
import { Navbar, Page } from 'framework7-react';
import RegistrationForm from './form';

const SignUpPage = ({ f7route, f7router }) => {
  const userId = f7route.query.user_id;

  return (
    <Page noToolbar>
      <Navbar title="회원가입" backLink sliding={false} />
      <p className="font-semibold text-2xl text-center mt-5">MODOODA</p>
      <RegistrationForm f7router={f7router} f7route={f7route} userId={userId} />
    </Page>
  );
};

export default SignUpPage;

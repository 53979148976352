import React from 'react';
import { f7, Row, Col, Checkbox, Button, Icon } from 'framework7-react';
import { currency, truncate } from '@js/utils';
import { API_URL, deleteLineItem, changeLineItemCheck } from '@api';
import { useMutation, useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { lineItemsCount } from '@atoms';
import { LineItem } from '@constants';
import { IS_PRODUCTION } from '@config';

interface LineItemProps {
  line_item: LineItem;
  isCart?: boolean;
}

const LineItemCard = ({ line_item, isCart = false }: LineItemProps) => {
  const queryClient = useQueryClient();
  const setLineItemsCount = useSetRecoilState(lineItemsCount);

  const optionFormatter = (value: string | number) => ({ value, label: value });

  const deleteMutation = useMutation(deleteLineItem(), {
    onSuccess: async (data) => {
      f7.preloader.show();
      await queryClient.invalidateQueries('line_items', { exact: true });
      await setLineItemsCount(() => data.line_items_count);
      f7.preloader.hide();
    },
  });

  const changeCheckMutation = useMutation(changeLineItemCheck(line_item.id), {
    onSuccess: async () => {
      f7.preloader.show();
      await queryClient.invalidateQueries('line_items', { exact: true });
      f7.preloader.hide();
    },
  });

  const onClickRemoveLineItem = (lineItemId) => deleteMutation.mutate(lineItemId);

  const onChangeCheck = async () => {
    changeCheckMutation.mutate();
  };

  return (
    <Row className={`${isCart ? 'border-solid border border-gray-100' : 'rounded-lg shadow-sm'} p-2 bg-white`}>
      {isCart && (
        <Col width="10">
          <Checkbox onChange={onChangeCheck} name="checkbox-2" className="mt-2" checked={line_item.is_checked} />
        </Col>
      )}
      <Col width="25" className="p-1">
        <img src={IS_PRODUCTION ? line_item.image_path : API_URL + line_item.image_path} className="w-screen rounded" />
      </Col>
      <Col width={isCart ? '65' : '75'} className="p-1">
        <div className="w-full flex items-left justify-between">
          <div className="flex-1 truncate list m-0">
            <div className="item-header py-1">
              {line_item?.category_name && <>[{truncate(14, line_item?.category_name)}]</>}
            </div>
            <div className="item-title text-left">{line_item.item_name}</div>
            <div className="item-subtitle">{`${currency(line_item?.price)}원`}</div>
          </div>
          <a href={`/items/${line_item?.item_id}`} className="justify-right w-1/5">
            <Icon icon="icon-forward text-gray-400 px-6 py-3"></Icon>
          </a>
        </div>
        <p className="text-gray-400 bold text-xs mt-2">
          {line_item?.item?.deliver_method === '일반배송' ? '일반배송(3,000원)' : '화물배송(착불)'}
        </p>

        {isCart && (
          <>
            <Row className="mt-4">
              <Col className="flex flex-row-reverse">
                <Button
                  onClick={() => onClickRemoveLineItem(line_item.id)}
                  className="text-center w-1/3 rounded items-center px-2 mr-4 py-4 border border-blue-700 shadow-sm text-base font-medium text-blue-700 bg-white"
                >
                  삭제
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default React.memo(LineItemCard);

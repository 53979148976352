import React, { useState } from 'react';
import QrReader from 'react-qr-reader';
import { Button, Link, Navbar, NavRight, Page, Popup, f7 } from 'framework7-react';
import { useFormikContext } from 'formik';

const QrScanner = () => {
  const [popupOpened, setPopupOpened] = useState(false);

  const { setFieldValue } = useFormikContext();

  const handleScan = (data) => {
    if (data) {
      setFieldValue('item_code', data);
      f7.popup.close('.qr-popup');
    }
  };

  const handleError = (errorMessage) => {
    // f7.dialog.alert(errorMessage);
  };

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-4 lg:px-6 mt-2">
        <Button
          className="items-center text-center px-6 py-6 mb-8 mt-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          popupOpen=".qr-popup"
        >
          QR코드 스캔
        </Button>
      </div>
      <Popup
        className="qr-popup"
        opened={popupOpened}
        onPopupClosed={() => {
          setPopupOpened(false);
        }}
      >
        <Page>
          <Navbar title="QR코드 스캔">
            <NavRight>
              <Link popupClose>닫기</Link>
            </NavRight>
          </Navbar>
          <QrReader delay={300} onScan={handleScan} onError={handleError} resolution={800} />
        </Page>
      </Popup>
    </>
  );
};

export default React.memo(QrScanner);

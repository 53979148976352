import React from 'react';
import { Navbar, Page } from 'framework7-react';

const InfoSearchPage = () => (
  <Page noToolbar>
    <Navbar title="회원정보 찾기" backLink />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">로그인에 도움이 필요하신가요? </span>
        </h2>
        <div className="mt-8 justify-center">
          <div className="rounded-md">
            <a
              href="/email_search"
              className="button button-fill button-large inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white w-80"
            >
              아이디(이메일) 찾기
            </a>
          </div>
          <div className="mt-2 rounded-md">
            <a
              href="/passwords/new"
              className="button button-fill button-large inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white w-80"
            >
              비밀번호 찾기
            </a>
          </div>
        </div>
      </div>
    </div>
  </Page>
);

export default React.memo(InfoSearchPage);

import React, { useState, useRef, useEffect } from 'react';
import { Navbar, Page, Link, Tabs, Tab, Button, Popup, NavRight, Icon, f7 } from 'framework7-react';
import { getObjects, getItem, createLike, deleteLike, deleteContact } from '@api';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { userInfos } from '@atoms';
import { currency, dateFormat, toast, objectsSkeletonPlaceholder } from '@js/utils';
import { useRecoilValue } from 'recoil';
import { Contact, Objects, Item } from '@constants';
import HeartContainer from '@components/shared/HeartContainer';
import BuySheet from '@components/items/BuySheet';
import DeliverInfo from '@components/shared/DeliverInfo';
import useAuth from '@hooks/useAuth';
import { map } from 'lodash';
import ImagesSlide from '../../components/ImagesSlide';
import ReactQueryState from '../../components/shared/ReactQueryState';
import i18n from '../../assets/lang/i18n';

const ItemShowPage = ({ f7route, f7router }) => {
  const user = useRecoilValue(userInfos);
  const [popupOpened, setPopupOpened] = useState(false);
  const [isCurrentUserItem, setIsCurrentUserItem] = useState(false);
  // const [contacts, setContacts] = useState([]);
  let id = f7route.params.id;
  const { currentUser, isAuthenticated } = useAuth();

  const ITEM_KEY = ['item', parseInt(id)];

  // const { data: item, status, error, isSuccess } = useQuery(ITEM_KEY, getItem({ id: id }), {
  //   enabled: !!id,
  // });

  const { data: item, status, error, isSuccess } = useQuery<Item, Error>(ITEM_KEY, getItem(id), {
    enabled: !!id,
  });

  const { data: contacts, isLoading, isFetching } = useQuery<Objects<Contact>, Error>(
    'contacts',
    getObjects({
      q: {
        s: ['updated_at desc'],
        item_id_eq: item?.id || '',
        contact_type_eq: 'item_contact',
      },
      model_name: 'contact',
    }),
    {
      placeholderData: objectsSkeletonPlaceholder(4),
      enabled: !!item,
    },
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    if (item) {
      const mapOptions = {
        center: new naver.maps.LatLng(
          item?.seller?.lat ? item?.seller?.lat : 37.3595704,
          item?.seller?.lng ? item?.seller?.lng : 127.105399,
        ),
        zoom: 18,
      };
      const map = new naver.maps.Map('map', mapOptions);
      const marker = new naver.maps.Marker({
        position: new naver.maps.LatLng(
          item?.seller?.lat ? item?.seller?.lat : 37.3595704,
          item?.seller?.lng ? item?.seller?.lng : 127.105399,
        ),
        map: map,
      });
      if (isAuthenticated && item?.seller?.user_id === currentUser?.id) {
        setIsCurrentUserItem(true);
      }
    }
  }, [item]);

  const [itemLikeProps, setItemLikeProps] = useState({
    like_id: item?.like_id,
    is_liked: !!item?.like_id,
  });

  const { mutate: createMutate } = useMutation(createLike(), {
    onSuccess: (resp) => {
      const likeToast = toast.get();
      likeToast.setToastIcon('heart_fill').setToastText('찜했습니다').openIconToast();
      setItemLikeProps({
        like_id: resp.id,
        is_liked: true,
      });
    },
  });
  const { mutate: deleteMutate } = useMutation(deleteLike(), {
    onSuccess: () => {
      const likeToast = toast.get();
      likeToast.setToastIcon('heart').setToastText('취소했습니다').openIconToast();
      setItemLikeProps(() => ({ like_id: null, is_liked: false }));
    },
  });

  const onClickLike = () => {
    itemLikeProps.is_liked ? deleteMutate(itemLikeProps.like_id) : createMutate(id);
  };

  const deleteMutation = useMutation(deleteContact(), {
    onSuccess: async (data) => {
      f7.dialog.close();
      await queryClient.invalidateQueries('contacts');
      toast.get().setToastText('문의가 성공적으로 삭제되었습니다.').openToast();
    },
  });

  const onClickDestroy = (contactId) => {
    f7.dialog.confirm('정말 삭제하시겠습니까?', 'MODOODA', () => {
      f7.dialog.preloader('잠시만 기다려주세요...');
      deleteMutation.mutate(contactId);
    });
  };

  (window as any).goPageBack = {
    promises: [] as Promise<string>[],
    callback() {
      f7router.back();
    },
  };

  return (
    <Page noToolbar>
      <Navbar backLink noShadow transparent sliding={false} className="bg-trans">
        <NavRight>
          {item && (
            <HeartContainer target={item} className="block w-12 text-center" heartClassName="text-red-500 text-2xl" />
          )}
        </NavRight>
      </Navbar>

      <ReactQueryState data={item} status={status} error={error} />
      <ImagesSlide imagableType="Item" imagableId={id} />
      {item && (
        <div>
          <div className="bg-white shadow overflow-hidden rounded-md">
            <ul className="divide-y divide-gray-200">
              <div className="px-6 py-4 row">
                <div className="col-85 float-left">
                  <h2 className="text-lg font-bold">{item?.title}</h2>
                  {item?.sell_type === 'temporary' && (
                    <h3 className="price-text text-red-700 mt-2">남은시간: {item?.remain_time || '판매 종료'}</h3>
                  )}
                </div>
                <div className="col-15 text-right mt-1">
                  {item?.seller && (
                    <>
                      <a href={`/sellers/${item?.seller?.id}/items`} className="font-medium font-semibold">
                        <i className="las la-store-alt" style={{ fontSize: '36px', color: 'gray' }} />
                      </a>
                    </>
                  )}
                  {/* <h3 className="text-sm font-semibold">{item?.address2}</h3> */}
                </div>
              </div>
            </ul>
          </div>
          <div className="p-6">
            <h2 className="text-sm leading-6 font-medium text-gray-900">가격</h2>
            <p className="mt-4">
              <span className="text-4xl font-extrabold text-gray-900">{currency(item?.price)}</span>
              <span className="text-base font-medium text-gray-500"> 원</span>
            </p>
            {item?.is_valid && item?.sell_status === 'on_sale' && !isCurrentUserItem ? (
              <Button
                sheetOpen=".buy-sheet"
                large
                fill
                className="mt-4 mb-0 bg-blue-500 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-700"
              >
                구매하기
              </Button>
            ) : (
              <Button
                large
                fill
                className="mt-4 mb-0 border border-gray-300 shadow-sm text-sm font-semibold rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50"
              >
                {isCurrentUserItem ? `구매 불가능한 상품입니다` : `판매종료`}
              </Button>
            )}
          </div>
          {item && <BuySheet f7router={f7router} selectedItem={item} />}
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              <Link
                tabLink="#detail"
                className="border-transparent text-gray-500 hover:text-gray-800 hover:border-blue-600 w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
              >
                상세설명
              </Link>

              <Link
                tabLink="#delivery"
                className="border-transparent text-gray-500 hover:text-gray-800 hover:border-blue-600 w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
              >
                배송
              </Link>

              <Link
                tabLink="#contact"
                className="border-transparent text-gray-500 hover:text-gray-800 hover:border-blue-600 w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
              >
                문의
              </Link>
            </nav>
          </div>
          <Tabs>
            <Tab id="detail" tabActive>
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">{item?.title}</h3>
                  <p className="mt-1 max-w-2xl text-sm text-black-500">카테고리: {item?.category_string}</p>
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-3 sm:py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">재질</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{item.texture}</dd>
                    </div>
                    <div className="py-3 sm:py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">제조사</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{item.manufacturer}</dd>
                    </div>
                    <div className="py-3 sm:py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">상태</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{item.status_ko}</dd>
                    </div>
                    <div className="py-3 sm:py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">크기(가로 x 세로 x 높이)</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {item?.item_width}cm x {item?.item_height}cm x {item?.item_depth}cm
                      </dd>
                    </div>
                    <div className="py-3 sm:py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">상세설명</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <p className="display-linebreak">{item.description}</p>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </Tab>
            <Tab id="delivery">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">배송방법</h3>
                  <p className="mt-1 max-w-2xl text-medium text-gray-700">{item?.deliver_method}</p>
                </div>
                {item?.seller?.lng ? (
                  <img
                    src={`https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors?w=600&h=300&center=${item?.seller?.lng},${item?.seller?.lat}&level=16&markers=type:d|size:mid|pos:${item?.seller?.lng}%20${item?.seller?.lat}&X-NCP-APIGW-API-KEY-ID=9nr6uysay2`}
                    alt=""
                  />
                ) : (
                  <img
                    src="https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors?w=600&h=300&center=127.1054221,37.3591614&level=16&markers=type:d|size:mid|pos:127.1054221%2037.3591614&X-NCP-APIGW-API-KEY-ID=9nr6uysay2"
                    alt=""
                  />
                )}
                <div className="px-4 py-5">
                  <Button className="mt-4 mb-0 block w-full" fill popupOpen=".demo-popup">
                    지도 열기
                  </Button>
                </div>
                <Popup className="demo-popup" opened={popupOpened} onPopupClosed={() => setPopupOpened(false)}>
                  <Page>
                    <Navbar title={item.title}>
                      <NavRight>
                        <Link popupClose>닫기</Link>
                      </NavRight>
                    </Navbar>
                    <div id="map" style={{ width: '100vw', height: '100vh' }} />
                  </Page>
                </Popup>

                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">픽업 장소</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {item?.seller?.pickup_address}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">오픈하우스</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {dateFormat(item?.seller?.first_open_house_at, 'day')} {item?.seller?.open_at} ~{' '}
                        {item?.seller?.closed_at}
                      </dd>
                    </div>
                    <DeliverInfo />
                  </dl>
                </div>
              </div>
            </Tab>
            <Tab id="contact">
              <div className="bg-white overflow-hidden sm:rounded-lg mb-8">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">상품문의</h3>
                </div>
                <div className="p-6">
                  <h2 className="text-sm leading-6 font-medium text-gray-900 text-center">
                    상품, 배송에 대한 궁금한 사항을 작성해 주세요.
                    <br />
                    빠른 시간 내 답변 드리겠습니다.
                  </h2>
                </div>
                {!isCurrentUserItem && currentUser && (
                  <a
                    href={`/contacts/new?item_id=${item.id}`}
                    className="card mt-4 mb-4 block bg-blue-500 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-purple-700"
                  >
                    등록하기
                  </a>
                )}
              </div>
              <div>
                <div className="sm:block mb-10">
                  <div id="list">
                    <div className="py-6 sm:px-6 mb-8">
                      {isSuccess && contacts && (
                        <ul className="space-y-4 mb-10">
                          {map(contacts?.objects, (contact: Contact, i) => (
                            <li key={i} className="px-4 pb-4 border-solid border-b-2 border-gray-100">
                              <div className="flex space-x-3">
                                <div>
                                  <div className="text-sm">
                                    <a href="#" className="font-medium text-gray-900">
                                      {`${i18n.t(contact?.contact_type)}`} /{' '}
                                      {contact?.answer ? <span className="text-indigo-500">답변완료</span> : '미답변'}
                                    </a>
                                  </div>
                                  <div className="mt-1 mb-2 text-xs space-x-2">
                                    <span className="text-gray-400 font-normal">
                                      {contact?.user_name || '비공개'} | {dateFormat(contact.created_at, 'day')}
                                    </span>
                                    <span className="text-gray-600 text-xs font-medium">
                                      {currentUser && currentUser.id === contact.user_id && (
                                        <>
                                          <span className="text-gray-500 font-medium">&middot;</span>{' '}
                                          <a href={`/contacts/${contact.id}/edit`}>수정</a>{' '}
                                          <span className="text-gray-500 font-medium">&middot;</span>{' '}
                                          <a onClick={onClickDestroy.bind(this, contact.id)}>삭제</a>
                                        </>
                                      )}
                                    </span>
                                  </div>

                                  <div className="mt-1 text-sm text-gray-800">
                                    <span className="text-indigo-500 text-sm text-bold">Q. </span>
                                    <span className="text-gray-400 text-sm font-normal">{contact?.title}</span>
                                  </div>
                                  <div className="ml-4 mt-1 text-sm text-gray-800 display-linebreak">
                                    {contact?.content}
                                  </div>
                                  {contact?.answer && (
                                    <>
                                      <div className="mt-2 text-sm text-gray-800 ml-2">
                                        <span className="text-indigo-500 text-sm text-bold">
                                          <Icon f7="arrow_turn_down_right" size="20px" className="mr-1" /> A.{' '}
                                        </span>
                                        <span className="text-gray-800 text-sm font-bold">관리자</span>
                                        <span className="text-gray-400 text-xs font-normal">
                                          {' '}
                                          {dateFormat(contact.updated_at, 'day')}{' '}
                                        </span>
                                      </div>
                                      <div className="ml-8 mt-1 text-sm text-gray-800 display-linebreak">
                                        {contact?.answer}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      )}
    </Page>
  );
};

export default React.memo(ItemShowPage);

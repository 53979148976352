import React from 'react';
import { Navbar, Page } from 'framework7-react';
import { getObject } from '@api';
import { useQuery } from 'react-query';
import ReactQueryState from '@components/shared/ReactQueryState';
import { dateFormat } from '@js/utils';
import { Term, PageRouteProps } from '@constants';

const TermShowPage = ({ f7route, f7router }: PageRouteProps) => {
  const termId = f7route.params.id;

  const { status, data: term, error } = useQuery<Term, Error>(
    ['term', parseInt(termId, 10)],
    getObject(termId, {
      model_name: 'term',
    }),
    {
      enabled: !!termId,
    },
  );

  return (
    <Page noToolbar>
      <Navbar backLink title={term && term?.title} />
      <ReactQueryState data={term} status={status} error={error} />
      <div className="py-8 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        {term && (
          <div className="w-full">
            <div className="relative z-10 mb-5 md:mb-2 md:px-6">
              <div className="text-base max-w-prose lg:max-w-none">
                <p className="font-semibold text-sm text-gray-900">{term.title}</p>
                <span className="text-xs">{dateFormat(term.created_at, 'day')}</span>
              </div>
            </div>
            <div className="relative mt-4">
              <div className="relative md:bg-white md:p-6">
                <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                  <div className="prose prose-blue prose-lg text-gray-500 lg:max-w-none text-base">
                    <p className="text-sm display-linebreak">{term.body}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Page>
  );
};

export default TermShowPage;

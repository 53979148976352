import React from 'react';
import useAuth from '@hooks/useAuth';
import { Navbar, Page, List, ListInput, f7 } from 'framework7-react';
import { Formik, Form } from 'formik';
import { convertObjectToFormData, sleep } from '@utils';
import { updateUserAPI, logoutAPI } from '@api';

const UserWithdrawPage = ({ f7router }) => {
  const { currentUser, authenticateUser } = useAuth();

  return (
    <Page noToolbar>
      <Navbar title="회원 탈퇴" backLink />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto text-left py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h4 className="font-bold tracking-tight text-gray-600">탈퇴 사유를 알려주세요</h4>
          <Formik
            initialValues={{
              id: currentUser.id,
              exit_reason: '',
              update_type: 'destroy',
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await sleep(400);
              setSubmitting(false);
              f7.dialog.preloader('잠시만 기다려주세요...');
              try {
                const fd = convertObjectToFormData({ modelName: 'user', data: values });
                const response = await updateUserAPI(fd);
                if (response.status === 200) {
                  authenticateUser(response.data);
                  f7.dialog.close();
                  f7router.navigate('/users/withdraw_complete');
                  f7.dialog.alert('탈퇴 처리가 완료되었습니다');
                } else {
                  f7.dialog.close();
                  f7.dialog.alert('탈퇴 처리 중 오류가 발생하였습니다. 문제가 계속될 경우 관리자에게 문의해주세요.');
                }
              } catch (error) {
                f7.dialog.close();
                f7.dialog.alert('탈퇴 처리 중 오류가 발생하였습니다. 문제가 계속될 경우 관리자에게 문의해주세요.');
              }
            }}
          >
            {({ handleChange, handleBlur, values, errors, touched, isSubmitting, isValid }) => (
              <Form className="space-y-8">
                <div className="space-y-8 sm:space-y-5">
                  <List noHairlinesMd>
                    <ListInput
                      type="textarea"
                      resizable
                      name="exit_reason"
                      placeholder="탈퇴 사유를 입력해주세요."
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.exit_reason}
                    />
                  </List>
                  <div className="mt-8 flex justify-center">
                    <div className="inline-flex rounded-md shadow">
                      <button
                        type="submit"
                        className="inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                      >
                        탈퇴하기
                      </button>
                    </div>
                    <div className="ml-3 inline-flex">
                      <a
                        onClick={() => {
                          f7router.back();
                        }}
                        className="inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
                      >
                        취소
                      </a>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <h4 className="font-bold tracking-tight text-gray-600 mt-5">
            <span className="block">탈퇴 시 3개월동안 재가입이 불가능합니다.</span>
            <br />
            <span className="block">개인정보 처리방침에 의해 관계법령처리에 따라 관리 합니다.</span>
          </h4>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(UserWithdrawPage);

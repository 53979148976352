import { Button, f7ready, Page, Navbar, Swiper, SwiperSlide, Toolbar } from 'framework7-react';
import React, { useState } from 'react';
import logo from '@images/modooda_logo.png';

const IntroPage = ({ f7router }) => {
  const [slides, setSlides] = useState([]);
  const images: string[] = [
    'couple',
    'segment',
    'chilling',
    'choose',
    'chatting',
    'confirmed',
    'agreement',
    'grades',
    'brainstorming',
    'hiring',
    'love',
    'messages1',
    'development',
    'team',
    'together',
    'space',
    'mobile',
    'website',
    'easter',
    'romantic',
    'tasting',
    'drone',
    'coding',
    'mindfulness',
    'artificial',
    'celebration',
    'virtual',
    'doggy',
    'static',
    'healthy',
    'data',
    'sleep',
    'force',
    'makeup',
    'bicycle',
    'podcast',
    'fishing',
    'credit',
    'workout',
    'pilates',
    'group',
    'mouth',
    'school',
  ];

  (window as any).goPageBack = {
    promises: [] as Promise<string>[],
    callback() {
      if (f7router.history.length === 1) {
        (window as any).Android.pressBackOut();
      }
      f7router.back();
    },
  };

  return (
    <Page noToolbar>
      <Navbar title="MODOODA" backLink />
      {/* <Toolbar bottom className="p-0" inner={false}>
        <div className="w-full flex">
          <Button className="w-full rounded-none" large href="/users/sign_in">
            로그인
          </Button>
          <Button className="w-full rounded-none" large href="/users/sign_up" fill>
            회원가입
          </Button>
        </div>
      </Toolbar> */}
      <div className="container pt-10">
        <div className="sm:flex">
          <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4 m-5">
            <a href="https://www.sseuimsae.com" target="_blank" className="external" rel="noreferrer">
              <img src={logo} alt="" style={{ width: `50%`, margin: `auto` }} />
            </a>
          </div>
          <div className="text-center mt-5">
            <h2 className="tracking-tight text-gray-500">
              <span className="block text-3xl font-normal">상인들의 1번 어플</span>
              <span className="block text-blue-400 font-extrabold text-4xl">모두다</span>
            </h2>
            <p className="mt-4 text-medium font-light leading-6 text-200">
              내 사업장에 필요한 물건 <br /> 우리 동네 주변에서 저렴하고,쉽고,편리하게 <br /> 그리고 안전하게{' '}
              <strong>사고, 팔 수</strong> 있도록 <strong>'모두다'</strong>에서 도와드립니다.
            </p>
          </div>
          <div className="mx-5 my-20">
            <Button className="w-full" fill large href="/users/sign_in">
              로그인
            </Button>
            <div className="mt-4 text-center">
              <p className="text-base leading-6 font-normal text-gray-900">
                {/* <a href="/info_search">아이디, 비밀번호 찾기</a> / <a href="/users/sign_up">회원가입</a> */}
                <a href="/info_search">아이디, 비밀번호 찾기</a>&nbsp; | &nbsp;<a href="/authentication">회원가입</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
export default IntroPage;

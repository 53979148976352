import React, { useState, useEffect } from 'react';
import ReactQueryState from '@components/shared/ReactQueryState';
import DaumAddressSearch from '@components/shared/DaumAddressSearch';
import * as Yup from 'yup';
import { Navbar, Page, List, ListInput, Toolbar, Button, f7, ListItem } from 'framework7-react';
import { useQuery, useQueryClient } from 'react-query';
import { Address, LineItem, LineItems, PageRouteProps } from '@constants';
import { getLineItemsByOrder } from '@api';
import { utils } from 'framework7';
import { Form, Formik, FormikHelpers } from 'formik';
import { sleep } from '@js/utils';
import { map } from 'lodash';
import { configs, IS_PRODUCTION } from '@config';
import LineItemCard from '@pages/line_items/LineItemCard';
import TotalSheet from '@pages/orders/TotalSheet';
import { loadTossPayments } from '@tosspayments/sdk';

interface PaymentFormValues extends Address {
  receiver_name: string;
  receiver_phone: string;
  payment_method: '카드' | '계좌이체' | '휴대폰';
  deliver_method: 'pickup' | 'not_pickup' | '';
}

const PaymentSchema = Yup.object().shape({
  receiver_name: Yup.string().required('필수 입력사항 입니다'),
  zipcode: Yup.string().min(4, '길이가 너무 짧습니다').max(8, '길이가 너무 깁니다').required('필수 입력사항 입니다'),
  address1: Yup.string().required('필수 입력사항 입니다'),
  receiver_phone: Yup.string()
    .min(9, '길이가 너무 짧습니다')
    .max(15, '길이가 너무 깁니다')
    .required('휴대폰 번호를 인증해주세요'),
  deliver_method: Yup.string().required('배송정보를 선택해 주세요'),
});

const PaymentShowPage = ({ f7route }: PageRouteProps) => {
  const orderId = f7route.params.id;
  const queryClient = useQueryClient();
  const clientKey = configs.TOSS_CLIENT_KEY;
  const tosspayments = loadTossPayments(clientKey);
  const [deliverFee, setDeliverFee] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [isPickUp, setIsPickup] = useState(true);

  const { data, status, error } = useQuery<LineItems, Error>(
    ['line_items', parseInt(orderId, 10)],
    getLineItemsByOrder(orderId),
    {
      enabled: !!orderId,
    },
  );

  const getOrderName = () => {
    const cartListLength = data.line_items.length;
    const initialName = data.line_items[0]?.item_name;
    const name = cartListLength === 1 ? initialName : `${initialName}외 ${cartListLength - 1}건`;
    return name;
  };

  const onFormSubmit = async (values: any, setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    try {
      (await tosspayments).requestPayment(values.payment_method ? values.payment_method : '카드', {
        amount: orderTotal,
        orderId: data.order.order_number,
        customerName: data.order.receiver_name || '',
        orderName: getOrderName(),
        successUrl: `${
          IS_PRODUCTION ? `https://app.modooda-all.com` : configs.SERVICE_URL
        }?payment_result=success&${utils.serializeObject(values)}`,
        failUrl: `${
          IS_PRODUCTION ? `https://app.modooda-all.com` : configs.SERVICE_URL
        }?payment_result=fail&${utils.serializeObject(values)}`,
      });
    } catch {
      f7.preloader.hide();
      f7.dialog.alert('정보를 확인 해주세요.');
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (data?.order) {
      setOrderTotal(data?.order?.total);
      setDeliverFee(data?.order?.deliver_fee);
    }
  }, [data]);

  return (
    <Page noToolbar>
      <Navbar title="주문 및 결제" backLink />

      <ReactQueryState data={data} status={status} error={error} />

      {data && (
        <Formik<PaymentFormValues>
          initialValues={{
            receiver_name: data?.order?.receiver_name,
            receiver_phone: data?.order?.receiver_phone,
            zipcode: data?.order?.zipcode,
            address1: data?.order?.address1,
            address2: data?.order?.address2,
            payment_method: '카드',
            deliver_method: '',
          }}
          validationSchema={PaymentSchema}
          onSubmit={async (values, { setSubmitting }: FormikHelpers<PaymentFormValues>) => {
            f7.preloader.show();
            await sleep(400);
            if (values.deliver_method !== '') {
              onFormSubmit(values, setSubmitting);
            } else {
              f7.dialog.alert('배송 정보를 선택해주세요');
            }
            f7.preloader.hide();
          }}
          validateOnMount
        >
          {({ handleChange, handleBlur, values, errors, touched, isSubmitting, isValid, setFieldValue }) => (
            <>
              <Form>
                <Toolbar bottom className="item-toolbar fixed">
                  <Button
                    type="submit"
                    fill
                    className="w-full h-11 text-base mr-2 ml-2"
                    disabled={isSubmitting || !isValid}
                  >
                    결제하기
                  </Button>
                </Toolbar>

                <List noHairlinesMd style={{ '--f7-list-margin-vertical': '0px' }}>
                  <div className="p-3 font-semibold mt-4 ">기본 정보</div>
                  <ListInput
                    name="receiver_name"
                    label="수령인 성함"
                    type="text"
                    placeholder="수령인 성함"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.receiver_name}
                    errorMessageForce
                    errorMessage={touched.receiver_name && errors.receiver_name}
                  />
                  <ListInput
                    name="receiver_phone"
                    label="수령인 전화번호"
                    type="text"
                    placeholder="수령인 전화번호"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.receiver_phone}
                    errorMessageForce
                    errorMessage={touched.receiver_phone && errors.receiver_phone}
                  />
                  <hr className="border-4" />
                  <DaumAddressSearch
                    zipcode={values.zipcode}
                    address1={values.address1}
                    address2={values.address2}
                    className="text-base mt-8"
                  />

                  <hr className="border-4" />

                  <div className="p-3 font-semibold">주문 상품</div>

                  {map(data.line_items, (lineItem: LineItem) => (
                    <LineItemCard key={lineItem.id} line_item={lineItem} />
                  ))}

                  <hr className="border-4" />

                  <div className="p-3 font-semibold">배송 정보</div>
                  <fieldset>
                    <div className="bg-white rounded-md -space-y-px">
                      <label className="relative border p-4 flex cursor-pointer focus:outline-none">
                        <input
                          type="radio"
                          name="deliver-method-radio"
                          value="pickup"
                          className="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500"
                          aria-labelledby="deliver-method-radio-0-label"
                          aria-describedby="deliver-method-radio-0-description"
                          onChange={(e) => {
                            values.deliver_method = 'pickup';
                            setIsPickup(true);
                            setOrderTotal(data?.order?.total);
                            handleChange(e);
                          }}
                        />
                        <div className="ml-3 flex flex-col">
                          <span id="deliver-method-radio-0-label" className="block text-sm font-medium">
                            현장픽업
                          </span>
                          <span id="deliver-method-radio-0-description" className="block text-sm">
                            현장 픽업 (배송비: 무료 / 판매처 현장에서 직접 회수 시 선택해 주세요.)
                          </span>
                        </div>
                      </label>

                      <label className="relative border p-4 flex cursor-pointer focus:outline-none">
                        <input
                          type="radio"
                          name="deliver-method-radio"
                          value="not_pickup"
                          className="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500"
                          aria-labelledby="deliver-method-radio-1-label"
                          aria-describedby="deliver-method-radio-1-description"
                          onChange={(e) => {
                            values.deliver_method = 'not_pickup';
                            setIsPickup(false);
                            setOrderTotal(data?.order?.include_deliver_total);
                            handleChange(e);
                          }}
                        />
                        <div className="ml-3 flex flex-col">
                          <span id="deliver-method-radio-1-label" className="block text-sm font-medium">
                            배송
                          </span>
                          <span id="deliver-method-radio-1-description" className="block text-sm">
                            일반 배송 (배송비: 3,000원) <br />
                            화물 배송 (배송비: 착불 / 화물 배송 기사 및 화물배송 업체에 직접 지불 / 화물배송문의:
                            031.309.2208)
                          </span>
                        </div>
                      </label>
                    </div>
                  </fieldset>

                  <hr className="border-4" />

                  <div className="px-3 font-semibold mt-4">결제 정보</div>

                  <TotalSheet
                    order={data.order}
                    className="text-base mt-5 mb-4"
                    deliverFee={deliverFee}
                    orderTotal={orderTotal}
                    isPickup={isPickUp}
                  />

                  <hr className="border-4" />
                  <div className="px-3 font-semibold mt-4 mb-4">결제 방법</div>
                  <ul className="row">
                    <ListItem
                      radio
                      radioIcon="start"
                      value="카드"
                      name="payment-type-radio"
                      onChange={(e) => {
                        values.payment_method = '카드';
                      }}
                      title="카드"
                      className="w-full"
                      defaultChecked
                    />
                  </ul>
                  <ul className="row">
                    <ListItem
                      radio
                      radioIcon="start"
                      value="계좌이체"
                      name="payment-type-radio"
                      onChange={(e) => {
                        values.payment_method = '계좌이체';
                      }}
                      title="계좌"
                      className="w-full"
                      defaultChecked={false}
                    />
                  </ul>
                  <ul className="row mb-10">
                    <ListItem
                      radio
                      radioIcon="start"
                      value="휴대폰"
                      name="payment-type-radio"
                      onChange={(e) => {
                        values.payment_method = '휴대폰';
                      }}
                      title="휴대폰"
                      className="w-full"
                      defaultChecked={false}
                    />
                  </ul>
                </List>
              </Form>
            </>
          )}
        </Formik>
      )}
    </Page>
  );
};

export default React.memo(PaymentShowPage);

import React, { useEffect } from 'react';
import { Page, Navbar, List, ListInput, Icon, f7 } from 'framework7-react';
import { getItem, getObjects } from '@api';
import { useQuery } from 'react-query';
import { Seller, Objects, Category } from '@constants';
import { dateFormat } from '@js/utils';
import { objectsSkeletonPlaceholder } from '@js/utils';
import { useRecoilValue } from 'recoil';
import { userInfos } from '@atoms';
import ItemForm from './Form';

const editItemPage = ({ f7route, f7router }) => {
    const itemId = f7route.params.id;
    const ITEM_KEY = ['edit_item', parseInt(itemId)];
    const { data:item, status, error, isSuccess } = useQuery(ITEM_KEY, getItem(itemId), {
        enabled: !!itemId,
    });
    const user = useRecoilValue(userInfos);

    return(
        <Page noToolbar>
            <Navbar title="상품 정보 수정" backLink sliding={false} onBackClick={() => {f7.toast.create({ text: "상품 수정이 취소되었습니다", position: "center", closeTimeout: 2000 }).open();}}/>
            <ItemForm f7router={f7router} user={user} seller={item && item?.seller?.id} item={item} />
        </Page>        
    )
}

export default editItemPage;
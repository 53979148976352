import React from 'react';
import { Navbar, Page } from 'framework7-react';
import { getObject } from '@api';
import { useQuery } from 'react-query';
import ReactQueryState from '@components/shared/ReactQueryState';
import { dateFormat } from '@js/utils';
import { Contact, PageRouteProps } from '@constants';

const ContactShowPage = ({ f7route, f7router }: PageRouteProps) => {
  const contactId = f7route.params.id;

  const { status, data: contact, error } = useQuery<Contact, Error>(
    ['contact', parseInt(contactId, 10)],
    getObject(contactId, {
      model_name: 'contact',
    }),
    {
      enabled: !!contactId,
    },
  );

  return (
    <Page noToolbar>
      <Navbar backLink title="문의 상세" />
      <ReactQueryState data={contact} status={status} error={error} />
      <div className="py-8 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        {contact && (
          <div className="w-full">
            <div className="relative z-10 mb-5 md:mb-2 md:px-6">
              <div className="text-base max-w-prose lg:max-w-none">
                <p className="font-semibold text-sm text-gray-900">
                  Q. {contact.title}
                  {contact?.answer ? (
                    <span className="mx-2 px-2 inline-flex text-xs leading-5 font-normal rounded-full bg-green-100 text-green-800">
                      답변완료
                    </span>
                  ) : (
                    <span className="mx-2 px-2 inline-flex text-xs leading-5 font-normal rounded-full bg-gray-100 text-gray-500">
                      답변예정
                    </span>
                  )}
                </p>
                <span className="text-xs">{dateFormat(contact.created_at, 'day')}</span>
              </div>
            </div>
            <div className="relative mt-4">
              <div className="relative md:bg-white md:p-6">
                <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                  <div className="prose prose-blue prose-lg text-gray-500 lg:max-w-none text-base">
                    <p className="text-sm display-linebreak">{contact.content}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <hr />
      <div className="bg-white overflow-hidden sm:rounded-lg">
        {contact && (
          <div className="px-4 py-5 sm:px-6">
            <p className="font-semibold text-sm text-gray-900">
              A. 관리자 답변
              {contact?.admin_user && (
                <span className="mx-2 px-2 inline-flex text-xs leading-5 font-normal rounded-full bg-blue-100 text-gray-800">
                  {contact?.admin_user?.email}
                </span>
              )}
            </p>
            {contact.answer ? (
              <div className="mb-4 mt-1 text-xs w-full">{dateFormat(contact.updated_at, 'time')}</div>
            ) : (
              ''
            )}
            <div className="relative w-full">
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {contact.answer ? contact.answer : '답변이 없습니다.'}
              </p>
            </div>
          </div>
        )}
      </div>
    </Page>
  );
};

export default ContactShowPage;

import React, { useState } from 'react';
import { f7, List, ListInput } from 'framework7-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DaumAddressSearch from '@components/shared/DaumAddressSearch';
import { convertObjectToFormData, sleep } from '@utils';
import AgreeCheckboxes from '@components/shared/AgreeCheckboxes';
import { signupAPI, updateUserAPI } from '@api';
import { toast } from '@js/utils';
import useAuth from '@hooks/useAuth';
import { Address } from '@constants';

interface FormValues extends Address {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  phone: string;
  termCheck: boolean;
  privacyCheck: boolean;
  marketingCheck: boolean;
}

const RegistrationForm = ({ user = null, f7router, f7route, userId = null }) => {
  const { currentUser, authenticateUser } = useAuth();

  const [certComplete, setCertComplete] = useState(false);
  const userName = f7route.query.name;
  const userPhone = f7route.query.phone;

  const SignUpSchema = Yup.object().shape({
    name: Yup.string().required('필수 입력사항 입니다'),
    email: Yup.string().email().required('필수 입력사항 입니다'),
    password: Yup.string()
      .min(4, '길이가 너무 짧습니다')
      .max(50, '길이가 너무 깁니다')
      .required('필수 입력사항 입니다'),
    password_confirmation: Yup.string()
      .min(4, '길이가 너무 짧습니다')
      .max(50, '길이가 너무 깁니다')
      .oneOf([Yup.ref('password'), null], '비밀번호가 일치하지 않습니다.')
      .required('필수 입력사항 입니다'),
    zipcode: Yup.string().min(4, '길이가 너무 짧습니다').max(8, '길이가 너무 깁니다').required('필수 입력사항 입니다'),
    address1: Yup.string().required('필수 입력사항 입니다'),
    phone: Yup.string().min(9, '길이가 너무 짧습니다').max(15, '길이가 너무 깁니다').required('필수 입력사항 입니다'),
    termCheck: Yup.boolean().oneOf([true], '이용약관에 동의해주세요'),
    privacyCheck: Yup.boolean().oneOf([true], '개인정보 보호정책에 동의해주세요'),
    marketingCheck: Yup.boolean(),
  });

  const RegisterEditSchema = Yup.object().shape({
    name: Yup.string().required('필수 입력사항 입니다'),
    email: Yup.string().email().required('필수 입력사항 입니다'),
    password: Yup.string()
      .min(4, '길이가 너무 짧습니다')
      .max(50, '길이가 너무 깁니다')
      .required('필수 입력사항 입니다'),
    password_confirmation: Yup.string()
      .min(4, '길이가 너무 짧습니다')
      .max(50, '길이가 너무 깁니다')
      .oneOf([Yup.ref('password'), null], '비밀번호가 일치하지 않습니다.')
      .required('필수 입력사항 입니다'),
    zipcode: Yup.string().min(4, '길이가 너무 짧습니다').max(8, '길이가 너무 깁니다').required('필수 입력사항 입니다'),
    address1: Yup.string().required('필수 입력사항 입니다'),
    phone: Yup.string()
      .min(9, '길이가 너무 짧습니다')
      .max(15, '길이가 너무 깁니다')
      .required('휴대폰 번호를 인증해주세요'),
  });

  return (
    <Formik
      initialValues={
        userId
          ? {
              id: userId,
              name: currentUser?.name || '',
              email: currentUser?.email || '',
              password: '',
              password_confirmation: '',
              zipcode: currentUser?.zipcode || '',
              address1: currentUser?.address1 || '',
              address2: currentUser?.address2 || '',
              phone: currentUser?.phone || '',
            }
          : {
              name: userName || '',
              email: '',
              password: '',
              password_confirmation: '',
              zipcode: '',
              address1: '',
              address2: '',
              phone: userPhone || '',
              termCheck: false,
              privacyCheck: false,
              marketingCheck: false,
            }
      }
      validationSchema={userId ? RegisterEditSchema : SignUpSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await sleep(400);
        setSubmitting(false);
        f7.dialog.preloader('잠시만 기다려주세요...');
        try {
          const fd = convertObjectToFormData({ modelName: 'user', data: values });
          if (userId) {
            const response = await updateUserAPI(fd);
            f7.dialog.close();
            authenticateUser(response.data);
            f7router.back();
            toast.get().setToastText(`회원 정보가 수정되었습니다.`).openToast();
          } else {
            const response = await signupAPI(fd);
            f7.dialog.close();
            authenticateUser(response.data);
            location.replace('/');
          }
        } catch (error) {
          f7.dialog.close();
          if (error.length) {
            f7.dialog.alert(error?.response?.data || error?.message);
          } else {
            f7.dialog.alert('사용자가 이미 존재합니다. 다른 이메일로 시도해주세요');
          }
        }
      }}
      validateOnMount
      // enableReinitialize
    >
      {({ handleChange, handleBlur, values, errors, touched, isSubmitting, isValid }) => (
        <Form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <List>
              <small className="mx-4 mb-3">(*)표시된 항목은 필수 입력 사항입니다.</small>
              <h3 className="text-lg leading-6 font-semibold text-gray-900 mx-4 mb-3">기본 정보</h3>
              <ListInput
                label={i18next.t('login.name')}
                type="text"
                name="name"
                onChange={handleChange}
                readonly
                onBlur={handleBlur}
                value={userId ? currentUser.name : userName}
                errorMessageForce
                errorMessage={touched.name && errors.name}
              />
              <ListInput
                label={i18next.t('login.phone')}
                type="text"
                name="phone"
                onChange={handleChange}
                readonly
                onBlur={handleBlur}
                value={userId ? currentUser.phone : userPhone}
                errorMessageForce
                errorMessage={touched.name && errors.name}
              />
              <ListInput
                label={i18next.t('login.email')}
                type="email"
                name="email"
                placeholder="이메일을 입력해주세요"
                clearButton
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                errorMessageForce
                errorMessage={touched.email && errors.email}
              />
              <ListInput
                label={i18next.t('login.password')}
                type="password"
                name="password"
                placeholder="비밀번호를 입력해주세요"
                clearButton
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                errorMessageForce
                errorMessage={touched.password && errors.password}
              />
              <ListInput
                label={i18next.t('login.password_confirmation')}
                type="password"
                name="password_confirmation"
                placeholder="비밀번호를 확인해주세요"
                clearButton
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password_confirmation}
                errorMessageForce
                errorMessage={touched.password_confirmation && errors.password_confirmation}
              />
            </List>
            <DaumAddressSearch zipcode={values.zipcode} address1={values.address1} address2={values.address2} />
            {!userId && <AgreeCheckboxes names={['termCheck', 'privacyCheck', 'marketingCheck']} />}
            <div className="p-4">
              <button
                type="submit"
                className="button button-fill button-large disabled:opacity-50"
                disabled={isSubmitting || !isValid}
              >
                {userId ? '회원정보 저장' : '회원가입'}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RegistrationForm;

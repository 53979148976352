import { createImage, destroyImage } from '@api';
import { Image } from '@constants';
import { convertObjectToFormData } from '@utils';
import { useCallback, useState } from 'react';

export const useImageManager = (defaultImages: Image[] = []) => {
  const [images, setImages] = useState<Image[]>(defaultImages);

  const [state, setState] = useState({
    isUploading: false,
    isError: false,
    error: null,
  });
  const { isUploading, isError, error } = state;

  const inputRef = useCallback((el: HTMLInputElement) => {
    if (!el) return;

    const uploadImages = async (imageFiles: FileList) => {
      setState((state) => ({ ...state, isUploading: true, isError: false }));
      try {
        const imagesPromise = Array.from(imageFiles).map(async (imageFile) => {
          // console.log(imageFile);
          const formData = convertObjectToFormData({
            modelName: 'image',
            data: {
              image: imageFile,
              imagable_type: 'Item',
            },
          });
          return await createImage({})(formData);
        });

        for await (const image of imagesPromise) {
          setImages((images) => [...images, image]);
        }
      } catch (error) {
        setState((state) => ({ ...state, isError: true, error }));
      } finally {
        setState((state) => ({ ...state, isUploading: false }));
      }
    };
    el.onchange = (e: any) => {
      uploadImages(e.target.files);
      el.value = null;
    };
  }, []);

  const deleteImage = (image: Image) => {
    if (!image?.id) throw new Error("'image' in 'deleteImage' function has invalid id");
    destroyImage(image.id)();
    setImages((images) => images.filter((each) => each.id !== image.id));
  };

  // console.log(images);

  return { images, setImages, isUploading, isError, error, deleteImage, inputRef };
};

import React from 'react';
import { simpleFormat } from '@js/utils';

const DeliverInfo = () => (
  <div className="mt-2">
    <h3 className="text-lg leading-6 font-medium text-gray-900">배송옵션</h3>
    <div className="relative mt-2">
      <dt>
        <p className="text-medium leading-6 font-bold text-gray-900">오픈 하우스 현장픽업(권장)</p>
      </dt>
      <dd className="mt-2 text-sm text-gray-500">
        <svg
          className="absolute h-5 w-5 text-green-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        <p className="ml-7">상세내역</p>
        <ul className="mt-2">
          <li className="text-xs">&bull; 대부분의 품목은 오픈하우스에 현장에서 픽업할 수 있습니다.</li>
          <li className="text-xs mt-1">
            &bull; 픽업장소는 상단 지도와 주소를 참고하시고, 오픈하우스 날짜와 시간에 오셔서 픽업하시면 됩니다.
          </li>
          <li className="text-xs mt-1">
            &bull; 사업장 현장 특성상 오픈 하우스 날짜와 시간에만 픽업이 가능하므로 날짜와 시간을 필히 숙지하시고 방문 바랍니다.
          </li>
          <li className="text-xs mt-1">
            &bull; 방문 시 현장 직원에게 구매 상품에 대한 정보를 확인 후 사업장에 출입하실 수 있습니다. (분실 위험 방지 및 원활한 오픈하우스 운영에 꼭 필요한 사항이니 양해바랍니다.)
          </li>
        </ul>
        <svg
          className="absolute h-5 w-5 text-green-500 mt-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        <p className="ml-7 mt-2">비용</p>
        <ul className="mt-2">
          <li className="text-xs">&bull; 무료!!!</li>
          <li className="text-xs mt-1">&bull; 오픈하우스 날에 직접 픽업을 오시면 배송비는 무료입니다.</li>
        </ul>
        <svg
          className="absolute h-5 w-5 text-green-500 mt-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        <p className="ml-7 mt-2">배송기간</p>
        <ul className="mt-2">
          <li className="text-xs">&bull; 오픈 하우스날 바로 가져가실 수 있어 가장 빠르게 제품을 받으실 수 있습니다.</li>
          <li className="text-xs mt-1">&bull; 상품의 상세정보에서 오픈 하우스 일정, 시간 및 장소를 확인하실 수 있습니다.</li>
        </ul>
      </dd>
    </div>
    <div className="relative mt-4">
      <dt>
        <p className="text-medium leading-6 font-bold text-gray-900">일반배송</p>
      </dt>
      <dd className="mt-2 text-sm text-gray-500">
        <svg
          className="absolute h-5 w-5 text-green-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        <p className="ml-7">상세내역</p>
        <ul className="mt-2">
          <li className="text-xs">&bull; 중량: 20kg이내의 품목</li>
          <li className="text-xs mt-1">&bull; 규격: 160cm (세변의 합)</li>
        </ul>
        <svg
          className="absolute h-5 w-5 text-green-500 mt-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        <p className="ml-7 mt-2">비용</p>
        <ul className="mt-2">
          <li className="text-xs">&bull; 개당 3,000원</li>
          <li className="text-xs mt-1">
            &bull; 묶음 배송의 경우 문의 주시기 바랍니다.
            <ul className="ml-4 mt-1">
              <li className="text-xs">
                &bull; 제품의 특성상 합포장이 어려운 경우, 동일한 주문 건 임에도 개별 포장으로 각각 배송될 수 있습니다.
              </li>
              <li className="text-xs mt-1">&bull; 문의: 031-309-2208</li>
            </ul>
          </li>
        </ul>
        <svg
          className="absolute h-5 w-5 text-green-500 mt-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        <p className="ml-7 mt-2">배송기간</p>
        <ul className="mt-2">
          <li className="text-xs">&bull; 배송일은 오픈 하우스 이후 1~2일 이내 일괄 발송을 진행합니다.</li>
          <li className="text-xs mt-1">&bull; 배송과정은 모두다 앱에서 확인하실 수 있습니다.</li>
          <li className="text-xs mt-1">
            &bull; 배송 시작 후 일반 배송은 평균 2~3일 정도 소요되며, 날씨, 상품의 상태에 따라 최대 15일까지 소요될 수
            있습니다. (주말 공휴일 제외, 택배 사 배송일정 기준 준수)
          </li>
          <li className="text-xs mt-1">
            &bull; 특별한 요청을 제외하고 제주, 도서, 산간의 배송이 불가함을 양해 바랍니다.
          </li>
          <li className="text-xs mt-1">&bull; 일부 도서지역은 규격에 따라, 배송비 변동사항이 있을 수 있습니다.</li>
        </ul>
      </dd>
    </div>
    <div className="relative mt-4">
      <dt>
        <p className="text-medium leading-6 font-bold text-gray-900">화물배송</p>
      </dt>
      <dd className="mt-2 text-sm text-gray-500">
        <svg
          className="absolute h-5 w-5 text-green-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        <p className="ml-7">상세내역</p>
        <ul className="mt-2">
          <li className="text-xs">&bull; 20kg이상의 중대형 물품은 화물 배송이 필요합니다.</li>
          <li className="text-xs mt-1">&bull; 화물 배송은 상품의 상세정보에서 확인하실 수 있습니다.</li>
          <li className="text-xs mt-1">
            &bull; 배송 정책상 중고제품의 취급과 관련하여 한정된 수량에 국한된 물량을 취급하므로 배송에 있어서 할인율이
            적용되지 않습니다
          </li>
          <li className="text-xs mt-1">
            &bull; 화물배송의 경우 착불 이므로 화물 배송업체 및 화물배송 기사님에게 직접 지불하시면 됩니다.
          </li>
          <li className="text-xs mt-1">&bull; 구매하시는 고객의 컨디션에 따라 신중히 배송결정을 하시기 바랍니다.</li>
        </ul>
        <svg
          className="absolute h-5 w-5 text-green-500 mt-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        <p className="ml-7 mt-2">비용</p>
        <ul className="mt-2">
          <li className="text-xs">
            &bull; 착불
            <ul className="ml-4 mt-1">
              <li className="text-xs">&bull; 화물 배송 기사 및 화물배송 업체에 직접 지불하시면 됩니다.</li>
              <li className="text-xs mt-1">
                &bull; 모두다에서는 고객이 요청 시 화물 배송에 관한 기본적 정보를 제공하며 화물배송 비용에 관하여는
                관여하지 않습니다.
              </li>
            </ul>
          </li>
          <li className="text-xs mt-1">
            &bull; 화물 배송의 경우 화물 배송 비용은 기본 상차 되는 곳에서 하차 되는 곳의 거리로 책정되며, 기본 10km이내는 5만원부터 시작되고, 10km이후부터 10km단위로 1만원의 추가운임이 부가됨을 참고하시기 바랍니다.
            <ul className="ml-4 mt-1">
              <li className="text-xs">&bull; 화물 배송은 배송 기사 한 분이 1층 문 앞까지 만 배송합니다</li>
              <li className="text-xs mt-1">
                &bull; 추가 인력이 필요한 경우, 계단이 있는 경우, 승강기가 없는 건물의 경우 추가적으로 장비동원 또한
                고객이 자비로 해결해야 하며 이외에도 추가 운임이 발생할 수 있습니다.
              </li>
            </ul>
          </li>
          <li className="text-xs mt-1">
            &bull; 특별한 요청을 제외하고 제주, 도서, 산간의 배송이 불가함을 양해 바랍니다.
          </li>
          <li className="text-xs mt-1">&bull; 일부 도서지역은 규격에 따라, 배송비 변동사항이 있을 수 있습니다.</li>
          <li className="text-xs mt-1">&bull; 배송 환경과 여건에 따라 추가 운임이 발생할 수 있습니다.</li>
          <li className="text-xs mt-1">&bull; 가능한 오픈 하우스에 직접 수령해가는 것을 권장합니다.</li>
        </ul>
        <svg
          className="absolute h-5 w-5 text-green-500 mt-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        <p className="ml-7 mt-2">배송기간</p>
        <ul className="mt-2">
          <li className="text-xs">&bull; 배송일은 오픈 하우스 이후 1~2일 이내 일괄 발송을 진행합니다.</li>
          <li className="text-xs mt-1">
            &bull; 서울/경기: 1~2일(동일 권역) <br />
            <span className="ml-2">지방: 1~2일 (타 권역) </span>
            <br />
            <span className="ml-2">도서/산간: 10~21일 </span>
            <br />
          </li>
          <li className="text-xs mt-1">
            &bull; 배송 시작 후 일반 배송은 평균 2~3일 정도 소요되며, 날씨, 상품의 상태에 따라 최대 15일까지 소요될 수
            있습니다. (주말 공휴일 제외, 택배 사 배송일정 기준 준수)
          </li>
          <li className="text-xs mt-1">
            &bull; 배송일정은 화물 배송 기사님 및 화물 배송 관련업체에 직접 문의하시기 바랍니다.
          </li>
        </ul>
      </dd>
    </div>
    <div className="relative mt-2">
      <dt>
        <p className="text-medium leading-6 font-bold text-gray-900">배송업체 정보</p>
      </dt>
    </div>
    <table className="divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            일반배송
          </th>
          <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            화물배송
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="bg-white">
          <td className="px-3 py-4 text-sm font-medium text-gray-900">배송사: 로젠택배</td>
          <td className="px-3 py-4 text-sm text-gray-500">배송사: 일반용달 1톤 기본 (당사제휴)</td>
        </tr>
        <tr className="bg-white">
          <td className="px-3 py-4 text-sm font-medium text-gray-900">배송비용: 제품구매시 결제</td>
          <td className="px-3 py-4 text-sm text-gray-500">
            배송비용: 착불 <br />
            현장에서 화물 기사님 또는 화물 배송 관련업체에 직접 지불
          </td>
        </tr>
        <tr className="bg-white">
          <td className="px-3 py-4 text-sm font-medium text-gray-900">
            묶음배송: 고객 센터 문의 <br /> 301-309-2208
          </td>
          <td className="px-3 py-4 text-sm text-gray-500">
            묶음 배송: 기본 불가능 <br />
            화물업체와 별도 협의 필요
          </td>
        </tr>
      </tbody>
    </table>
    <div className="relative mt-2">
      <dt>
        <p className="text-medium leading-6 font-bold text-gray-900">환불/교환 정책</p>
      </dt>
      <dd className="mt-2">
        <div className="text-xs">
          환불 규정
          <br />
          배송전에 주문 취소시 바로 환불이 가능합니다.
          <br />
          배송 준비 이상 단계에서는 관리자 확인 후 관리자 페이지에서 수동으로 환불처리가 가능합니다.
          <br />
          회원은 『전자상거래 등에서의 소비자보호에 관한 법률』 등 관련 법령에 따라 상품 등을 배송 받은 날로부터 7일 이내에 청약철회 등을 할 수 있습니다.
          <br />
          다만, 청약철회에 관하여 『전자상거래 등에서의 소비자보호에 관한 법률』에달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
          <br />
          <br />
          회원의 책임 있는 사유로 상품 등이 멸실 또는 훼손된 경우(다만, 상품 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외함)
          <br />
          ① 회원의 책임 있는 사유로 상품 등이 멸실 또는 훼손된 경우(다만, 상품 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외함)
          <br />
          ② 회원의 사용 또는 일부 소비로 인하여 상품 등의 가치가 현저히 감소한 경우
          <br />
          ③ 시간의 경과에 의하여 재판매가 곤란할 정도로 상품 등의 가치가 현저히 감소한 경우
          <br />
          ④ 복제 가능한 상품 등의 포장을 훼손한 경우
          <br />
          ⑤ 용역 또는 『문화산업진흥 기본법』 제2조 제5호의 디지털콘텐츠의 제공이 개시된 경우(다만, 가분적 용역 또는 가분적 디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한 부분에 대하여는 제외함)
          <br />
          ⑥ 주문에 따라 개별적으로 생산되는 상품 등 그에 대하여 청약철회 등을 인정할 경우 판매자에게 회복할 수 없는
          중대한 피해가 예상되는 경우로서, 사전에 해당 거래에 대하여 별도로 그 사실을 고지하고 회원의 서면(전자문서를
          포함)에 의한 동의를 받은 경우
          <br />
          ⑦ 그 밖에 관련 법령에 따른 반품 제한 사유에 해당되는 경우
          <br />
          3 회원은 제1항 및 제2항에도 불구하고 상품의 내용이 표시∙광고 내용과 다르거나 계약내용과 다르게 이행된 경우에는
          해당 상품 등을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을
          할 수 있습니다.
          <br />
          5 회원은 제1항 또는 제3항에 따라 교환 신청을 하더라도 판매자에게 교환할 상품의 재고가 없는 경우에는 해당 상품 등의 교환을 받을 수 없습니다.
          <br />
          이 경우 해당 교환 신청은 환불 처리됩니다.
          <br />
          6 반품이나 교환에 필요한 배송비 및 기타 비용은 귀책사유가 있는 당사자가 부담합니다.
          <br />
          이 경우 상품 등의 하자 또는 오 배송으로 인한 반품이나 교환의 경우에는 회사가 그 비용을 부담하나, 회원의 단순 변심으로 인한 반품이나 교환의 경우에는 회원이 그 비용을 부담합니다.
          <br />
          7 회사는 회원이 배송상의 문제로 손해를 입은 경우에는 그에 대한 책임을 지지 않습니다.
          <br />
          8 회사는 회원의 결제완료에 대한 통지를 받은 이후에도 상당 기간 배송 절차 등을 이행하지 아니하여 회원이
          청약철회 등을 하거나 개별적인 정책으로 미리 정한 자동 환불처리 사안에 해당하는 경우에는 해당 거래를 취소하고
          결제대금예치서비스 이용 중인 결제대금을 회원에게 환불할 수 있습니다.
          <br />
          9 회사가 회원의 교환 또는 반품 신청에 대하여 적극적으로 임하지 않거나 이를 지연하는 경우, 회사는 회원에게 그
          교환 또는 반품 신청의 사유를 파악하여 정당한 사유가 있는 경우에는 해당 거래를 취소하는 한편, 예치 중인
          결제대금을 회원에게 환불할 수 있습니다. 다만 회원의 교환 또는 반품신청에 정당한 사유가 없는 경우에는 그러하지
          아니합니다.
          <br />
          10 회원이 교환 또는 반품신청을 한 날로부터 14일이 지날 때까지 이미 수령한 상품을 판매자에게 반환하지 아니 하거나 전화, 전자우편 등으로 연락되지 아니하는 경우, 해당 회원의 교환 또는 반품 신청은 효력을 상실합니다.
          <br />
          11 회사는 청약철회 등으로 인하여 결제대금을 환불하는 경우에는 그 청약철회 등을 한 날(단, 상품 등이 배송된
          경우에는 판매자가 상품 등을 반환 받은 날)로부터 3영업일 이내에 환불에 필요한 조치를 합니다. 신용카드로 결제가
          이루어진 경우 환불은 해당 신용카드 결제 취소를 통해서만 가능하고, 어떠한 경우에도 현금으로 환불되지는
        </div>
      </dd>
    </div>
  </div>
);

export default DeliverInfo;

import { Token, LineItemParams, CreateLineItemProps, CreatePaymentsProps, PaymentParams, Image } from '@constants';
import { getToken } from '@store';
import { PlainAPI, API, VERSION, API_URL } from './api.config';
import { ApiService } from './api.service';

export const refresh = (): Promise<{ data: Token }> =>
  PlainAPI.post(
    '/token',
    {},
    {
      headers: { 'X-CSRF-TOKEN': getToken().csrf, Authorization: `Bearer ${getToken().token}` },
    },
  );

export const get = (url: string, params: any) => PlainAPI.get(url, params);
export const loginAPI = (params: FormData) => PlainAPI.post('/login', params);
export const signupAPI = (params: FormData) => PlainAPI.post('/signup', params);
export const updateUserAPI = (params: FormData) => PlainAPI.patch(`/signup`, params);
export const logoutAPI = () => API.delete('/logout');
export const { query: getLikeDatas } = ApiService('likes');
// export const createImages = (params: FormData) => PlainAPI.post('/images', params);
export const updateItemImage = (id, params: FormData) => PlainAPI.patch(`/items/${id}`, params);
export const createNewPassword = (params: FormData) => PlainAPI.post('/password', params);
export const searchUserInfo = (params: any) => PlainAPI.get('/users/search_info', { params });
export const { query: getImages, create: createImage, destroy: destroyImage } = ApiService<Image[]>('images');

/* TODO : parameter type 지정 (위에는 샘플로 해두었습니다) */
export const getSmsAuth = (params) => API.get('/phone_certifications/sms_auth', { params });
export const paymentSuccessOrder = (params) => API.get(`/orders/payment`, { params });
// export const getImages = (params) => API.get(`/images`, { params });
export const deleteImage = (id, params) => API.delete(`/images/${id}`, { params });
export const getLikes = () => API.get('/likes');
export const getUserAuthInfo = (imp_uid: string) => API.get(`/users/get_info?imp_uid=${imp_uid}`);
export const getItemByCode = (code: string) => API.get(`/sellers/get_item_by_code?code=${code}`);
/* TODO */

// 일반적인 경우는 Ojbect API 사용하기
export const {
  query: getObjects,
  infiniteQuery: getInfiniteObjects,
  get: getObject,
  create: createObject,
  update: updateObject,
  destroy: destroyObject,
} = ApiService('objects');

export const { infiniteQuery: getInfiniteItems, get: getItem } = ApiService('items');
export const { query: getCategories } = ApiService('categories');
export { API_URL, VERSION };

export const getUser = (id) => async () => {
  const { data } = await API.get(`/users/${id}`);
  return data;
};

export const getContact = (id) => async () => {
  const { data } = await API.get(`/contacts/${id}`).then((value) => {
    return value;
  });
  return data;
};

export const getNotice = (id) => async () => {
  const { data } = await API.get(`/notices/${id}`);
  return data;
};

// export const getUserAuthInfo = (imp_uid: string) => async () => {
//   const { data } = await API.get(`/users/get_info?imp_uid=${imp_uid}`);
//   return data;
// };

export const getSellerInfo = (id: number) => async () => {
  const { data } = await API.get(`/sellers/${id}`);
  return data;
};

export const createLike = () => async (params) => {
  const { data } = await API.post('/likes', params);
  return data;
};

export const createDropzoneImage = () => async (params) => {
  const { data } = await API.post('/images/dropzone', params);
  return data;
};

export const signUp = () => async (params) => {
  const { data } = await API.post('/signup', params);
};

export const editUser = () => async (params) => {
  const { data } = await API.put('/signup', params);
};

export const deleteLike = () => async (id) => {
  const { data } = await API.delete(`/likes/${id}`);
  return data;
};

export const deleteContact = () => async (contactId) => {
  const { data } = await API.delete(`/contacts/${contactId}`);
  return data;
};

export const createFollow = () => async (userId) => {
  const { data } = await API.post('/follows', { followed_id: userId });
  return data;
};

export const deleteFollow = () => async (id) => {
  const { data } = await API.delete(`/follows/${id}`);
  return data;
};

export const getOptions = (itemId) => async () => {
  const { data } = await API.get(`/items/${itemId}/options`);
  return data;
};

export const getLineItems = () => async () => {
  const { data } = await API.get(`/line_items`);
  return data;
};

export const getItems = () => async () => {
  const { data } = await API.get(`/items`);
  return data;
};

export const getCurrentSeller = () => async () => {
  const { data } = await API.get(`/sellers/current_seller`);
  return data;
};

export const createLineItem = () => async (params: LineItemParams<CreateLineItemProps>) => {
  const { data } = await API.post('/line_items', params);
  return data;
};

export const cancelPayment = () => async (params) => {
  const { data } = await API.post('/orders/refund', params);
  return data;
};

export const createItem = () => async (params) => {
  const { data } = await API.post('/items', params);
  return data;
};

export const createPayment = (orderId) => async (params: PaymentParams<CreatePaymentsProps>) => {
  const { data } = await API.post(`/orders/${orderId}/payment`, params);
  return data;
};

export const deleteLineItem = () => async (lineItemId) => {
  const { data } = await API.delete(`/line_items/${lineItemId}`);
  return data;
};

export const changeLineItemQuantity = (lineItemId) => async (params) => {
  const { data } = await API.put(`/line_items/${lineItemId}/quantity`, params);
  return data;
};

export const changeLineItemCheck = (lineItemId) => async () => {
  const { data } = await API.put(`/line_items/${lineItemId}/check`);
  return data;
};

export const updateLineItemsCheck = () => async () => {
  const { data } = await API.put(`/line_items/update_checked`);
  return data;
};

export const deleteLineItemsCheck = () => async () => {
  const { data } = await API.put(`/line_items/destroy_checked`);
  return data;
};

export const deleteItem = () => async (itemId) => {
  const { data } = await API.delete(`/items/${itemId}`);
};

export const getLineItemsByOrder = (orderId) => async () => {
  const { data } = await API.get(`/orders/${orderId}/line_items`);
  return data;
};

export const getLineItemsBySeller = (sellerId) => async () => {
  const { data } = await API.get(`/sellers/${sellerId}/line_items`);
  return data;
};

import React from 'react';
import { List, ListItem, Row, Col } from 'framework7-react';
import { dateFormat } from '@js/utils';

const SellerInfo = ({ seller }) => (
  <div className="bg-white shadow overflow-hidden sm:rounded-lg container">
    <div className="mt-5 border-t border-gray-200">
      <dl>
        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">주소</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{seller.address1}</dd>
          <dt className="text-sm font-medium text-gray-500 mt-2">업종</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {seller.category} {`/ ${seller.category_sub}`}
          </dd>
          <dt className="text-sm font-medium text-gray-500 mt-2">대표(담당자)</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{seller.name}</dd>
          <dt className="text-sm font-medium text-gray-500 mt-2">연락처</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{seller.phone}</dd>
          <dt className="text-sm font-medium text-gray-500 mt-2">픽업주소</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{seller?.pickup_address}</dd>
          <dt className="text-sm font-medium text-gray-500 mt-2">상품 등록일</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{seller.item_registered_at}</dd>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <dt className="text-sm font-medium text-gray-500 mt-2">판매 시작일</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {dateFormat(seller?.sell_start_at, 'day')}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500 mt-2">판매 종료일</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {dateFormat(seller?.sell_end_at, 'day')}
              </dd>
            </div>
          </div>
          <dt className="text-sm font-medium text-gray-500 mt-2">1차 오픈하우스 (구매자 현장회수)</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="mr-1">{seller.first_open_house_at}</span>
            <span>
              {dateFormat(seller.open_at, 'timeonly')} ~ {dateFormat(seller.close_at, 'timeonly')}
            </span>
          </dd>
          <dt className="text-sm font-medium text-gray-500 mt-2">2차 오픈하우스 (모두다 현장회수)</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{seller.second_open_house_at}</dd>
          <dt className="text-sm font-medium text-gray-500 mt-2">최종 종료 예상일</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{seller.final_date}</dd>
        </div>
      </dl>
    </div>
  </div>
);

export default React.memo(SellerInfo);

import React, { useState } from 'react';
import { f7, Page, Navbar, List, ListInput, Button, Row, Col } from 'framework7-react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { searchUserInfo } from '@api';

interface FormValues {
  name: string;
  phone: string;
}

const EmailSearchSchema = Yup.object().shape({
  name: Yup.string().required('필수 입력 항목입니다.'),
  phone: Yup.string().min(9, '길이가 너무 짧습니다').max(15, '길이가 너무 깁니다').required('필수 입력 항목입니다.'),
});

const initialValues: FormValues = { name: '', phone: '' };

const emailSearchPage = () => {
  const [searchedEmail, setSearchedEmail] = useState();
  const [userName, setUserName] = useState();

  const handleSubmit = async (params, setSubmitting) => {
    setSubmitting(true);
    try {
      const result = await searchUserInfo(params);
      if (result.data === 204) {
        f7.dialog.alert('일치하는 회원정보를 찾을 수 없습니다.');
      } else {
        setSearchedEmail(result.data.email);
        setUserName(result.data.name);
      }
    } catch (error) {
      f7.dialog.alert('입력된 정보를 다시 확인해주세요.');
      setSubmitting(false);
    }
  };

  const checkNull = (str) => {
    if (typeof str === 'undefined' || str == null || str === '') {
      return true;
    }
    return false;
  };

  const maskingEmail = (str) => {
    const originStr = str;
    const emailStr = originStr.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    if (checkNull(originStr) === true || checkNull(emailStr) === true) {
      return originStr;
    }
    const strLength = emailStr.toString().split('@')[0].length - 3;
    return originStr
      .toString()
      .replace(new RegExp(`.(?=.{0,${strLength}}@)`, 'g'), '*')
      .replace(/.{6}$/, '******');
  };

  return (
    <Page className="bg-white" noToolbar>
      <Navbar title="아이디 찾기" backLink sliding={false} />
      {searchedEmail ? (
        <div className="max-w-7xl mx-auto py-10 px-2 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-5 text-lg">
              {userName} 회원님의 계정 아이디(이메일)는 <span className="text-blue-500">{maskingEmail(searchedEmail)}</span> 입니다.
            </p>
            <Row>
              <Col>
                <Button className="mt-5" fill large href="/users/sign_in">
                  로그인
                </Button>
              </Col>
              <Col>
                <Button className="mt-5" fill large href="/passwords/new">
                  비밀번호 찾기
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={EmailSearchSchema}
          onSubmit={(values, { setSubmitting }: FormikHelpers<FormValues>) => handleSubmit(values, setSubmitting)}
          validateOnMount
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
            <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
              <List>
                <h3 className="text-lg leading-6 font-semibold text-gray-900 mx-4 mb-3">회원정보 입력</h3>
                <ListInput
                  label="이름"
                  type="text"
                  name="name"
                  placeholder="이름을 입력해주세요."
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  errorMessageForce
                  errorMessage={touched.name && errors.name}
                />
                <ListInput
                  label="전화번호"
                  type="text"
                  name="phone"
                  placeholder="전화번호를 입력해주세요."
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  errorMessageForce
                  errorMessage={touched.phone && errors.phone}
                />
              </List>
              <div className="p-4">
                {searchedEmail && (
                  <div>
                    {userName}님의 아이디는 {searchedEmail} 입니다.
                  </div>
                )}
                <button
                  type="submit"
                  className="button button-fill button-large disabled:opacity-50"
                  disabled={isSubmitting || !isValid}
                >
                  아이디 찾기
                </button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </Page>
  );
};

export default React.memo(emailSearchPage);

import React from 'react';
import { Page, Navbar, Block, f7 } from 'framework7-react';
import { getObjects } from '@api';
import { useQuery } from 'react-query';
import { sleep, dateFormat } from '@js/utils';
import { Notice, Objects } from '@constants';
import { map } from 'lodash';
import moment from 'moment';

const NoticeIndexPage = ({ f7router }) => {
  const { data, status, error } = useQuery<Objects<Notice>, Error>(
    'notices',
    getObjects({
      model_name: 'notice',
      q: {
        notice_type_eq: 'event',
        end_at_gteq: moment().toString(),
        s: ['created_at desc'],
      },
    }),
  );

  const onClickGoBack = async () => {
    f7.preloader.show();
    f7router.back();
    await sleep(500);
    f7.preloader.hide();
  };

  return (
    <Page name="notice" noToolbar>
      <Navbar backLink={true} title="이벤트 게시판"></Navbar>
      <div>
        <div>
          <div className="flow-root mt-6 mb-6">
            {data && data.total_count > 0 ? (
              <ul className="-my-5 divide-y divide-gray-200">
                {map(data?.objects, (notice: Notice) => (
                  <li className="py-5" key={notice.id}>
                    <div className="relative focus-within:ring-2 focus-within:ring-blue-500 p-2 ">
                      <h3 className="text-sm font-semibold text-gray-800">
                        <a href={`/notices/${notice.id}`} className="hover:underline focus:outline-none">
                          <span className="absolute inset-0" aria-hidden="true"></span>
                          {notice?.title}
                        </a>
                      </h3>

                      <span className="leading-6 text-gray-600 font-base text-sm tracking-wide uppercase mr-2">
                        {dateFormat(notice.created_at, 'day')}
                      </span>
                      <span className="px-2 inline-flex text-xs leading-5 font-normal rounded-full bg-green-100 text-green-800 mr-1">
                        진행중
                      </span>
                      <p className="mt-2 text-sm text-gray-600 line-clamp-2">{notice?.body}</p>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <Block className="pt-16 mt-16 text-center">
                <div className="text-center pt-16 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                  <i className="las la-exclamation-circle" style={{ fontSize: '64px', color: '#bbbbbb' }}></i>
                  <h1 className="text-center mt-2 mb-5 font-normal">진행중인 이벤트가 없습니다.</h1>
                  <div className="flex">
                    <button
                      onClick={onClickGoBack}
                      type="button"
                      className="text-center mr-20 ml-20 flex-initial rounded-full items-center px-4 py-2 border border-blue-700 shadow-sm text-base font-medium text-blue-700 bg-white"
                    >
                      이전 페이지 돌아가기
                    </button>
                  </div>
                </div>
              </Block>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(NoticeIndexPage);

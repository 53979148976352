import React, { useRef, useState } from 'react';
import HeartContainer from '@components/shared/HeartContainer';
import { Navbar, NavTitle, NavRight, Link, Page, List, ListItem, ListInput, Badge, Icon } from 'framework7-react';
import { API_URL, getObject, getInfiniteObjects, getInfiniteItems } from '@api';
import { useQuery, useInfiniteQuery, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import { currency, truncate, convertImagePath } from '@js/utils';
import { useRecoilValue } from 'recoil';
import { lineItemsCount } from '@atoms';
import { map } from 'lodash';
import { Category, InfiniteObjects, Item } from '@constants';
import useAuth from '@hooks/useAuth';
import i18n from '../../assets/lang/i18n';

const ItemIndexPage = ({ f7route, f7router }) => {
  const { is_main, category_id, today, category_all } = f7route.query;
  const [viewType, setViewType] = useState('grid');
  const allowInfinite = useRef(true);
  const queryClient = useQueryClient();
  const ITEM_INF_KEY = [`${is_main === 'true' ? 'items/regular_infinite' : 'items/infinite'}`, category_id * 1];
  const cartCount = useRecoilValue(lineItemsCount);
  const { currentUser, isAuthenticated } = useAuth();

  const { data: category } = useQuery<Category, Error>(
    ['category', parseInt(category_id, 10)],
    getObject(category_id, {
      model_name: 'category',
    }),
    {
      enabled: !!category_id,
    },
  );

  const filterForm = useFormik({
    initialValues: {
      s: 'created_at desc',
      item_categories_category_id_eq: category_id,
      sell_type_eq: `${is_main === 'true' ? '1' : ''}`,
    },
    onSubmit: async () => {
      await queryClient.removeQueries(ITEM_INF_KEY);
      await refetch();
      allowInfinite.current = true;
    },
  });

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery<
    InfiniteObjects<Item>,
    Error
  >(
    ITEM_INF_KEY,
    getInfiniteItems({
      // model_name: 'item',
      q: filterForm.values,
    }),
    {
      getNextPageParam: (lastPage) => lastPage.next_cursor,
    },
  );

  const loadMore = async () => {
    if (!allowInfinite.current) return;
    allowInfinite.current = false;
    if (hasNextPage && !isFetchingNextPage) {
      await fetchNextPage();
      allowInfinite.current = true;
    }
  };

  const onRefresh = async (done) => {
    await queryClient.removeQueries(ITEM_INF_KEY);
    await refetch();
    allowInfinite.current = true;
    done();
  };

  (window as any).goPageBack = {
    promises: [] as Promise<string>[],
    callback() {
      if (f7router.history.length === 1) {
        (window as any).Android.pressBackOut();
      }
      f7router.back();
    },
  };

  return (
    <Page
      noToolbar={!is_main}
      infinite
      infinitePreloader={isFetchingNextPage}
      onInfinite={loadMore}
      onPtrRefresh={onRefresh}
      ptr
    >
      <Navbar backLink={!is_main}>
        {is_main ? (
          <NavTitle>상시판매</NavTitle>
        ) : (
          <NavTitle>{today ? '오늘의 상품' : (category && category.title) || '전체상품'}</NavTitle>
        )}
        <NavRight>
          {currentUser && (
            <Link href="/line_items" iconF7="cart" iconBadge={cartCount > 9 ? '9+' : cartCount} badgeColor="red" />
          )}
          <Link
            href="/search"
            iconIos="f7:search"
            iconAurora="f7:search"
            iconMd="material:search"
            style={{ marginLeft: '0px' }}
          />
        </NavRight>
      </Navbar>

      <form onSubmit={filterForm.handleSubmit} className="item-list-form p-3 table w-full border-b">
        <div className="float-left">
          총 <b>{currency((data && data.pages[0]?.total_count) || 0)}</b>개 상품
        </div>
        <ListInput
          type="select"
          className="float-right inline-flex items-center px-2.5 py-3 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          name="s"
          onChange={(e) => {
            filterForm.handleChange(e);
            filterForm.submitForm();
          }}
          value={filterForm.values.s}
        >
          {map(i18next.t('sort').item, (v, k) => (
            <option value={k} key={k}>
              {v}
            </option>
          ))}
        </ListInput>
        <div
          className="float-right inline-flex items-center shadow-sm text-xs hover:text-blue-600 rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
          onClick={() => setViewType('list')}
        >
          <Icon f7="list_bullet" size="24px" />
        </div>
        <div
          className="float-right inline-flex items-center shadow-sm text-xs hover:text-blue-600 rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
          onClick={() => setViewType('grid')}
        >
          <Icon f7="circle_grid_3x3_fill" size="24px" />
        </div>
      </form>
      <List noHairlines className="mt-0 text-sm font-thin">
        {data && (
          <ul>
            {viewType === 'list'
              ? data.pages.map((page, i) => (
                  <React.Fragment key={i}>
                    {page.objects.map((item: Item) => (
                      <React.Fragment key={item.id}>
                        <ListItem key={item.id} mediaItem className="w-full">
                          <div className="flex flex-row">
                            <div className="w-4/5 inline-flex justify-left align-middle">
                              <a href={`/items/${item.id}`} className="item-link">
                                <div className="item-header py-1">
                                  {item?.category_name && <>[{truncate(14, item?.category_name)}]</>}
                                </div>
                                <div className="font-semibold line-clamp-2">{`${item.title}`}</div>
                                <div className="item-subtitle">{`${currency(item.price)}원`}</div>
                              </a>
                            </div>
                            <a href={`/items/${item.id}`} className="justify-right w-1/5">
                              <Icon icon="icon-forward text-gray-400 px-6 py-3" />
                            </a>
                          </div>
                          <img slot="media" src={convertImagePath(item?.image_path)} className="w-20 rounded" alt="" />
                          <div className="flex flex-row">
                            {item?.sell_type === '기간판매' ? (
                              <a
                                href={`/items/${item.id}`}
                                className="font-medium text-red-700 w-4/5 inline-flex justify-left align-middle mt-2"
                              >
                                남은시간: {item?.remain_time || '정보 없음'}
                              </a>
                            ) : (
                              <a
                                href={`/items/${item.id}`}
                                className="font-medium text-gray-700 w-4/5 inline-flex justify-left align-middle mt-2"
                              >
                                상시판매
                              </a>
                            )}
                            <HeartContainer
                              target={item}
                              heartClassName="z-50 text-red-500 text-2xl"
                              className="justify-right align-middle inline-flex w-1/5"
                            />
                          </div>
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))
              : data.pages.map((page, i) => (
                  <React.Fragment key={i}>
                    {page.objects.map((item) => (
                      <React.Fragment key={item.id}>
                        <div className="w-1/2 inline-flex grid-list-item relative">
                          <ListItem mediaItem className="w-full">
                            <a href={`/items/${item.id}`} className="item-link">
                              <div className="item-media">
                                <img
                                  slot="media"
                                  src={convertImagePath(item?.image_path)}
                                  className="w-40 m-auto radius rounded shadow-sm"
                                  alt=""
                                />
                              </div>
                              <div className="item-header mt-1">
                                {item?.category_name && <>[{truncate(14, item?.category_name)}]</>}
                              </div>
                              <div className="font-semibold line-clamp-2">{item?.title}</div>
                              <div className="item-subtitle">{`${currency(item?.price)}원`}</div>
                            </a>
                            <div className="flex flex-row items-center">
                              {item?.sell_type === '상시판매' ? (
                                <a
                                  href={`/items/${item.id}`}
                                  className="font-medium text-gray-700 w-full inline-flex justify-left align-middle"
                                >
                                  상시판매
                                </a>
                              ) : (
                                <a
                                  href={`/items/${item.id}`}
                                  className="font-medium text-red-700 w-full inline-flex justify-left align-middle"
                                >
                                  남은시간: {item?.remain_time || '정보 없음'}
                                </a>
                              )}
                              <HeartContainer
                                target={item}
                                heartClassName="z-50 text-red-500 text-2xl"
                                className="justify-right align-middle inline-flex mr-1"
                              />
                            </div>
                          </ListItem>
                        </div>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
          </ul>
        )}
      </List>
    </Page>
  );
};

export default ItemIndexPage;

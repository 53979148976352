import React, { useState, useEffect } from 'react';
import ReactQueryState from '@components/shared/ReactQueryState';
import LineItemCard from '@pages/line_items/LineItemCard';
import { f7, Navbar, Page, Row, Col, List, ListItem, Toolbar, Button, Block } from 'framework7-react';
import { currency, toast } from '@js/utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteLineItemsCheck, getLineItems, updateLineItemsCheck } from '@api';
import { map, find, filter } from 'lodash';
import { LineItems, LineItem } from '@constants';
import { useSetRecoilState } from 'recoil';
import { lineItemsCount } from '@atoms';
import TotalSheet from '@pages/orders/TotalSheet';

const LineItemIndexPage = () => {
  const { data, status, error, isSuccess } = useQuery<LineItems, Error>('line_items', getLineItems());
  const queryClient = useQueryClient();
  const setLineItemsCount = useSetRecoilState(lineItemsCount);
  const [deliverFee, setDeliverFee] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);

  const updateLineItemsMutation = useMutation(updateLineItemsCheck(), {
    onSuccess: async () => {
      f7.preloader.show();
      await queryClient.invalidateQueries('line_items', { exact: true });
      f7.preloader.hide();
    },
  });

  const destroyLineItemsMutation = useMutation(deleteLineItemsCheck(), {
    onSuccess: async (data) => {
      f7.preloader.show();
      await queryClient.invalidateQueries('line_items', { exact: true });
      await setLineItemsCount(() => data.line_items_count);
      f7.preloader.hide();
    },
  });

  const onClickCheckAll = () => {
    updateLineItemsMutation.mutate();
  };

  const onClickDestroyLineItems = () => {
    if (find(data.line_items, (li: LineItem) => li.is_checked)) {
      f7.dialog.confirm('정말 삭제하시겠습니까?', 'MODOODA', () => destroyLineItemsMutation.mutate());
    } else {
      toast.get().setToastText('상품을 선택해주세요').openToast();
    }
  };

  useEffect(() => {
    if (data?.order) {
      setOrderTotal(data?.order?.include_deliver_total);
      setDeliverFee(data?.order?.deliver_fee);
    }
  }, [data]);

  return (
    <Page noToolbar>
      <Navbar title="장바구니" backLink />

      <ReactQueryState data={data} status={status} error={error} />

      <Toolbar bottom className="item-toolbar">
        {data && data.line_items.length > 0 && !!find(data.line_items, (li) => li.is_checked) ? (
          <Button href={data && `/payments/${data.order.id}`} fill className="w-full h-11 text-base mr-2 ml-2">
            {data && currency(data.order.total + deliverFee)} 원 바로구매 (
            {(data && filter(data.line_items, (li: LineItem) => li.is_checked).length) || 0})
          </Button>
        ) : (
          <Button href="/items?category_all=true" fill className="w-full h-11 text-base mr-2 ml-2">
            상품 구매하러 가기
          </Button>
        )}
      </Toolbar>

      {data && data.line_items.length > 0 ? (
        <>
          <Row noGap>
            <Col width="75">
              <List className="m-0">
                <ListItem
                  checkbox
                  title="전체 선택"
                  checked={data && !data.line_items.find((li: LineItem) => !li.is_checked)}
                  onClick={onClickCheckAll}
                />
              </List>
            </Col>
            <Col width="25">
              <List className="m-0">
                <ListItem onClick={onClickDestroyLineItems} after="선택 삭제" className="text-xs font-semibold" />
              </List>
            </Col>
          </Row>

          <ReactQueryState data={data} status={status} error={error} />

          {data.line_items &&
            map(data.line_items, (line_item: LineItem) => (
              <LineItemCard key={line_item.id} line_item={line_item} isCart />
            ))}

          <hr className="border-4" />

          <div className="mb-5">
            {data.order && (
              <TotalSheet
                order={data.order}
                className="text-base"
                deliverFee={deliverFee}
                isPickup={false}
                orderTotal={orderTotal}
              />
            )}
          </div>
        </>
      ) : (
        <Block className="pt-16 mt-16 text-center">
          <div className="text-center pt-16 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <i className="las la-shopping-cart" style={{ fontSize: '64px', color: '#bbbbbb' }} />
            <h1 className="text-center mt-2 mb-5">장바구니가 비었습니다.</h1>
            <div className="flex justify-center">
              <Button
                href="/items?category_all=true"
                className="text-center mr-20 ml-20 flex-initial rounded-full items-center px-4 py-4 border border-blue-700 shadow-sm text-base font-medium text-blue-700 bg-white"
              >
                상품 둘러보기
              </Button>
            </div>
          </div>
        </Block>
      )}
    </Page>
  );
};

export default React.memo(LineItemIndexPage);

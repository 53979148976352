import React from 'react';
import { Page } from 'framework7-react';
import logo from '@images/modooda_logo.png';

const LandingPage = () => (
  <Page>
    <div className="w-full h-screen flex justify-center items-center">
      {/* <a href="https://www.sseuimsae.com" target="_blank" className="external" rel="noreferrer"> */}
      <img src={logo} alt="insomenia-logo" className="w-48 h-48" />
      {/* </a> */}
    </div>
  </Page>
);

export default LandingPage;

import { Navbar, Page } from 'framework7-react';
import React from 'react';
import { getObject } from '@api';
import { useQuery } from 'react-query';
import useAuth from '@hooks/useAuth';
import { Seller } from '@constants';

const SellerDashboardPage = ({ f7route }) => {
  const seller_id = f7route.params.id;

  const { status, data: seller, error } = useQuery<Seller, Error>(
    ['seller', parseInt(seller_id, 10)],
    getObject(seller_id, {
      model_name: 'seller',
    }),
    {
      enabled: !!seller_id,
    },
  );

  return (
    <Page noToolbar className="bg-gray-100">
      <Navbar title="판매 현황" backLink />
      <div className="p-4">
        <h3 className="text-lg leading-6 font-semibold text-gray-900">{seller?.title} 판매 현황 대시보드</h3>
        {seller && (
          <>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
              <div className="relative bg-white pt-5 px-4 pb-8 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-blue-500 rounded-md p-3">
                    <i className="las la-calendar" style={{ color: 'white', fontSize: '28px' }} />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">상품 등록일</p>
                </dt>
                <dd className="ml-16 flex items-baseline sm:pb-7">
                  <p className="text-base font-semibold text-gray-900 mt-1">{seller?.item_registered_at}</p>
                </dd>
              </div>
              <div className="relative bg-white pt-5 px-4 pb-8 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-blue-500 rounded-md p-3">
                    <i className="las la-calendar-check" style={{ color: 'white', fontSize: '28px' }} />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">판매 기간</p>
                </dt>
                <dd className="ml-16 flex items-baseline sm:pb-7">
                  <p className="text-base font-semibold text-gray-900 mt-1">
                    {seller?.sell_start_at} - {seller?.sell_end_at}
                  </p>
                </dd>
              </div>
              <div className="relative bg-white pt-5 px-4 pb-8 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-blue-500 rounded-md p-3">
                    <i className="las la-clock" style={{ color: 'white', fontSize: '28px' }} />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">1차 오픈하우스</p>
                </dt>
                <dd className="ml-16 flex items-baseline sm:pb-7">
                  <p className="text-base font-semibold text-gray-900 mt-1">
                    {seller?.first_open_house_at} <br />
                    {seller?.open_at} ~ {seller?.closed_at}
                  </p>
                </dd>
              </div>
              <div className="relative bg-white pt-5 px-4 pb-8 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-blue-500 rounded-md p-3">
                    <i className="las la-clock" style={{ color: 'white', fontSize: '28px' }} />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">2차 오픈하우스</p>
                </dt>
                <dd className="ml-16 flex items-baseline sm:pb-7">
                  <p className="text-base font-semibold text-gray-900 mt-1">{seller?.second_open_house_at}</p>
                </dd>
              </div>
              <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-blue-500 rounded-md p-3">
                    <i className="las la-check-circle" style={{ color: 'white', fontSize: '28px' }} />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">판매중인 상품</p>
                </dt>
                <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                  <p className="text-xl font-semibold text-gray-900">{seller?.active_items_count}</p>
                  <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                      <a
                        href={`/sellers/${seller?.id}/items`}
                        className="font-semibold text-blue-800 hover:text-blue-500"
                      >
                        {' '}
                        자세히 보기
                        <span className="sr-only" />
                      </a>
                    </div>
                  </div>
                </dd>
              </div>
              <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-blue-500 rounded-md p-3">
                    <i className="las la-times-circle" style={{ color: 'white', fontSize: '28px' }} />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">판매 종료 상품</p>
                </dt>
                <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                  <p className="text-xl font-semibold text-gray-900">{seller?.sold_items_count}</p>
                  <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                      <a
                        href={`/sellers/${seller?.id}/items`}
                        className="font-semibold text-blue-800 hover:text-blue-500"
                      >
                        {' '}
                        자세히 보기
                        <span className="sr-only" />
                      </a>
                    </div>
                  </div>
                </dd>
              </div>
              <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                <dt>
                  <div className="absolute bg-blue-500 rounded-md p-3">
                    <i className="las la-shopping-cart" style={{ color: 'white', fontSize: '28px' }} />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">총 주문 내역</p>
                </dt>
                <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                  <p className="text-xl font-semibold text-gray-900">{seller?.seller_lineitems_count}</p>
                  <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                      <a
                        href={seller?.seller_lineitems_count > 0 ? `/sellers/${seller?.id}/line_items` : '#'}
                        className="font-semibold text-blue-800 hover:text-blue-500"
                      >
                        {' '}
                        자세히 보기
                        <span className="sr-only" />
                      </a>
                    </div>
                  </div>
                </dd>
              </div>
            </dl>
          </>
        )}
      </div>
    </Page>
  );
};

export default SellerDashboardPage;

import React, { useRef } from 'react';
import { Page, Navbar, NavTitle, Searchbar, theme, List, Subnavbar, ListItem } from 'framework7-react';
import HeartContainer from '@components/shared/HeartContainer';
import { getInfiniteItems } from '@api';
import { useQuery, useInfiniteQuery, useQueryClient } from 'react-query';
import { currency, truncate, convertImagePath } from '@js/utils';
import { InfiniteObjects, Item } from '@constants';
import { useFormik } from 'formik';

const SearchPage = ({ f7route, f7router }) => {
  const allowInfinite = useRef(true);
  const afterSearch = useRef(false);
  const queryClient = useQueryClient();
  const SEARCH_INF_KEY = ['search/infinite'];
  const { seller_id } = f7route.query;

  const searchForm = useFormik({
    initialValues: {
      title_or_description_or_address1_cont: '',
      s: 'created_at desc',
    },
    onSubmit: async () => {
      await queryClient.removeQueries(SEARCH_INF_KEY);
      await refetch();
      allowInfinite.current = true;
      afterSearch.current = true;
    },
  });

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery<
    InfiniteObjects<Item>,
    Error
  >(
    SEARCH_INF_KEY,
    getInfiniteItems({
      q: searchForm.values,
      seller_id: seller_id,
    }),
    {
      getNextPageParam: (lastPage) => lastPage.next_cursor,
    },
  );

  const loadMore = async () => {
    if (!allowInfinite.current) return;
    allowInfinite.current = false;
    if (hasNextPage && !isFetchingNextPage) {
      await fetchNextPage();
      allowInfinite.current = true;
    }
  };

  const onRefresh = async (done) => {
    await queryClient.removeQueries(SEARCH_INF_KEY);
    await refetch();
    allowInfinite.current = true;
    done();
  };

  const initializeSearch = () => {
    searchForm.values.title_or_description_or_address1_cont = '';
    // searchForm.submitForm();
  };

  (window as any).goPageBack = {
    promises: [] as Promise<string>[],
    callback() {
      if (f7router.history.length === 1) {
        (window as any).Android.pressBackOut();
      }
      f7router.back();
    },
  };

  return (
    <Page noToolbar infinite infinitePreloader={isFetchingNextPage} onInfinite={loadMore} onPtrRefresh={onRefresh} ptr>
      <Navbar backLink className="m-0">
        <NavTitle>{seller_id ? '판매자 상품 검색' : '검색'}</NavTitle>
        <Subnavbar inner={false}>
          <Searchbar
            id="search-form"
            placeholder="키워드를 입력해주세요"
            clearButton
            disableButtonText="취소"
            searchContainer=".search-list"
            searchIn=".item-title"
            disableButton={!theme.aurora}
            onChange={(e) => {
              searchForm.values.title_or_description_or_address1_cont = e.target.value;
            }}
            onSubmit={(e) => {
              searchForm.handleChange(e);
              searchForm.submitForm();
            }}
            onClickClear={(e) => {
              initializeSearch();
            }}
            onClickDisable={(e) => {
              // initializeSearch();
              f7router.back();
            }}
          />
        </Subnavbar>
      </Navbar>
      <List noHairlines className="mt-0 text-sm font-thin ">
        <ul>
          {afterSearch.current &&
            data &&
            data.pages.map((page, i) => (
              <React.Fragment key={i}>
                {page.objects.map((item) => (
                  <React.Fragment key={item.id}>
                    <div className="w-1/2 inline-flex grid-list-item relative">
                      <ListItem mediaItem className="w-full">
                        <a href={`/items/${item.id}`} className="item-link">
                          <div className="item-media">
                            <img
                              slot="media"
                              src={convertImagePath(item?.image_path)}
                              className="w-40 m-auto radius rounded shadow-sm"
                              alt=""
                            />
                          </div>
                          <div className="item-header mt-1">
                            {item?.category_name && <>[{truncate(14, item?.category_name)}]</>}
                          </div>
                          <div className="item-title">{item?.title}</div>
                          <div className="item-subtitle">{`${currency(item?.price)}원`}</div>
                        </a>
                        <div className="flex flex-row">
                          <a
                            href={`/items/${item.id}`}
                            className="font-medium text-red-700 w-full inline-flex justify-left align-middle mt-2"
                          >
                            남은시간: {item?.remain_time || '정보 없음'}
                          </a>
                          <HeartContainer
                            target={item}
                            heartClassName="z-50 text-red-500 text-2xl"
                            className="justify-right align-middle inline-flex mr-1"
                          />
                        </div>
                      </ListItem>
                    </div>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
        </ul>
        {data?.pages[0]?.objects?.length === 0 && (
          <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="text-center">
              <p className="mt-1 text-lg font-extrabold text-gray-600 sm:text-lg sm:tracking-tight lg:text-xl">
                검색 결과가 없습니다.
              </p>
            </div>
          </div>
        )}
      </List>
    </Page>
  );
};

export default React.memo(SearchPage);

import React, { useEffect } from 'react';
import { Navbar, Page, List, ListInput, f7, f7ready } from 'framework7-react'
import  { logoutAPI } from '@api';
import { toast } from '@js/utils';
import { sleep } from '@utils';
import useAuth from '@hooks/useAuth';

const WithdrawCompletePage = ({ f7router }) => {
  const { unAuthenticateUser } = useAuth();

  const handleLogout = async () => {
    const data = await logoutAPI();
    if (data.data === 'ok') {
      toast.get().setToastText('잠시 후 로그아웃 됩니다.').openToast();
      await sleep(1000);
      unAuthenticateUser();
      await sleep(200);
      window.location.replace('/');
    } else {
      f7.dialog.alert('로그아웃 오류입니다. 문제가 계속되면 관리자에게 문의해주세요.');
    }
  };

  return (
    <Page noToolbar>
      <Navbar title="회원 탈퇴" />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto text-left py-32 px-4">
          <h2 className="font-extrabold tracking-tight text-gray-900 text-base">
            <span className="block">
              정상적으로 탈퇴 처리 되었습니다. <br /> 회원님의 개인정보는 안전하게 처리 되었습니다.
            </span>
            <br />
            <span className="block">이용해 주셔서 감사합니다.</span>
          </h2>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <a
                onClick={handleLogout}
                className="inline-flex items-center justify-center px-16 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                확인
              </a>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(WithdrawCompletePage);

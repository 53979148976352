import React from 'react';
import { Navbar, Page } from 'framework7-react';
import { getObject, API_URL } from '@api';
import { useQuery } from 'react-query';
import ReactQueryState from '@components/shared/ReactQueryState';
import { dateFormat } from '@js/utils';
import { LineItem, PageRouteProps } from '@constants';
import { currency } from '@js/utils';
import { map } from 'lodash';
import { IS_PRODUCTION } from '@config';

const LineItemShowPage = ({ f7route, f7router }: PageRouteProps) => {
  const lineitemId = f7route.params.id;

  const { status, data: line_item, error } = useQuery<LineItem, Error>(
    ['line_item', parseInt(lineitemId, 10)],
    getObject(lineitemId, {
      model_name: 'line_item',
    }),
    {
      enabled: !!lineitemId,
    },
  );

  return (
    <Page noToolbar className="bg-gray-100">
      <Navbar backLink title="주문 상세" />
      <ReactQueryState data={line_item} status={status} error={error} />
      <div className="py-4 xl:py-10 px-2 sm:px-2 lg:px-4 overflow-hidden">
        {line_item && (
          <div className="w-full">
            <div className="relative md:bg-white mx-4">
              <div className="prose prose-blue prose-lg text-gray-500 lg:max-w-none text-base">
                <p className="font-semibold text-xs text-gray-900">
                  {dateFormat(line_item?.order?.updated_at, 'time')}
                </p>
                <p className="text-xs mt-2">주문번호 {line_item?.order?.order_number}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="col-span-1 rounded-lg divide-y divide-gray-200 px-4">
        {line_item && (
          <>
            <div className="border border-gray-200 col-span-1 bg-white rounded-lg shadow-sm divide-y divide-gray-200">
              <div className="px-4 py-5 sm:px-6">
                <p className="font-semibold text-base text-gray-900 mb-2">결제정보</p>
                <div className="relative w-full mt-2">
                  <span className="mt-1 max-w-2xl text-sm text-gray-500">상품가격</span>
                  <span className="mt-1 max-w-2xl text-sm text-gray-800 float-right">{`${currency(
                    line_item?.price,
                  )}원`}</span>
                </div>
                <div className="relative w-full mt-2 mb-4">
                  <span className="mt-1 max-w-2xl text-sm text-gray-500">배송비</span>
                  <span className="mt-1 max-w-2xl text-sm text-gray-800 float-right">{`${currency(
                    line_item?.order?.shipping_fee,
                  )}원`}</span>
                </div>
                <hr />
                <div className="relative w-full mt-2">
                  <span className="mt-1 max-w-2xl text-sm text-gray-800 font-semibold">총 결제금액</span>
                  <span className="mt-1 max-w-2xl text-sm text-gray-800 float-right font-semibold">{`${currency(
                    line_item?.price,
                  )}원`}</span>
                </div>
              </div>
            </div>
            <div className="border border-gray-200 col-span-1 bg-white rounded-lg shadow-sm divide-y divide-gray-200 mt-4">
              <div className="px-4 py-5 sm:px-6">
                <p className="font-semibold text-base text-gray-900 mb-2">{line_item?.order?.receiver_name}</p>
                <div className="relative w-full mt-2">
                  <span className="mt-1 max-w-2xl text-sm text-gray-500">
                    {line_item?.order?.zipcode ? `(${line_item?.order?.zipcode})` : ''}
                    {line_item?.order?.address1}
                    {line_item?.order?.address2}
                  </span>
                </div>
                <div className="relative w-full mt-2">
                  <span className="mt-1 max-w-2xl text-xs text-gray-500">{line_item?.order?.receiver_phone}</span>
                </div>
              </div>
            </div>
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-4">
              <li className="border border-gray-200 col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                <div className="w-full flex items-center justify-between p-6 space-x-6">
                  <img
                    className="w-14 h-14 bg-gray-300 flex-shrink-0"
                    src={`${IS_PRODUCTION ? line_item.image_path : API_URL + line_item.image_path}`}
                    alt=""
                  />
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="text-gray-900 text-base font-medium truncate">{line_item?.item_name}</h3>
                      <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                        {line_item.category_name}
                      </span>
                    </div>
                    <p className="mt-1 text-gray-500 text-sm truncate">{`${currency(line_item?.price)}원`}</p>
                  </div>
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="w-0 flex-1 flex">
                      <a
                        href={`/items/${line_item.item_id}`}
                        className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                      >
                        <span className="ml-3">상품 보기</span>
                      </a>
                    </div>
                    <div className="-ml-px w-0 flex-1 flex">
                      <a
                        href={`#`}
                        className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                      >
                        <span className="ml-3">배송 조회</span>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </>
        )}
      </div>
    </Page>
  );
};

export default LineItemShowPage;

import { atom } from 'recoil';
import { AuthState } from '@constants';

const initialAuthState: AuthState = {
  token: null,
  csrf: null,
  currentUser: null,
};

export const authState = atom({
  key: 'authState',
  default: initialAuthState,
});

export const userLikes = atom({
  key: 'userLikes',
  default: {},
});

export const lineItemsCount = atom({
  key: 'lineItems',
  default: 0,
});

export const sellerInfos = atom({
  key: 'sellerInfos',
  default: {},
});

export const userInfos = atom({
  key: 'userInfo',
  default: {},
});

import React from 'react';
import { Button, Page, Navbar, f7 } from 'framework7-react';
import { getUserAuthInfo } from '@api';

const AuthenticationPage = ({ f7router }) => {
  const callback = async (response) => {
    const { success, error_msg, imp_uid } = response;

    if (success) {
      const { data } = await getUserAuthInfo(imp_uid);
      if (data?.result === 'ok') {
        f7router.navigate(`/users/sign_up?name=${data?.name}&phone=${data?.phone}`);
        f7.dialog.alert(`본인 인증이 완료되었습니다. 회원가입을 진행해주세요.`);
      } else if (data?.result === 'already') {
        f7.dialog.alert(`3개월 이내에 탈퇴한 기록이 있는 사용자입니다. 탈퇴 시 3개월동안 재가입이 불가능합니다.`);
      } else {
        f7.dialog.alert(`본인인증 진행 중 문제가 발생하였습니다. 문제가 계속될 경우 관리자에게 문의해주세요.`);
      }
    } else {
      f7.dialog.alert(`본인인증 실패: ${error_msg}`);
    }
  };

  const onClickCertification = () => {
    const { IMP } = window;
    IMP.init('imp07884560');

    const data = {
      merchant_uid: `mid_${new Date().getTime()}`,
      min_age: 18,
      company: '모두다',
    };

    IMP.certification(data, callback);
  };

  return (
    <Page noToolbar>
      <Navbar backLink title="인증" />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">본인 인증 절차</span>
          </h2>
          <p className="text-medium text-black mt-4">회원가입 진행을 위해 본인인증을 진행해 주세요.</p>
          <p className="text-medium text-black mt-4">가입 조건</p>
          <ul>
            <li>- 본인 소유의 휴대폰</li>
            <li>- 만 18세 이상</li>
          </ul>
          <p className="text-medium text-black mt-4">본인 인증 완료 후 회원가입이 가능합니다.</p>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <Button onClick={onClickCertification} className="w-full px-20" fill large>
                본인인증
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(AuthenticationPage);

import React, { useEffect, useRef, useState } from 'react';
import { f7, SwiperSlide, Swiper, PhotoBrowser, f7ready } from 'framework7-react';
import { getImages, API_URL } from '@api';
import { Image } from '@constants';
import { useQuery } from 'react-query';
import { map } from 'lodash';
import { convertImagePath } from '@js/utils';
import { IS_PRODUCTION } from '@config';

interface ImagesSlideProps {
  imagableType: string;
  imagableId: number;
}

const ImagesSlide = ({ imagableType, imagableId }: ImagesSlideProps) => {
  const IMAGES_KEY = ['images', imagableType, imagableId];
  const photoBrowser = useRef(null);

  const { data: images, isSuccess } = useQuery<Image[], Error>(
    IMAGES_KEY,
    getImages({ q: { imagable_type_eq: imagableType, imagable_id_eq: imagableId } }),
  );

  useEffect(() => {
    f7ready(() => isSuccess && f7.lazy.create('.slide-lazy'));
    // console.log(images);
  }, [isSuccess]);

  return (
    <>
      {isSuccess && (
        <PhotoBrowser
          ref={photoBrowser}
          photos={images.map((image) => IS_PRODUCTION ? image.image_path : API_URL + image.image_path )}
          popupCloseLinkText="닫기"
          navbarOfText="/"
        />
      )}
      <Swiper
        pagination
        speed={500}
        slidesPerView={1}
        spaceBetween={10}
        style={{ height: '100vw', backgroundColor: '#e9ecef' }}
      >
        {isSuccess &&
          map(images, (v, i) => (
            <SwiperSlide key={v?.id || i} onClick={() => photoBrowser.current.open(i)}>
              <img
                data-src={`${ convertImagePath(v.image_path) }`}
                alt=""
                className="slide-lazy lazy lazy-fade-in open-photo-browser width-100"
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};
export default React.memo(ImagesSlide);

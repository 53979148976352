import React, { useState } from 'react';
import { Page, Navbar, List, ListInput, Icon } from 'framework7-react';
import { getObjects } from '@api';
import { useQuery } from 'react-query';
import { Seller, Objects } from '@constants';
import { objectsSkeletonPlaceholder } from '@js/utils';
import SellerInfo from '@components/shared/SellerInfo';

const newItemPage = () => {
  const [selectedSeller, setSelectedSeller] = useState<any>();

  const { data, isLoading, isFetching } = useQuery<Objects<Seller>, Error>(
    'sellers',
    getObjects({ model_name: 'seller' }),
    {
      placeholderData: objectsSkeletonPlaceholder(4),
    },
  );

  const selectChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = data.objects.filter((v) => v.id === parseInt(e.currentTarget.value))[0];
    setSelectedSeller(selected);
  };

  return (
    <Page>
      <Navbar title="계약 선택" sliding={false} />
      <List noHairlinesMd>
        <ListInput label="" type="select" placeholder="판매자를 선택해주세요" onChange={selectChange}>
          <Icon icon="demo-list-icon" slot="media" />
          <option value="">판매자를 선택해주세요</option>
          {data &&
            data.objects.map((seller, index) => (
              <option value={seller.id} key={index}>
                {seller?.title}
              </option>
            ))}
        </ListInput>
        {selectedSeller && (
          <>
            <SellerInfo seller={selectedSeller} />
            <div className="bg-white overflow-hidden sm:rounded-lg container">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-5">
                <a href={`/items/register/${selectedSeller.id}`}>
                  <button
                    type="button"
                    className="items-center text-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    상품 등록 시작
                  </button>
                </a>
                <a href={`/sellers/${selectedSeller.id}/items`}>
                  <button
                    type="button"
                    className="mt-2 items-center text-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    상품 조회
                  </button>
                </a>
              </div>
            </div>
          </>
        )}
      </List>
    </Page>
  );
};

export default newItemPage;

import React, { useRef, useEffect } from 'react';
import { f7, f7ready, Navbar, Page } from 'framework7-react';
import { getObject, API_URL, getImages } from '@api';
import ReactQueryState from '../../components/shared/ReactQueryState';
import { useQuery, useQueryClient } from 'react-query';
import { Notice, Objects, Image } from '@constants';
import { map } from 'lodash';
import { convertImagePath } from '@js/utils';

const NoticeShowPage = ({ f7route }) => {
  let id = f7route.params.id;
  const queryClient = useQueryClient();
  const noticesQuery: Objects<Notice> = queryClient.getQueryData('notices');
  const IMAGES_KEY = ['images', 'Notice', id];

  const { data: images, isSuccess } = useQuery<Image[], Error>(
    IMAGES_KEY,
    getImages({
      q: {
        imagable_type_eq: 'Notice',
        imagable_id_eq: id,
      },
      model_name: 'image',
    }),
    {
      enabled: !!id,
    },
  );

  const NOTICE_KEY = ['notice', parseInt(id)];

  const { data: notice, status, error } = useQuery<Notice, Error>(
    ['notice', parseInt(id, 10)],
    getObject(id, {
      model_name: 'notice',
    }),
    {
      placeholderData: () => noticesQuery.objects?.find((n: Notice) => n.id === parseInt(id, 10)),
      enabled: !!id,
    },
  );

  useEffect(() => {
    f7ready(() => isSuccess && f7.lazy.create('.slide-lazy'));
  }, [isSuccess]);

  return (
    <Page noToolbar>
      <Navbar backLink={true} title="이벤트"></Navbar>
      <ReactQueryState data={notice} status={status} error={error} />
      <div className="py-8 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        {notice && (
          <div>
            <div className="relative z-10 mb-8 md:mb-2 md:px-6 border-b-2 border-fuchsia-600">
              <div className="text-base max-w-prose lg:max-w-none mb-2">
                <p className="mt-2 text-xl leading-8 font-semibold tracking-tight text-gray-900 sm:text-4xl">
                  {notice.title}
                </p>
                <span className="leading-6 text-blue-600 font-base text-sm tracking-wide uppercase">
                  {notice.created_at}
                </span>
              </div>
            </div>

            {notice?.banner_image_path && <img src={`${ convertImagePath(notice.banner_image_path) }`} />}
            <div className="relative">
              <svg
                className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="384" fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
              </svg>
              <svg
                className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="384" fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
              </svg>
              <div className="relative md:bg-white md:p-6 mb-6">
                <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                  <div className="prose prose-blue prose-lg text-gray-500 lg:max-w-none">
                    <p className="display-linebreak">{notice?.body}</p>
                  </div>
                </div>
                {/* <div className="mt-8 inline-flex rounded-md shadow">
                  <a
                    href="#"
                    className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                  >
                    참여하기
                  </a>
                </div> */}
              </div>
              {isSuccess &&
                map(images, (v, i) => (
                  <img
                    data-src={`${ convertImagePath(v.image_path)}`}
                    alt=""
                    key={v?.id || i}
                    className="slide-lazy lazy lazy-fade-in open-photo-browser width-100 mb-4"
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </Page>
  );
};

export default React.memo(NoticeShowPage);

import { f7, Navbar, Page, List, ListItem, NavRight, Link, Icon, ListInput, Row, Col, Button } from 'framework7-react';
import React, { useRef, useState, useEffect } from 'react';
import { API_URL, getInfiniteItems, deleteItem, getSellerInfo, getItemByCode } from '@api';
// import ReactQueryState from '../../components/ReactQueryState';
import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import { currency } from '@js/utils';
import { useFormik } from 'formik';
import HeartContainer from '@components/shared/HeartContainer';
import useAuth from '@hooks/useAuth';
import { map } from 'lodash';
import { InfiniteObjects, Item } from '@constants';
import { IS_PRODUCTION } from '@config';
import ListQrScanner from '@components/shared/ListQrScanner';
import { AppInterface } from '@utils/interfaces';

const SellerItemsPage = ({ f7route, f7router }) => {
  let seller_id = f7route.params.id;
  const [viewType, setViewType] = useState('list');
  const [seller, setSeller] = useState();
  const allowInfinite = useRef(true);
  const queryClient = useQueryClient();
  const { isAuthenticated, currentUser } = useAuth();
  const [isIos, setIsIos] = useState(false);

  // const ITEM_KEY = ['seller_items', parseInt(seller_id)];
  const ITEM_INF_KEY = ['items/infinite', seller_id * 1];
  const SELLER_KEY = ['seller', parseInt(seller_id)];

  const filterForm = useFormik({
    initialValues: {
      s: 'created_at desc',
      seller_id: seller_id,
    },
    onSubmit: async () => {
      await queryClient.removeQueries(ITEM_INF_KEY);
      await refetch();
      allowInfinite.current = true;
    },
  });

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteQuery(
    ITEM_INF_KEY,
    getInfiniteItems({
      seller_id: seller_id,
      q: filterForm.values,
    }),
    {
      getNextPageParam: (lastPage) => lastPage.next_cursor,
    },
  );

  const { status, error, isSuccess } = useQuery(SELLER_KEY, getSellerInfo(seller_id), {
    enabled: !!seller_id,
    onSuccess: async (data) => {
      setSeller(data);
    },
  });

  const deleteMutation = useMutation(deleteItem(), {
    onSuccess: async (data) => {
      f7.preloader.show();
      f7.dialog.alert('삭제되었습니다.');
      await queryClient.invalidateQueries(ITEM_INF_KEY);
      f7.preloader.hide();
    },
    onError: async () => {
      f7.preloader.show();
      f7.dialog.alert('삭제 권한 인증에 실패하였습니다. 문제가 계속되면 관리자에게 문의해주세요.');
      f7.preloader.hide();
    },
  });

  const onClickDestroy = (id) => {
    f7.dialog.confirm('정말 삭제하시겠습니까?', 'MODOODA', () => {
      deleteMutation.mutate(id);
    });
  };

  const loadMore = async () => {
    if (!allowInfinite.current) return;
    allowInfinite.current = false;
    if (hasNextPage && !isFetchingNextPage) {
      await fetchNextPage();
      allowInfinite.current = true;
    }
  };

  const onRefresh = async (done) => {
    await queryClient.removeQueries(ITEM_INF_KEY);
    await refetch();
    allowInfinite.current = true;
    done();
  };

  useEffect(() => {
    if (/iPad|iPhone|iPod|MacIntel/.test(navigator.platform)) {
      setIsIos(true);
    }
  }, []);

  return (
    <Page
      noToolbar
      infinite
      infinitePreloader={isFetchingNextPage}
      onInfinite={loadMore}
      onPtrRefresh={onRefresh}
      ptr
      className="bg-gray-100"
    >
      <Navbar title="판매자 상품 조회" backLink>
        <NavRight>
          {seller && (
            <Link
              href={`/search?seller_id=${seller?.id}`}
              iconIos="f7:search"
              iconAurora="f7:search"
              iconMd="material:search"
            />
          )}
        </NavRight>
      </Navbar>
      {currentUser && !currentUser?.is_admin && (
        <form onSubmit={filterForm.handleSubmit} className="item-list-form p-3 table w-full border-b bg-white">
          <div className="float-left">
            총 <b>{currency((data && data.pages[0]?.total_count) || 0)}</b>개 상품
          </div>
          <ListInput
            type="select"
            className="float-right inline-flex items-center px-2.5 py-3 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            name="s"
            onChange={(e) => {
              filterForm.handleChange(e);
              filterForm.submitForm();
            }}
            value={filterForm.values.s}
          >
            {map(i18next.t('sort').item, (v, k) => (
              <option value={k} key={k}>
                {v}
              </option>
            ))}
          </ListInput>
          <div
            className="float-right inline-flex items-center shadow-sm text-xs hover:text-blue-600 rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
            onClick={(e) => setViewType('list')}
          >
            <Icon f7="list_bullet" size="24px" />
          </div>
          <div
            className="float-right inline-flex items-center shadow-sm text-xs hover:text-blue-600 rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2"
            onClick={(e) => setViewType('grid')}
          >
            <Icon f7="circle_grid_3x3_fill" size="24px" />
          </div>
        </form>
      )}
      <List noHairlines className="mt-5 text-sm font-thin">
        {currentUser && currentUser?.is_admin && (
          <div className="max-w-7xl mx-auto px-4 sm:px-4 lg:px-6 mt-2">
            <h3 className="text-normal leading-6 font-semibold text-black-600">{seller?.title}</h3>
            {isIos ? (
              <Button
                onClick={() => {
                  AppInterface.callQrScan().then(async (result: string) => {
                    if (result) {
                      const { data: itemData } = await getItemByCode(result);
                      if (itemData?.id) {
                        f7router.navigate(`/items/${itemData?.id}`);
                      } else {
                        f7.dialog.alert('상품 정보를 찾을 수 없습니다');
                      }
                    }
                  });
                }}
                className="items-center text-center px-6 py-6 mb-8 mt-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                QR코드 스캔
              </Button>
            ) : (
              <ListQrScanner f7router={f7router} />
            )}
          </div>
        )}
        {data && (
          <div>
            <ul>
              {viewType === 'list'
                ? data.pages.map((page, i) => (
                    <React.Fragment key={i}>
                      {page.objects.map((item) => (
                        <React.Fragment key={item.id}>
                          <ListItem key={item.id} mediaItem className="w-full">
                            <div className="flex flex-row">
                              <div className="w-4/5 inline-flex justify-left align-middle">
                                <a href={`/items/${item.id}`} className="item-link">
                                  <div className="item-header py-1">{item?.category_name}</div>
                                  <div className="item-title">{`${item.title}`}</div>
                                  <div className="item-subtitle">{`${currency(item.price)}원`}</div>
                                </a>
                              </div>
                              <a href={`/items/${item.id}`} className="justify-right w-1/5">
                                <Icon icon="icon-forward text-gray-400 px-6 py-3" />
                              </a>
                            </div>
                            <img
                              slot="media"
                              src={IS_PRODUCTION ? item?.image_path : API_URL + item?.image_path}
                              className="w-20 rounded"
                              alt=""
                            />
                            <div className="flex flex-row">
                              <a
                                href={`/items/${item.id}`}
                                className="font-medium text-red-700 w-4/5 inline-flex justify-left align-middle mt-2"
                              >
                                남은시간: {item?.remain_time || '정보 없음'}
                              </a>
                              <HeartContainer
                                target={item}
                                heartClassName="z-50 text-red-500 text-2xl px-5"
                                className="justify-right align-middle inline-flex w-1/5"
                              />
                            </div>
                          </ListItem>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))
                : data.pages.map((page, i) => (
                    <React.Fragment key={i}>
                      {page.objects.map((item) => (
                        <React.Fragment key={item.id}>
                          <div className="w-1/2 inline-flex grid-list-item relative">
                            <ListItem mediaItem className="w-full">
                              <a href={`/items/${item.id}`} className="item-link">
                                <div className="item-media">
                                  <img
                                    slot="media"
                                    src={IS_PRODUCTION ? item?.image_path : API_URL + item?.image_path}
                                    className="w-40 m-auto radius rounded shadow-sm"
                                    alt=""
                                  />
                                </div>
                                <div className="item-header mt-1">{item?.category_name}</div>
                                <div className="item-title">{item?.title}</div>
                                <div className="item-subtitle">{`${currency(item?.price)}원`}</div>
                              </a>
                              <div className="flex flex-row">
                                <a
                                  href={`/items/${item.id}`}
                                  className="font-medium text-red-700 w-full inline-flex justify-left align-middle mt-2"
                                >
                                  남은시간: {item?.remain_time || '정보 없음'}
                                </a>
                                <HeartContainer
                                  target={item}
                                  heartClassName="z-50 text-red-500 text-2xl"
                                  className="justify-right align-middle inline-flex mr-1"
                                />
                              </div>
                            </ListItem>
                          </div>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
            </ul>
          </div>
        )}
      </List>
    </Page>
  );
};

export default SellerItemsPage;

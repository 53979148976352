import React, { useEffect, useState } from 'react';
import { Page, Navbar, f7, Button } from 'framework7-react';
import { getObjects, API_URL, logoutAPI } from '@api';
import { useRecoilValue } from 'recoil';
import { userInfos } from '@atoms';
import { toast, sleep } from '@js/utils';
import { Objects, Term } from '@constants';
import useAuth from '@hooks/useAuth';
import { useQuery } from 'react-query';
import MenuItem from '@components/shared/MenuItem';
import BusinessInfo from '@components/shared/BusinessInfo';

const UserMypage = ({ f7router }) => {
  const { currentUser, unAuthenticateUser, isAuthenticated } = useAuth();
  const user = useRecoilValue(userInfos);

  const { data: terms, status, error } = useQuery<Objects<Term>, Error>(
    'terms/mypage',
    getObjects({
      model_name: 'term',
      q: {
        s: ['created_at asc'],
      },
    }),
  );

  const handleLogout = async () => {
    const data = await logoutAPI();
    if (data.data === 'ok') {
      toast.get().setToastText('잠시 후 로그아웃 됩니다.').openToast();
      await sleep(1000);
      unAuthenticateUser();
      await sleep(200);
      window.location.replace('/');
    } else {
      f7.dialog.alert('로그아웃 오류입니다. 문제가 계속되면 관리자에게 문의해주세요.');
    }
  };

  (window as any).goPageBack = {
    promises: [] as Promise<string>[],
    callback() {
      if (f7router.history.length === 1) {
        (window as any).Android.pressBackOut();
      }
      f7router.back();
    },
  };

  return (
    <Page name="mypage">
      <Navbar title="마이페이지" sliding={false} />
      <div className="bg-white shadow">
        <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          {currentUser ? (
            <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
              <div className="flex-1 min-w-0">
                <div className="flex items-center">
                  <img
                    className="hidden h-16 w-16 rounded-full sm:block"
                    src={`${API_URL + currentUser?.image_path}`}
                    alt=""
                  />
                  <div>
                    <div className="flex items-center">
                      <h1 className="text-xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                        {currentUser?.email}
                      </h1>
                    </div>
                    <div className="text-base font-semibold mt-2">{currentUser?.name}</div>
                    <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                      <dt className="sr-only">Company</dt>
                      <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                        <svg
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                            clipRule="evenodd"
                          />
                        </svg>
                        {currentUser?.address1} {currentUser?.address2}
                      </dd>
                      <dt className="sr-only">Account status</dt>
                      {user?.is_seller && (
                        <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                          <svg
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span>등록된 판매자</span>
                        </dd>
                      )}
                    </dl>
                  </div>
                </div>
              </div>
              <ul className="divide-y divide-gray-200">
                <li className="py-4 flex justify-start">
                  <div className="inline-flex rounded-md">
                    <Button
                      className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                      href={`/users/edit?user_id=${currentUser?.id}`}
                    >
                      수정
                    </Button>
                  </div>
                  <div className="ml-3 inline-flex rounded-md">
                    <Button
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-indogi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      href="#"
                      onClick={handleLogout}
                    >
                      로그아웃
                    </Button>
                  </div>
                </li>
              </ul>
            </div>
          ) : (
            <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
              <div className="flex-1 min-w-0">
                <div className="flex items-center">
                  <div>
                    <div className="flex items-center">
                      <img className="h-16 w-16 rounded-full sm:hidden" src={`${API_URL}/image/profile.png`} alt="" />
                      <h1 className="ml-3 text-xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                        로그인이 필요합니다.
                      </h1>
                    </div>
                    <div className="mt-5 w-100 text-center ml-5">
                      <a
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-indogi-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        href="/intro"
                      >
                        로그인/회원가입
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 sbg-white shadow overflow-hidden sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          <li>
            {currentUser && (
              <>
                <MenuItem
                  title="장바구니"
                  link="/line_items"
                  svgPath="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                />
                <MenuItem title="나의 주문내역" link="/orders" svgPath="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                {currentUser && currentUser?.seller && (
                  <>
                    <MenuItem
                      title="나의 판매 상품"
                      link={`/sellers/${currentUser?.seller?.id}/items`}
                      svgPath="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                    />
                    <MenuItem
                      title="정산 내역"
                      link={`/payments?user_id=${currentUser?.id}`}
                      svgPath="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                    <MenuItem
                      title="판매 현황"
                      link={`/sellers/${currentUser?.seller?.id}/dashboard`}
                      svgPath="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </>
                )}
                <MenuItem
                  title="찜 목록"
                  link="/likes"
                  svgPath="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </>
            )}
            <MenuItem
              title="FAQ"
              link="/faqs"
              svgPath="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <MenuItem
              title="이벤트 게시판"
              link="/notices"
              svgPath="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
            <MenuItem
              title="판매문의(1:1 문의)"
              link="/contacts"
              svgPath="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
            <MenuItem title="이용약관" link="/terms" svgPath="M4 6h16M4 10h16M4 14h16M4 18h16" />
            {currentUser && (
              <MenuItem
                title="회원 탈퇴"
                link="/users/withdraw"
                svgPath="M13 7a4 4 0 11-8 0 4 4 0 018 0zM9 14a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zM21 12h-6"
              />
            )}
            <a href="#" className="hover:bg-gray-50">
              <div className="flex items-center px-3 py-3 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-12 w-12 rounded-full"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 30"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="text-sm font-medium text-gray-900 truncate">버전 정보</p>
                      <p className="text-xs font-medium text-gray-400 truncate">v 1.0.0</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <BusinessInfo terms={terms} />
    </Page>
  );
};
export default UserMypage;

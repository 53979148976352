import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getLikeIds } from '@selectors';
import { userLikes } from '@atoms';
import { useMutation } from 'react-query';
import { createLike, deleteLike } from '@api';
import { toast } from '@js/utils.js';
import { Item, Like, UserLikesProps, Post } from '@constants';
import { reject } from 'lodash';
import useAuth from '@hooks/useAuth';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { IconType } from 'react-icons';
import i18n from '../../assets/lang/i18n';

interface HeartContainerProps {
  target: Item;
  className?: string;
  heartClassName?: string;
  countClassName?: string;
  toastYes?: boolean;
  textClassName?: string;
  likeText?: string;
  hasNotification?: boolean;
  HeartIcon?: IconType;
  HeartFillIcon?: IconType;
}

const HeartContainer: React.FC<HeartContainerProps> = ({
  target,
  className,
  heartClassName,
  countClassName,
  toastYes = true,
  textClassName,
  likeText = '좋아요',
  hasNotification,
  HeartIcon = FaRegHeart,
  HeartFillIcon = FaHeart,
}) => {
  const [likesCount, setLikesCount] = useState<number>(target?.likes_count || 0);
  const targetLikes: Like[] = useRecoilValue(getLikeIds(target.model_name));
  const setUserLikes = useSetRecoilState(userLikes);
  const likeToast = toast.get();
  const { currentUser } = useAuth();
  const { mutate: createMutate } = useMutation(createLike(), {
    onSuccess: (data: Like) => {
      setLikesCount((count) => count + 1);
      setUserLikes((likes: UserLikesProps) => {
        const likeList = likes[data.target_type] || [];
        return {
          ...likes,
          [data.target_type]: [...likeList, data],
        };
      });

      if (toastYes) {
        likeToast
          .setToastIcon('heart_fill')
          .setToastText(`${i18n.t(target.model_name.toLocaleLowerCase())}을(를) 찜했습니다`)
          .openIconToast();
      }
    },
  });
  const { mutate: deleteMutate } = useMutation(deleteLike(), {
    onSuccess: (data: Like) => {
      setLikesCount((count) => count - 1);
      setUserLikes((likes: UserLikesProps) => ({
        ...likes,
        [data.target_type]: reject(
          likes[data.target_type],
          (like: Like) => like.target_type === data.target_type && like.target_id === data.target_id,
        ),
      }));
      if (toastYes) {
        likeToast.setToastIcon('heart').setToastText('취소했습니다').openIconToast();
      }
    },
  });

  const onClickLike = () => {
    if (currentUser) {
      const targetLike = targetLikes.find((like: Like) => like.target_id === target.id);
      if (targetLike) {
        deleteMutate(targetLike.id);
      } else {
        createMutate({ target_type: target.model_name, target_id: target.id });
      }
    } else {
      toast.get().setToastText('로그인이 필요합니다').openToast();
    }
  };

  return (
    <span className={className}>
      <a onClick={onClickLike} className={heartClassName}>
        {(targetLikes && targetLikes.map((like: Like) => like.target_id).includes(target.id) && (
          <HeartFillIcon style={{ display: 'inline-block' }} />
        )) || <HeartIcon style={{ display: 'inline-block' }} />}
        {textClassName && (
          <span className={textClassName}>
            {likeText || '좋아요'}{' '}
            {targetLikes && targetLikes.map((like: Like) => like.target_id).includes(target.id) && '취소'}
          </span>
        )}
      </a>
      {countClassName && <p className={countClassName}>{likesCount || 0}</p>}
    </span>
  );
};

export default React.memo(HeartContainer);

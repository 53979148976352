import React, { useEffect, useState } from 'react';
import { Page, Navbar, NavRight, NavTitle, Link, SkeletonImage, SwiperSlide, Swiper } from 'framework7-react';
import { getObjects, getLineItems, paymentSuccessOrder, getLikes, getLikeDatas } from '@api';
import { useSetRecoilState } from 'recoil';
import { useQuery, useQueryClient } from 'react-query';
import { userLikes, userInfos, sellerInfos, lineItemsCount } from '@atoms';
import { Notice, Objects, LineItems, Term } from '@constants';
import { objectsSkeletonPlaceholder, convertImagePath, toast } from '@js/utils';
import useAuth from '@hooks/useAuth';
import moment from 'moment';
import BusinessInfo from '@components/shared/BusinessInfo';
import { utils } from 'framework7';
import { groupBy } from 'lodash';
import ItemsGrid from '../components/ItemsGrid';
import CategoriesGrid from '../components/shared/CategoriesGrid';

const HomePage = ({ f7router }) => {
  const queryClient = useQueryClient();
  const [notices, setNotices] = useState([]);
  const setUserInfos = useSetRecoilState(userInfos);
  const setUserLikes = useSetRecoilState(userLikes);
  const setLineItemsCount = useSetRecoilState(lineItemsCount);
  const { currentUser, isAuthenticated } = useAuth();

  const orderQuery = utils.parseUrlQuery(window.location.href) as any;
  const keys = Object.keys(orderQuery);

  const { data, isLoading, isFetching } = useQuery<Objects<Notice>, Error>(
    'notices',
    getObjects({
      model_name: 'notice',
      q: {
        notice_type_eq: 'event',
        end_at_gteq: moment().toString(),
        s: ['created_at desc'],
      },
    }),
    {
      placeholderData: objectsSkeletonPlaceholder(4),
    },
  );

  const { data: terms, status, error } = useQuery<Objects<Term>, Error>(
    'terms/home',
    getObjects({
      model_name: 'term',
      q: {
        s: ['created_at asc'],
      },
    }),
  );

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const { data } = await getLikes();
        setUserLikes(data);
        await queryClient.prefetchQuery('line_items', getLineItems());
        const result: LineItems = queryClient.getQueryData('line_items');
        // 로그아웃 시 아래 result undefined 오류 발생
        setLineItemsCount(result?.line_items?.length);
      }
    })();
  }, []);

  useEffect(() => {
    if (utils.parseUrlQuery(window.location.href).hasOwnProperty('payment_result') && keys?.length !== 0) {
      switch (orderQuery.payment_result) {
        case 'success':
          (async () => {
            window.history.replaceState({}, document.title, '/');
            const res = await paymentSuccessOrder(orderQuery);
            if (res.data.error) {
              toast
                .get()
                .setToastText('결제 처리 중 오류가 발생하였습니다. 문제가 계속될 경우 관리자에게 문의해주세요.')
                .openToast();
            } else {
              f7router.navigate(`/orders/${res.data.id}`);
            }
          })();
          break;
        case 'fail':
          window.history.replaceState({}, document.title, '/');
          toast.get().setToastText('다시 시도해주세요.').openToast();
          break;
        default:
          throw new Error('Unhandled Payment Result');
      }
    }

    (async () => {
      if (isAuthenticated) {
        await queryClient.prefetchQuery('likes', getLikeDatas());
        await setUserLikes(groupBy(queryClient.getQueryData('likes'), 'target_type'));
        await queryClient.prefetchQuery('line_items', getLineItems());
        const result: LineItems = queryClient.getQueryData('line_items');
        setLineItemsCount(result?.line_items?.length);
      }
    })();
  }, []);

  (window as any).goPageBack = {
    promises: [] as Promise<string>[],
    callback() {
      if (f7router.history.length === 1) {
        (window as any).Android.pressBackOut();
      }
      f7router.back();
    },
  };

  return (
    <Page name="home">
      {/* Top Navbar */}
      <Navbar sliding={false} className="m-0">
        <NavTitle>MODOODA</NavTitle>
        <NavRight>
          <Link href="/search" iconIos="f7:search" iconAurora="f7:search" iconMd="material:search" />
          {!currentUser && (
            <a href="/intro" className="text-black-600 m-0">
              로그인
            </a>
          )}
        </NavRight>
      </Navbar>
      {data && (
        <Swiper
          pagination
          speed={100}
          slidesPerView={1}
          spaceBetween={10}
          autoplay={{ delay: 10000, disableOnInteraction: false }}
          observer
          loop
          className="h-50"
        >
          {data.objects.map((notice: Notice, i) => (
            <SwiperSlide key={notice?.id || i}>
              {isLoading || isFetching ? (
                <Link href="#">
                  <SkeletonImage showIcon={false} width={375} height={250} className="open-photo-browser width-100" />
                </Link>
              ) : (
                <Link href={`/notices/${notice?.id}`}>
                  <img
                    src={`${convertImagePath(notice?.banner_image_path)}`}
                    alt=""
                    className="open-photo-browser width-100"
                    height={200}
                  />
                </Link>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      <CategoriesGrid />
      {/* Page content */}
      <ItemsGrid />
      <BusinessInfo terms={terms} />
    </Page>
  );
};
export default React.memo(HomePage);

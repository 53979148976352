import React, { useState } from 'react';
import { f7, PageContent, Sheet, Icon, Link, Row, Col, Stepper, Button } from 'framework7-react';
import { currency, toast } from '@js/utils';
import { createLineItem } from '@api';
import { useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { lineItemsCount } from '@atoms';
import { Item } from '@constants';
import { Router } from 'framework7/types';
import useAuth from '@hooks/useAuth';
interface BuySheetProps {
  f7router: Router.Router;
  selectedItem: Item;
}

const BuySheet = ({ f7router, selectedItem }: BuySheetProps) => {
  const setLineItemsCount = useSetRecoilState(lineItemsCount);
  const { currentUser } = useAuth();
  const addCartMutation = useMutation(createLineItem(), {
    onError: (error) => {
      console.log(`에러가 발생했습니다: `, error);
    },
  });

  const onClickAddCart = () => {
    if (currentUser) {
      addCartMutation.mutate(
        {
          line_item: { item_id: selectedItem.id, type: 'cart' },
        },
        {
          onSuccess: (data) => {
            toast.get().setToastText('상품을 담았습니다').setToastIcon('cart_badge_plus').openIconToast();
            setLineItemsCount(() => data.line_items_count);
          },
        },
      );
    } else {
      f7.dialog.alert('로그인 해주세요.');
      f7router.navigate('/users/sign_in');
    }
  };

  const onClickBuyNow = () => {
    if (currentUser) {
      f7.preloader.show();
      addCartMutation.mutate(
        {
          line_item: { item_id: selectedItem.id, type: 'buy_now' },
        },
        {
          onSuccess: (data) => {
            f7router.navigate(`/payments/${data.order_id}`);
            f7.preloader.hide();
          },
        },
      );
    } else {
      f7.dialog.alert('로그인 해주세요.');
      f7router.navigate('/users/sign_in');
    }
  };

  return (
    <Sheet className="buy-sheet" style={{ height: 'auto', '--f7-sheet-bg-color': '#fff' }} swipeToClose backdrop>
      <PageContent>
        <div className="text-center">
          <Link sheetClose>
            <Icon f7="chevron_down" className="text-lg text-gray-400 font-semibold p-1" />
          </Link>
        </div>
        <div className="p-3">
          <div>
            <p className="text-sm text-gray-500 font-bold">{selectedItem.name}</p>
            <p className="text-xl font-bold mt-1">{currency(selectedItem.price)}원</p>
            <p className="mb-2">{selectedItem?.deliver_method}</p>
          </div>
          <Row>
            <Col tag="span">
              <Button onClick={onClickAddCart} sheetClose large raised>
                장바구니 담기
              </Button>
            </Col>
            <Col tag="span">
              <Button onClick={onClickBuyNow} sheetClose large raised fill>
                바로구매
              </Button>
            </Col>
          </Row>
        </div>
      </PageContent>
    </Sheet>
  );
};

export default React.memo(BuySheet);

import React from 'react';
import { f7, Block } from 'framework7-react';
import { Page, Navbar } from 'framework7-react';
import { getObjects } from '@api';
import { useQuery } from 'react-query';
import { Faq, Objects } from '@constants';
import { map } from 'lodash';
import { sleep } from '@js/utils';

const FaqPage = ({ f7router }) => {
  const { data, status, error } = useQuery<Objects<Faq>, Error>(
    'faqs',
    getObjects({
      model_name: 'faq',
      q: {
        s: ['created_at desc'],
      },
    }),
  );

  const onClickGoBack = async () => {
    f7.preloader.show();
    f7router.back();
    await sleep(500);
    f7.preloader.hide();
  };

  return (
    <Page name="faq" noToolbar className="bg-gray-50">
      <Navbar backLink={true} title="FAQ" sliding={false}></Navbar>
      {data && data.total_count > 0 ? (
        <div className="max-w-7xl mx-auto py-12 px-4 divide-y-2 divide-gray-200 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-2xl font-extrabold text-gray-900 sm:text-4xl">자주 묻는 질문</h2>
          {map(data.objects, (faq: Faq) => (
            <div className="mt-6" key={faq.id}>
              <dl className="space-y-8 divide-y divide-gray-200">
                <div className="pt-6 md:grid md:grid-cols-12 md:gap-8">
                  <dt className="text-base font-medium text-gray-900 md:col-span-5">Q. {faq?.question}</dt>
                  <dd className="mt-2 md:mt-0 md:col-span-7">
                    <p className="text-base text-gray-500 display-linebreak">A. {faq?.answer}</p>
                  </dd>
                </div>
              </dl>
            </div>
          ))}
        </div>
      ) : (
        <Block className="pt-16 mt-16 text-center">
          <div className="text-center pt-16 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <i className="las la-exclamation-circle" style={{ fontSize: '64px', color: '#bbbbbb' }}></i>
            <h1 className="text-center mt-2 mb-5">자주묻는 질문이 없습니다.</h1>
            <div className="flex">
              <button
                onClick={onClickGoBack}
                type="button"
                className="text-center mr-20 ml-20 flex-initial rounded-full items-center px-4 py-2 border border-blue-700 shadow-sm text-base font-medium text-blue-700 bg-white"
              >
                이전 페이지 돌아가기
              </button>
            </div>
          </div>
        </Block>
      )}
    </Page>
  );
};

export default FaqPage;

import { Navbar, Page } from 'framework7-react';
import React from 'react';
import { getLineItemsBySeller, API_URL } from '@api';
import { useQuery } from 'react-query';
import { LineItems, LineItem } from '@constants';
import { map } from 'lodash';
import { currency } from '@js/utils';
import { dateFormat } from '@js/utils';
import { IS_PRODUCTION } from '@config';

const SellerLineItemsPage = ({ f7route }) => {
  let seller_id = f7route.params.id;

  const { status, data, error } = useQuery<LineItems, Error>(
    ['line_items', parseInt(seller_id, 10)],
    getLineItemsBySeller(seller_id),
    {
      enabled: !!seller_id,
    },
  );

  return (
    <Page noToolbar className="bg-gray-100">
      <Navbar title="총 주문 내역" backLink={true}></Navbar>
      <div>
        <div>
          <div className="flow-root mt-6 mb-6">
            <h3 className="px-4 pb-8 text-lg leading-6 font-semibold text-gray-900">판매자 상품 주문 내역</h3>
            {data && (
              <ul className="-my-5 divide-y divide-gray-200 px-4 ">
                {map(data.line_items, (line_item: LineItem) => (
                  <li
                    key={line_item.id}
                    className="mt-4 border border-gray-200 col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
                  >
                    <div className="w-full items-center justify-between px-6 py-3">
                      <h3 className="w-full text-sm font-semibold text-gray-800 mb-1">
                        {line_item?.order?.order_number}
                      </h3>
                      <h3 className="text-sm text-gray-600">{dateFormat(line_item?.order?.updated_at, 'time')}</h3>
                    </div>
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <img
                        className="w-14 h-14 bg-gray-300 flex-shrink-0"
                        src={`${IS_PRODUCTION ? line_item.image_path : API_URL + line_item.image_path}`}
                        alt=""
                      />
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <h3 className="text-gray-900 text-base font-medium truncate">{line_item?.item_name}</h3>
                          <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                            {line_item.category_name}
                          </span>
                        </div>
                        <p className="mt-1 text-gray-500 text-sm truncate">{`${currency(line_item?.price)}원`}</p>
                      </div>
                    </div>
                    <div>
                      <div className="-mt-px flex divide-x divide-gray-200">
                        <div className="w-0 flex-1 flex">
                          <a
                            href={`/items/${line_item.item_id}`}
                            className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                          >
                            <span className="ml-3">상품 보기</span>
                          </a>
                        </div>
                        <div className="-ml-px w-0 flex-1 flex">
                          <a
                            href={`/line_items/${line_item.id}`}
                            className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                          >
                            <span className="ml-3">주문 상세</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SellerLineItemsPage;
